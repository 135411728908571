import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../ModalWindow/ModalWindow';
import { ForgotPasswordFormInterface } from '../../interfaces/registration.interface';
import { useModal } from '../../hooks/useModal';
import Input from '../Input/Input';
import { NavLink } from 'react-router-dom';

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [modalMessage, setModalMessage] = useState('');

  const expiredRegistrationSchema = yup.object({
    passport: yup.string().required(t('Form.required')),
  });

  const { handleSubmit, reset, control, setError } =
    useForm<ForgotPasswordFormInterface>({
      defaultValues: {
        passport: '',
      },
      resolver: yupResolver(expiredRegistrationSchema),
      mode: 'onChange',
    });

  const { isShown, toggle } = useModal();

  const onSubmit = async (formData: ForgotPasswordFormInterface) => {
    setModalMessage('We have sent a new password to the provided email');
    toggle();
  };

  return (
    <>
      <ModalWindow
        isShown={isShown}
        hide={toggle}
        modalContent={modalMessage}
      />
      <form
        className="login__form password-recovery__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="password-recovery__form-title">
          {t('ForgotPassword.formTitle')}
        </h1>

        <p className="password-recovery__form-subtitle">
          {t('ForgotPassword.formSubtitle')}
        </p>

        <div className="password-recovery__form-inputs">
          <Input
            className="login__form-input"
            control={control}
            name="passport"
            placeholder={t('ForgotPassword.passport')}
          />
        </div>

        <div className="password-recovery__form-btn-container">
          <button className="btn btn--black" type="submit">
            {t('ForgotPassword.formButton')}
          </button>
        </div>

        <div className="password-recovery__form-go-back-container">
          <NavLink
            className="login__forgot link cursor"
            to={'/candidates/login'}
          >
            {t('ForgotPassword.goBack')}
          </NavLink>
        </div>
      </form>
    </>
  );
}

export default ForgotPasswordForm;
