import React, { useState } from 'react';
import { Toggle } from '../../Toggle/Toggle';

interface Props {}
export const NotificationsForm = ({}: Props) => {
  const [values, setValues] = useState({
    mailingCommon: false,
    mailingConference: false,
    approveCodes: false,
    securityCodes: false,
    sendEnMail: false,
  });

  const onChangeOption = (type: keyof typeof values) => () => {
    setValues((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <form className="section-launch-product-main__form section-launch-settings-main__form">
              <div className="section-launch-product-main__form-section section-launch-settings-main__form-section language-select first-section">
                <div className="section-launch-settings-main__form-section-heading">
                  <span>Язык рассылки</span>
                  <div className="section-launch-calculator-main__form-currency">
                    <button
                      type="button"
                      className={`section-launch-calculator-main__form-currency-btn ${
                        values.sendEnMail ? 'selected' : ''
                      }`}
                      onClick={onChangeOption('sendEnMail')}
                    >
                      EN
                    </button>
                    <button
                      type="button"
                      className={`section-launch-calculator-main__form-currency-btn ${
                        !values.sendEnMail ? 'selected' : ''
                      }`}
                      onClick={onChangeOption('sendEnMail')}
                    >
                      RU
                    </button>
                  </div>
                </div>
                <div className="section-launch-settings-main__form-section-description">
                  {' '}
                  Основной текст для рассылок
                </div>
              </div>
              <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                <div className="section-launch-settings-main__form-section-heading">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Информационная рассылка</span>
                  </div>
                  <Toggle
                    checked={values.mailingCommon}
                    onChange={onChangeOption('mailingCommon')}
                    name={'mailingCommon'}
                  />
                </div>
                <div className="section-launch-settings-main__form-section-description">
                  Последние новости и изменения в деятельности Клуба
                </div>
              </div>
              <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                <div className="section-launch-settings-main__form-section-heading">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>События, семинары, конференции</span>
                  </div>
                  <Toggle
                    checked={values.mailingConference}
                    onChange={onChangeOption('mailingConference')}
                    name={'mailingConference'}
                  />
                </div>
                <div className="section-launch-settings-main__form-section-description">
                  Предстоящие мероприятия, расписание встреч участников Клуба
                </div>
              </div>
              <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                <div className="section-launch-settings-main__form-section-heading">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Коды подтверждения</span>
                  </div>
                  <Toggle
                    checked={values.approveCodes}
                    onChange={onChangeOption('approveCodes')}
                    name={'approveCodes'}
                    disabled
                  />
                </div>
                <div className="section-launch-settings-main__form-section-description">
                  Уведомления о движении средств
                </div>
              </div>
              <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                <div className="section-launch-settings-main__form-section-heading">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Коды безопасности</span>
                  </div>
                  <Toggle
                    checked={values.securityCodes}
                    onChange={onChangeOption('securityCodes')}
                    name={'securityCodes'}
                    disabled
                  />
                </div>
                <div className="section-launch-settings-main__form-section-description">
                  Уведомления о сбросе или изменении пароля
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Оповещения
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                Данный раздел позволяет задать настройки для определенных
                рассылок от Клуба в зависимости от того, какие оповещения вы
                хотели бы получать. Чтобы увидеть весь список возможных
                оповещений, достаточно прокрутить данный экрану сверху вниз.
                Некоторые из оповещений могут быть настроены как обязательные, и
                их редактирование будет запрещено. К ним относятся уведомления о
                движении средств, подтверждение операций и другие моменты,
                важные с точки зрения безопасности и сохранности ваших средств и
                персональных данных.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
