import { CheckCardProps } from './CheckCard.props';

export function CheckCardDocs({
  children,
  value,
  selected,
  disabled,
  type,
  additionalHeaderTitle,
  onClick,
}: CheckCardProps) {
  return (
    <div
      className={`check_card_docs-wrapper ${
        value ? 'check_card_docs-signed_up' : ''
      }`}
    >
      <div className="check_card_docs-header">
        <label className="check_card_docs-label">
          <span
            className={`check_card_docs-box ${
              value ? 'check_card_docs-box_checked' : ''
            }`}
          ></span>
          <input
            className="check_card_docs-input"
            type="checkbox"
            name="check"
            checked={value}
            disabled={disabled}
            readOnly
          />
          <button className="check_card_docs-button" onClick={onClick}>
            {additionalHeaderTitle}
          </button>
        </label>
      </div>
      <div className="check_card_docs-body">{children}</div>
    </div>
  );
}

