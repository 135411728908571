import CurrenciesList from '../CurrenciesList/CurrenciesList';
import { AccountReplenishmentCardProps } from './AccountReplenishmentCard.props';
import CurrencySliderCalculator from '../../CurrencySliderCalculator/CurrencySliderCalculator';
import { useTranslation } from 'react-i18next';

function AccountReplenishmentCard({
  onCurrencySelect,
  selectedCurrency,
  onRangeChange,
  currencyAmount,
}: AccountReplenishmentCardProps) {
  const { t } = useTranslation();
  return (
    <div className="invoice-cards invoice-cards-account-replenishment">
      <div className="invoice-cards-account-replenishment__header">
        <h4 className="invoice-cards__header-title">{t('Invoice.price')}</h4>
        <p className="invoice-cards_subtitle">
          {t('Invoice.currencySelection')}
        </p>
      </div>
      <div className="invoice-cards-account-replenishment__body">
        <div className="invoice-cards-account-replenishment__body">
          <CurrenciesList
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={selectedCurrency}
          />
          <CurrencySliderCalculator
            value={currencyAmount}
            onChange={onRangeChange}
            currency={selectedCurrency}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountReplenishmentCard;
