import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClosedEye,
  EuroIcon,
  OpenEye,
  PrivacyStarsIcon,
  UsdIcon,
} from '../../../assets/media';
import { addSpaceToNumber } from '../../../helpers';

interface Props {
  name: string;
  type: 'golden' | 'silver';
}
export const Header = ({ name, type }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const onToggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <header
      className={`header-launchpad ${
        type === 'golden'
          ? 'header-launchpad--bronze'
          : ' header-launchpad--blue'
      }`}
    >
      <div className="wrapper">
        <div className="container">
          <div className="header-launchpad__inner">
            <h1 className="header-launchpad__heading header-launchpad__heading--static">
              {name}
            </h1>
            <div className="header-launchpad__currencies">
              <p className="header-launchpad__currencies-heading">
                {isOpen ? (
                  <span
                    className="header-launchpad__currencies-eye"
                    onClick={onToggleOpen}
                  >
                    <OpenEye />
                  </span>
                ) : (
                  <span
                    className="header-launchpad__currencies-eye"
                    onClick={onToggleOpen}
                  >
                    <ClosedEye />
                  </span>
                )}
                {t('Programs.availableBalance')}
              </p>
              <ul className="header-launchpad__currencies-list">
                <li className="header-launchpad__currency">
                  <div className="header-launchpad__currency-icon">
                    <UsdIcon theme={'white'} />
                  </div>
                  <p className="header-launchpad__currency-amount">
                    {isOpen ? (
                      addSpaceToNumber(2332313)
                    ) : (
                      <PrivacyStarsIcon theme={'white'} />
                    )}
                  </p>
                </li>
                <li className="header-launchpad__currency">
                  <div className="header-launchpad__currency-icon">
                    <EuroIcon theme={'white'} />
                  </div>
                  <p className="header-launchpad__currency-amount">
                    {isOpen ? (
                      addSpaceToNumber(2322313)
                    ) : (
                      <PrivacyStarsIcon theme={'white'} />
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
