import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

function Logout() {
  const { t } = useTranslation();
  const cookies = new Cookies();

  const loggedOut = () => {
    cookies.remove('logged_in', {
      path: '/',
    });
    cookies.remove('person_id', {
      path: '/',
    });
  };

  return (
    <a className="nav__link link" onClick={loggedOut} href="/">
      {t('Header.logOut')}
    </a>
  );
}

export default Logout;
