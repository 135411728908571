import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButtonProps } from './RadioButton.props';

function RadioButton({ children, onClick, checked, yesId, noId }: RadioButtonProps) {
  const { t } = useTranslation();

  const inputHandler = () => {
    onClick(checked);
  }

  return (
    <>
      <div className="personal-input__wrapper">
        <p className="personal-input__type">{children}</p>
        <div className="personal-input__radio">
          <ul>
            <li>
              <button
                type="button"
              >
                <label
                  className="personal-input__radio-wrapper"
                  htmlFor={yesId}
                  // htmlFor="step-2-radiobutton1"
                >
                  {t('RegistrationAddress.inputSwitcherVariantYes')}
                  <input
                    // id={'step-2-radiobutton1'}
                    id={yesId}
                    type="radio"
                    value="2"
                    onChange={inputHandler}
                    checked={!checked}
                  />
                  <span className="personal__form-checkmark" />
                </label>
              </button>
            </li>
            <li>
              <button
                type="button"
              >
                <label
                  className="personal-input__radio-wrapper"
                  htmlFor={noId}
                  // htmlFor="step-2-radiobutton2"
                >
                  {t('RegistrationAddress.inputSwitcherVariantNo')}
                  <input
                    type="radio"
                    value="1"
                    checked={checked}
                    onChange={inputHandler}
                    id={noId}
                    // id="step-2-radiobutton2"
                  />
                  <span className="personal__form-checkmark" />
                </label>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default RadioButton;
