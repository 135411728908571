import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { PersonIdInterface } from '../../interfaces/personId.interface';

export interface personIdState {
  value: PersonIdInterface | null | undefined;
}
const cookies = new Cookies();
const initialState: personIdState = {
  ...cookies.get('person_id'),
};

export const personIdentifierSlice = createSlice({
  name: 'personId',
  initialState,
  reducers: {
    getValue: (state, action: PayloadAction<PersonIdInterface | null>) => {
      state.value = action.payload;
    },
  },
});

export const { getValue } = personIdentifierSlice.actions;

export default personIdentifierSlice.reducer;
