import { CURRENCIES } from './CurrenciesList.model';
import { CurrenciesListProps } from './CurrenciesList.props';

function CurrenciesList({
  onCurrencySelect,
  selectedCurrency,
}: CurrenciesListProps) {
  return (
    <div className="invoice-cards__currencies-list">
      {CURRENCIES.map(({ id, label, value }) => (
        <div
          aria-hidden
          key={id}
          onClick={() => onCurrencySelect(id)}
          className={`invoice-cards__currencies-list__item ${
            selectedCurrency === id ? 'selected' : ''
          }`}
        >
          {value}
        </div>
      ))}
    </div>
  );
}

export default CurrenciesList;
