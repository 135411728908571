import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useExpiredRegistrationMutation } from '../../app/services/registration.api';
import { redirectUrl } from '../../helpers/utilits';
import ModalWindow from '../ModalWindow/ModalWindow';
import { ExpiredRegistrationFormInterface } from '../../interfaces/registration.interface';
import { useModal } from '../../hooks/useModal';
import Input from '../Input/Input';

function ExpiredRegistrationForm() {
  const { t } = useTranslation();
  const [sendEmailRequest] = useExpiredRegistrationMutation();
  const [modalMessage, setModalMessage] = useState('');

  const expiredRegistrationSchema = yup
    .object({
      email: yup
        .string()
        .required(t('ExpiredRegistrationForm.formEmailValidation'))
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          t('ExpiredRegistrationForm.formEmailValidationPattern'),
        ),
    })
    .required();

  const { handleSubmit, reset, control, setError } =
    useForm<ExpiredRegistrationFormInterface>({
      defaultValues: {
        email: '',
      },
      resolver: yupResolver(expiredRegistrationSchema),
      mode: 'onChange',
    });

  const { isShown, toggle } = useModal();

  const onSubmit: SubmitHandler<ExpiredRegistrationFormInterface> = async (
    formData: ExpiredRegistrationFormInterface,
  ) => {
    await sendEmailRequest({ ...formData, redirectUrl })
      .unwrap()
      .then((response) => {
        toggle();
        setModalMessage(response.message);
        reset();
      })
      .catch((error) => {
        setError('email', {
          type: 'email-user',
          message: error.data.message.email,
        });
      });
  };

  return (
    <>
      <ModalWindow
        isShown={isShown}
        hide={toggle}
        modalContent={modalMessage}
      />
      <form
        className="login__form login__form--white"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="login__form-heading">
          {t('ExpiredRegistrationForm.formTitle')}
        </h1>

        <div className="login__form-inputs">
          <Input
            className="login__form-input"
            control={control}
            name="email"
            placeholder="Email"
          />
        </div>

        <div className="login__btn-container">
          <button className="login__btn btn btn--black" type="submit">
            {t('ExpiredRegistrationForm.formButton')}
          </button>
        </div>
      </form>
    </>
  );
}

export default ExpiredRegistrationForm;
