import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

export interface userTokenState {
  token: string | null | undefined;
}

const cookies = new Cookies();

const initialState: userTokenState = {
  token: cookies.get('logged_in'),
};

export const userTokenSlice = createSlice({
  name: 'userToken',
  initialState,
  reducers: {
    getUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
  },
});

export const { getUserToken } = userTokenSlice.actions;

export default userTokenSlice.reducer;
