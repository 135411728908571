import React from 'react';
import { useForm } from 'react-hook-form';
import Input from '../Input/Input';
import { BankAccountInterface } from '../../interfaces/bankAccount.interface';
import { Button } from '../Button/Button';
import { BankAccountDetailsProps } from './BankAccountDetails.props';
import { useTranslation } from 'react-i18next';

function BankAccountDetails({ showForm }: BankAccountDetailsProps) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<BankAccountInterface>({
    defaultValues: {
      swift: '',
      senderBankName: '',
      senderBankAddress: '',
      senderAccountNumber: '',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="section-bank-account-form"
    >
      <div className="section-bank-account-form__information">
        <h3 className="section-bank-account-form__information-title">
          {t('Invoice.banDetailsTitle')}
        </h3>
        <p className="section-bank-account-form__information-subtitle">
          {t('Invoice.bankDetailsNeeds')}
        </p>
        {showForm && (
          <div className="section-bank-account-form__information-fields">
            <Input
              className="input input-default"
              control={control}
              name="swift"
              placeholder={t('Invoice.bankSwift')}
            />{' '}
            <Input
              className="input input-default"
              control={control}
              name="senderBankName"
              placeholder={t('Invoice.bankName')}
            />{' '}
            <Input
              className="input input-default"
              control={control}
              name="senderBankAddress"
              placeholder={t('Invoice.bankAddress')}
            />{' '}
            <Input
              className="input input-default"
              control={control}
              name="senderAccountNumber"
              placeholder={t('Invoice.bankNumber')}
            />
          </div>
        )}
      </div>
      {showForm && <Button type="submit">{t('Invoice.pay')}</Button>}
    </form>
  );
}

export default BankAccountDetails;
