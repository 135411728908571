import React from 'react';

interface Props {
  isActive?: boolean;
}
export const NadiaFryEventsIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="62"
      height="33"
      viewBox="0 0 62 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_333)">
        <path
          d="M21.722 30.626C19.2445 31.388 18.1266 32.8241 18.1266 32.8241H23.2025C23.3536 32.531 24.834 31.5346 24.834 31.5346C24.9247 30.3916 24.1995 29.864 21.722 30.626Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M14.7127 28.4577C14.048 28.78 12.1143 30.6264 12.1143 30.6264C12.5675 28.399 13.5343 27.9887 13.5343 27.9887C13.5343 27.9887 11.1172 26.582 10.3317 26.2303C9.51593 25.8786 9.03252 25.7614 8.06569 25.9372C7.09886 26.0838 4.71199 27.2267 2.44598 28.487C0.179971 29.7472 0.0289041 30.2747 0.0289041 30.4212C0.0289041 30.4212 -0.333658 31.1246 1.1468 31.6814C2.62726 32.2383 7.15928 32.8537 9.667 32.8537C12.1445 32.8537 16.9787 32.8537 16.9787 32.8537C18.9728 31.066 20.7251 30.3626 20.7251 30.3626C18.1268 30.0989 14.7127 28.4577 14.7127 28.4577Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M19.9398 29.3363C21.6016 29.6 22.9309 29.6293 23.2331 28.6915C23.5352 27.7537 22.9612 27.2261 21.813 27.021C20.6649 26.8158 18.1572 26.5521 15.7704 27.8709C15.7704 27.8709 18.2781 29.0432 19.9398 29.3363Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M5.92134 23.3C8.97289 25.0291 14.7135 27.3737 14.7135 27.3737C17.1305 26.3186 19.8195 26.2014 19.8195 26.2014C18.3089 25.4687 14.9552 22.2449 14.9552 22.2449C13.7466 22.5087 11.6619 23.5638 11.6619 23.5638C11.6619 23.5638 12.5683 22.3329 13.0819 21.8639C13.5956 21.395 14.1092 21.2778 14.1092 21.2778C13.5351 20.7503 12.387 19.0798 11.9942 18.6401C11.6015 18.2005 10.8461 17.4679 9.93973 17.4092C9.03332 17.3506 7.00902 17.7316 4.98472 18.3471C2.96042 18.9332 1.66124 19.4021 1.78209 20.1055C1.90294 20.8089 2.86978 21.6002 5.92134 23.3Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M22.265 23.6222C21.4795 23.1826 19.425 21.8051 16.2828 22.1275C16.2526 22.1275 18.0049 23.8859 19.3948 24.9996C20.6939 26.0254 21.7514 26.2012 22.5067 25.7909C23.4131 25.322 23.0506 24.0618 22.265 23.6222Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M10.0296 15.4455C12.9301 18.9917 15.4378 21.2484 15.4378 21.2484C18.9123 21.2777 20.5741 22.0983 20.5741 22.0983C19.2447 20.5743 17.7038 16.6178 17.7038 16.6178C17.6132 16.6178 14.2293 16.4713 14.2293 16.4713C16.1931 15.1818 17.2204 15.4162 17.2204 15.4162C16.9183 14.6542 16.4953 12.4855 16.0421 11.577C15.5889 10.6391 15.1357 10.1995 14.0782 10.0237C13.0509 9.84785 9.90873 9.32032 8.15635 9.40824C6.40397 9.49617 6.46439 10.1409 6.7061 10.7857C6.94781 11.3718 7.09888 11.8994 10.0296 15.4455Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M23.6581 20.2219C21.3316 17.4377 18.9146 16.9102 18.9146 16.9102C18.9146 16.9102 19.9418 20.0167 21.241 21.5407C22.5402 23.0647 23.507 22.6251 23.7789 22.4785C24.0811 22.3613 24.6853 21.4235 23.6581 20.2219Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M18.4281 15.8548C18.4281 15.8548 21.6307 17.1736 22.8997 18.4925C22.8997 18.4925 22.0839 14.8291 22.3861 12.4259C22.3861 12.4259 20.6639 11.488 19.1834 11.0191C19.1834 11.0191 21.1473 10.5795 22.3861 11.0777C22.3861 11.0777 22.9601 8.49871 22.8997 7.50226C22.8695 6.50582 22.658 5.48006 21.6307 5.01115C20.4222 4.45431 17.8238 2.93033 16.4038 2.98895C14.9838 3.04756 15.1046 3.42856 15.0744 4.10262C15.0744 4.77669 15.2255 6.50582 16.011 8.96763C16.8268 11.4001 18.4281 15.8548 18.4281 15.8548Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M23.3537 13.1299C23.3537 13.1299 23.3537 16.6174 23.6256 17.907C23.8976 19.1965 24.5623 20.1929 25.5895 20.105C26.6168 20.0171 26.8283 18.8155 26.3146 17.4674C25.8312 16.1192 24.9248 14.1556 23.3537 13.1299Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M29.0048 11.2832C28.9746 11.547 27.6755 14.1553 27.4337 15.4448C27.192 16.7637 27.1618 18.2876 28.461 18.5514C29.7602 18.8152 29.9112 17.2619 30.0321 16.3827C30.153 15.4741 30.0925 13.8329 29.7904 12.8951C29.5185 12.1038 29.0048 11.2832 29.0048 11.2832Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M40.0601 30.6258C37.5826 29.8638 36.8877 30.4206 36.9482 31.5636C36.9482 31.5636 38.3984 32.5601 38.5797 32.8531H43.6556C43.6253 32.8238 42.5074 31.3878 40.0601 30.6258Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M61.7267 30.3921C61.7267 30.2455 61.5454 29.718 59.3096 28.4578C57.0738 27.1976 54.6869 26.0546 53.6899 25.9081C52.723 25.7615 52.2396 25.8788 51.4239 26.2012C50.6081 26.5528 48.2212 27.9596 48.2212 27.9596C48.2212 27.9596 49.1881 28.3699 49.6413 30.5972C49.6413 30.5972 47.7076 28.7802 47.0429 28.4285C47.0429 28.4285 43.6288 30.099 41.0606 30.3335C41.0606 30.3335 42.813 31.0368 44.8071 32.8246C44.8071 32.8246 49.6413 32.8246 52.1188 32.8246C54.5963 32.8246 59.1585 32.2384 60.639 31.6523C62.1194 31.0955 61.7267 30.3921 61.7267 30.3921Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M41.8131 29.3363C43.4748 29.0725 45.9825 27.8709 45.9825 27.8709C43.5957 26.5521 41.0577 26.8158 39.9398 27.021C38.7917 27.2261 38.2479 27.7537 38.5198 28.6915C38.8219 29.6293 40.1513 29.6 41.8131 29.3363Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M51.875 17.4093C50.9686 17.4679 50.2133 18.1712 49.8205 18.6402C49.4277 19.0798 48.2796 20.7796 47.7055 21.2778C47.7055 21.2778 48.2192 21.395 48.7328 21.864C49.2464 22.3329 50.1528 23.5638 50.1528 23.5638C50.1528 23.5638 48.0681 22.5087 46.8596 22.2449C46.8596 22.2449 43.5059 25.4687 41.9952 26.2014C41.9952 26.2014 44.6842 26.3187 47.1013 27.3737C47.1013 27.3737 52.8418 25.0291 55.8934 23.3C58.945 21.5709 59.9118 20.7796 60.0326 20.0762C60.1837 19.3728 58.8845 18.9039 56.83 18.3178C54.8057 17.7316 52.7512 17.3506 51.875 17.4093Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M39.2447 25.7909C40 26.2012 41.0575 26.0254 42.3567 24.9996C43.7465 23.8859 45.4989 22.1275 45.4686 22.1275C42.3264 21.8051 40.2719 23.1826 39.4864 23.6222C38.7008 24.0618 38.3685 25.322 39.2447 25.7909Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M45.7418 11.5477C45.2886 12.4855 44.8656 14.625 44.5635 15.3869C44.5635 15.3869 45.5907 15.1818 47.5546 16.442C47.5546 16.442 44.1707 16.5885 44.08 16.5885C44.08 16.5885 42.5392 20.545 41.2098 22.069C41.2098 22.069 42.8715 21.2484 46.3461 21.2191C46.3461 21.2191 48.8538 18.9624 51.7543 15.4163C54.6548 11.8701 54.836 11.3426 55.0778 10.6978C55.3195 10.053 55.3799 9.40827 53.6275 9.32035C51.8751 9.23243 48.7329 9.75996 47.7057 9.93581C46.6482 10.141 46.195 10.6099 45.7418 11.5477Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M38.0975 20.2219C37.1005 21.4235 37.6746 22.3613 37.9767 22.4785C38.2788 22.6251 39.2457 23.0647 40.5146 21.5407C41.8138 20.0167 42.8411 16.9102 42.8411 16.9102C42.8411 16.9102 40.4542 17.467 38.0975 20.2219Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M42.5987 10.9898C41.1182 11.4587 39.3961 12.3966 39.3961 12.3966C39.6982 14.7998 38.8824 18.4632 38.8824 18.4632C40.1514 17.1444 43.354 15.8255 43.354 15.8255C43.354 15.8255 44.9553 11.4001 45.7409 8.93833C46.5264 6.47652 46.6775 4.7767 46.6775 4.07333C46.6775 3.39926 46.7681 3.01827 45.3481 2.95965C43.9281 2.90104 41.3297 4.42501 40.1212 4.98185C39.0939 5.45077 38.9126 6.44721 38.8522 7.47297C38.822 8.46941 39.3658 11.0484 39.3658 11.0484C40.6348 10.5795 42.5987 10.9898 42.5987 10.9898Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M38.1298 17.907C38.4017 16.6174 38.4017 13.1299 38.4017 13.1299C36.8306 14.1556 35.9242 16.1192 35.4408 17.4674C34.9574 18.8155 35.1689 20.0171 36.1659 20.105C37.1932 20.1929 37.8579 19.1965 38.1298 17.907Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M32.84 9.11468C32.84 9.11468 33.0515 8.35269 35.7707 7.76654C35.7707 7.76654 33.8672 9.40775 33.3838 10.199C33.3838 10.199 34.7132 13.1591 35.3175 16.0312C35.3175 16.0312 36.4656 13.5108 38.3388 11.9575C38.3388 11.9575 38.0669 8.03031 37.7043 5.59781C37.3418 3.16531 36.7979 0.791424 36.0728 0.205279C35.3477 -0.380865 34.5621 0.205279 33.9276 0.586273C33.2932 0.967267 31.148 2.96016 30.8761 3.5463C30.574 2.96016 28.459 0.967267 27.8245 0.586273C27.19 0.263894 26.4045 -0.351558 25.6794 0.234586C24.9542 0.820731 24.4104 3.19462 24.0478 5.62712C23.6853 8.05962 23.4134 11.9868 23.4134 11.9868C25.2866 13.5401 26.4347 16.0605 26.4347 16.0605C27.0692 13.1884 28.3684 10.2284 28.3684 10.2284C27.885 9.43706 25.9815 7.79585 25.9815 7.79585C28.7007 8.382 28.9122 9.14398 28.9122 9.14398L30.8459 5.83227L32.84 9.11468Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M32.7783 11.2832C32.7783 11.2832 32.2647 12.0745 31.9927 12.8951C31.6906 13.8329 31.6302 15.5034 31.751 16.3827C31.8719 17.2912 32.023 18.8152 33.3221 18.5514C34.6213 18.2876 34.5911 16.7343 34.3494 15.4448C34.1077 14.1553 32.8387 11.547 32.7783 11.2832Z"
          fill={!isActive ? 'black' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_333">
          <rect width="61.7865" height="32.8241" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
