import React from 'react';

export const ProfileForm = () => {
  const data = {
    name: 'Team Marketing',
    passportInformation:
      'ABAMARKETING, Ministry of Internal Affair , годен до  01/02/2050',
    address: 'Russia, Stockholm, Stridsbergavagen, 145/2, 115110',
    email: 'ezhov.maxim@gmail.com, 1it@7flags.com',
    phone: '+19162200405',
  };

  const onChangeImage = () => {};

  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <form action="section-launch-product-main__form section-launch-settings-main__form ng-untouched ng-pristine ng-valid">
              <div className="section-launch-product-main__form-section first-section">
                <div className="section-launch-product-main__form-section-heading">
                  Фотография
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <div className="section-launch-settings-main__load-avatar">
                    <div className="section-launch-settings-main__load-avatar-preview">
                      <img src="img-gray" alt="avatar" />
                    </div>
                    <input
                      type="file"
                      id="load-avatar"
                      className="section-launch-settings-main__form-input-load-avatar"
                      onChange={onChangeImage}
                    />
                    <label
                      className="section-launch-product-main__form-link link link--launch"
                      htmlFor="load-avatar"
                    >
                      Загрузить фото
                    </label>
                  </div>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Имя и фамилия
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    Team Marketing
                  </p>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Имя и фамилия
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    {data.name}
                  </p>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Паспортные данные
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    {data.passportInformation}
                  </p>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Адрес проживания
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    {data.address}
                  </p>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Электронная почта
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    {data.email}
                  </p>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Контактный телефон
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <p className="section-launch-product-main__form-text">
                    {data.phone}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Профиль
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                Принимая во внимание современные тенденции по использованию
                электронного документооборота, мы исходим из привычного
                «золотого» правила, что участник Клуба по умолчанию
                предоставляет достоверную информацию, содержащую его
                персональные данные, и поддерживает ее в актуальном состоянии.
                При этом Клуб 7 flags оставляет за собой право проводить
                дополнительные проверки согласно требованиям о международном
                регулировании протокола KYC (от англ. Know Your Client - «знай
                своего клиента»).
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
