import { AccountSavings } from '../../AccountSavings/AccountSavings';
import React from 'react';
import { LastTransactions } from '../../../components/Programs/LastTransactions/LastTransactions';
import { useTranslation } from 'react-i18next';
import { ContractInterface } from "../../../interfaces/contract.interface";
import { ContractsInterface} from "../../../interfaces/contracts.interface";
import axiosApi from "../../../helpers/axios.utils";
import { array } from "yup";
import Loader from 'react-loader-advanced';


export const GoldenGeneralStatement = () => {
  interface FilledData {
    accounts: [
      {
        accountStatus: number;
        accountTypeId: string;
        activationDate: string;
        contractId: string;
        currencyId: string;
        id: string;
        number: string;
        personId: string;
        balance: number;
      }
    ];
    id: string;
    personId: string;
    contractTypeId: string;
    currencyId: string;
    contractNumber: string;
    contractAmount: number;
    contractStatus: number;
  }
  const { t } = useTranslation();
  const [contracts, setContracts] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [balances, setBalances] = React.useState([]);
  const [filledData, setFilledData] = React.useState<FilledData[] | []>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  // getting contracts, accounts and balances from server
  React.useEffect(() => {
    setIsLoading(true);
    const getContracts = async () => {
      const response = await axiosApi.get('contracts?contractStatus=1');
      setContracts(response.data);
      if (response.data.length === 0) {
        setIsLoading(false);
      }
    };
    (async () => {
      await getContracts();
    }
    )();
  }, []);

  React.useEffect(() => {
      const getAccounts = async (contracts: any) => {
        if (contracts.length > 0) {
          //const accounts = await Promise.all(
            //contracts.map(async (contract: ContractInterface) => {
              const response = await axiosApi.get(`accounts?accountStatus=1`);
          if (response.data.length > 0) {
            setAccounts(response.data);
            setIsLoading(false);
          }
           // }),
          //);
        } else {
          // setIsLoading(false);
        }
      };
    (async () => {
      await getAccounts(contracts);
    })();
  }, [contracts]);

   /* React.useEffect(() => {
      const getBalances = async (accounts: any) => {
        const fetchedBalances: any = []
        await Promise.all(
          accounts.map(async (account: any) => {
            const response = await axiosApi.get(`balances?accountId=${account.id}`);
            fetchedBalances.push(response.data);
          }),
        );
        // flatten array of arrays
        const flattenBalances = [].concat.apply([], fetchedBalances);
        setBalances(flattenBalances);

      }
      //setFilledData(fitAccountsToContracts(contracts, accounts))
      //console.log('fittedData', fitAccountsToContracts(contracts, accounts));
      (async () => {
        await getBalances(accounts);
      })();
      // setIsLoading(false);
  }, [accounts]); */

  React.useEffect(() => {
    console.log('balances', balances);
    // const fittedAccountesAndBalances = fitBalancesToAccounts(accounts, balances);
    const fittedData = fitAccountsToContracts(contracts, accounts);
    setFilledData(fittedData);
    if (fittedData.length > 0) {
      setIsLoading(false);
    }
    console.log('fittedData', fittedData);
  }, [accounts]);

  //fit accounts to contracts by contractId
  const fitAccountsToContracts = (contracts: any, accounts: any) => {
    const contractsWithAccounts = contracts.map((contract: ContractInterface) => {
      const accountsByContractId = accounts.filter((account: any) => account.contractId === contract.id);
      return { ...contract, accounts: accountsByContractId };
    });
    return contractsWithAccounts;
  }
  // fit balances to accounts by accountId
  /* const fitBalancesToAccounts = (accounts: any, balances: any) => {
    accounts.forEach((account: any) => {
      balances.forEach((balance: any) => {
        // console.log('balance', balance);
        // console.log('account', account.id);
        console.log(account.id === balance.accountId);
      })
    })
    const accountsWithBalances = accounts.map((account: any) => {
      console.log('accountId', account.id);
      console.log('balances', balances[1]?.accountId);
      const balancesByAccountId = balances.filter((balance: any) => balance.accountId === account.id);
      return { ...account, balances: balancesByAccountId[0] ? balancesByAccountId[0] : { balance: 0 } };
    });
    return accountsWithBalances;
  } */

  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <h3 className="section-launch-product-right__section-heading">
              {t('Programs.myContracts')}
            </h3>
            <Loader show={isLoading} message={'Loading...'} style={{minHeight: '200px'}}>
              <div className="section-launch-product__contacts">
                  {filledData.length > 0 && filledData.map((contract: FilledData) => {
                    return (
                      <AccountSavings
                        name={contract.contractNumber}
                        maxValue={contract.contractAmount}
                        currency={contract.currencyId}
                        wallets={contract.accounts}
                      />
                    );
                  })}
                {!isLoading && filledData.length === 0 && <p>{t('Programs.noContracts')}</p>}
              </div>
            </Loader>
          </div>
        </div>
      </div>
      <LastTransactions />
    </>
  );
};
