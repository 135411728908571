import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Layout from '../layout/Layout';
import LayoutRegistration from '../layout/LayoutRegistration';
import { userGuest, userAuth, userAuthProducts } from './user';
import { LayoutProduct } from '../layout/LayoutProduct';

interface RoutingInterface {
  userToken: string | undefined | null;
}

function Routing({ userToken }: RoutingInterface) {
  // console.log({ userToken });
  return (
    <Router>
      {userToken ? (
        <Routes>
          {userAuthProducts.map((route) => (
            <Route element={<LayoutProduct type={route.type} />}>
              <Route key={route.path} path={route.path} element={route.element}>
                {route.subElements &&
                  route.subElements.map((subRoute) => {
                    return (
                      <Route
                        key={subRoute.path}
                        path={subRoute.path}
                        element={subRoute.element}
                      >
                        {subRoute.subElements.map((subRoute) => {
                          return (
                            <Route
                              key={subRoute.path}
                              path={subRoute.path}
                              element={subRoute.element}
                            />
                          );
                        })}
                      </Route>
                    );
                  })}
              </Route>
            </Route>
          ))}
          <Route element={<LayoutRegistration />}>
            {userAuth.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route element={<Layout />}>
            {userGuest.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        </Routes>
      )}
    </Router>
  );
}

export default Routing;
