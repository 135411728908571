import { SpecialCardProps } from './SpecialCard.props';
import ProgramCard from '../ProgramCard/ProgramCard';

export const SpecialCard = ({
  title,
  name,
  type,
  icon,
  onClick,
}: SpecialCardProps) => {
  return (
    <div className={`special-card special-card-${type}`} onClick={onClick}>
      <div className={'special-card__information'}>
        <h4 className="special-card__information--title">{title}</h4>
        <ProgramCard
          icon={icon}
          name={name}
          type={type}
          border
          transparent
          size={'big'}
        />
      </div>
      <p className="special-card--more">More about +</p>
    </div>
  );
};
