import { AccountSavings } from '../../AccountSavings/AccountSavings';
import React from 'react';
import { LastTransactions } from '../../../components/Programs/LastTransactions/LastTransactions';

export const SilverGeneralStatement = () => {
  const contracts = [
    { name: 'First Standart', maxValue: 90000000, currency: 'USD' },
    { name: 'First Standart', maxValue: 90000000, currency: 'EUR' },
    { name: 'First Professional', maxValue: 1000000, currency: 'EUR' },
  ];

  const wallets = [
    {
      accountStatus: 3,
      accountTypeId: "c7e4b4d2-306b-4a9c-9947-ec31147e46c2",
      activationDate: "2020-10-09",
      contractId: "5b8dbac5-9add-4777-8a03-303b4c09ad9f",
      currencyId: "22491dd5-e7a3-4834-b15e-ee393c1b30dd",
      id: "fffcb3ba-0dc6-46ed-894d-275ad05e4a28",
      number: "UZ89ABA20201009139320",
      personId: "0000fa75-d4d0-4e59-aa67-aad592782582",
      balance: 1000000,
    },
    {
      accountStatus: 3,
      accountTypeId: "c7e4b4d2-306b-4a9c-9947-ec31147e46c2",
      activationDate: "2020-10-09",
      contractId: "5b8dbac5-9add-4777-8a03-303b4c09ad9f",
      currencyId: "22491dd5-e7a3-4834-b15e-ee393c1b30dd",
      id: "fffcb3ba-0dc6-46ed-894d-275ad05e4a28",
      number: "UZ89ABA20201009139320",
      personId: "0000fa75-d4d0-4e59-aa67-aad592782582",
      balance: 1000000,
    },
  ];

  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <h3 className="section-launch-product-right__section-heading">
              Мои контракты
            </h3>
            <div className="section-launch-product__contacts">
              {contracts.map(({ name, maxValue, currency }) => {
                return (
                  <AccountSavings
                    name={name}
                    maxValue={maxValue}
                    currency={currency}
                    wallets={wallets}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <LastTransactions />
    </>
  );
};
