import { createApi } from '@reduxjs/toolkit/query/react';

import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { DocumentInterface } from '../../interfaces/document.interface';
import { DocumentSignatureInterface } from '../../interfaces/documentSignature.interface';

const documents = [
  {
    documentType: '3050da9b-fab2-4e0f-8d02-49688a2a0b58',
    name: 'Bylaws of 7Flags Ltd',
    content: 'first',
  },
  {
    documentType: '2a06be87-2d9e-4e34-9255-b1f2f2eb2b13',
    name: 'Rules of Ethics of the Club Members',
    content: 'second',
  },
  {
    documentType: 'c346a7c0-b008-428a-ad10-32eeb8ebed5a',
    name: 'Club Accession Agreement',
    content: 'third',
  },
  {
    documentType: '940a5b80-1c44-40a0-ace7-7b7a56067558',
    name: 'Mutual Assistance',
    content: 'fourth',
  },
  // { id: 5, name: 'Mutual Assistance 22', content: 'fifth' },
];
// merge parsed data with documents array by documentType

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentInterface[], void>({
      query: () => '/documents?collectionName=public_agreement_document',
      transformResponse: (response: DocumentInterface[]) =>
        // const parsedData = JSON.parse(response: any);
        response.map((document: any) =>
          // const parsedContent = JSON.parse(document.content);
          ({
            ...document,
            ...documents.find(
              (item) => item.documentType === document.documentType,
            ),
          }),
        ),
      // transformResponse: () => documents,
    }),
    signDocument: builder.mutation<
      DocumentSignatureInterface,
      DocumentSignatureInterface
    >({
      query: (body) => ({
        url: `documents/sign`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetDocumentsQuery, useSignDocumentMutation } = documentApi;
