import React from 'react';
import { AgreementCheckboxProps } from './agreementCheckbox.props';

export function AgreementCheckbox({
  value,
  selected,
  disabled,
  header,
  description,
  onClickBox,
  // register,
}: AgreementCheckboxProps) {
  return (
    <>
      <label
        className={`agreement-checkbox_wrapper ${
          selected ? 'agreement-checkbox_wrapper-selected' : ''
        } `}
      >
        <input
          type="checkbox"
          name="a_check"
          className="agreement-checkbox"
          // defaultChecked={value}
          // {...register('chooseCb')}
          disabled={disabled}
          onClick={onClickBox}
        />
        <div className="agreement-layout_wrapper">
          <div
            className={`agreement-checkbox_box ${
              selected ? 'agreement-checkbox_checked' : ''
            }`}
          ></div>

          <div className="agreement-checkbox_header">{header}</div>
        </div>
        <div className="agreement-checkbox_description">{description}</div>
      </label>
    </>
  );
}

