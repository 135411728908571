import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavMenuProps } from './NavMenu.props';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

function NavMenu({ openMenu }: NavMenuProps) {
  const { t } = useTranslation();
  return (
    <div className="nav-menu" data-nav-menu="">
      <div className="nav-menu__bg" />

      <div className="wrapper">
        <div className="container">
          <div className="nav-menu__inner">
            <div className="nav-menu__top">
              <a className="nav-menu__top-link" href="/">
                {t('NavigationBar.login')}
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </a>
              <button
                className="nav-menu__top-btn"
                type="button"
                onClick={openMenu}
              >
                <div className="nav-menu__top-btn-text">
                  {t('NavMenu.closeButton')}
                </div>
                <div className="nav-menu__top-btn-cross">+</div>
              </button>
            </div>

            <ul className="nav-menu__links">
              <li className="nav-menu__link-item">
                <a className="nav-menu__link" href="/">
                  {t('NavigationBar.about')}
                </a>
              </li>
              <li className="nav-menu__link-item">
                <a className="nav-menu__link" href="/">
                  {t('NavigationBar.directions')}
                </a>
              </li>
              <li className="nav-menu__link-item">
                <a className="nav-menu__link" href="/">
                  {t('NavigationBar.members')}
                </a>
              </li>
            </ul>

            <div className="nav-menu__bottom">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenu;
