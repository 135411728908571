import RangeSlider from '../RangeSlider/RangeSlider';
import { CurrencySliderCalculatorProps } from './CurrencySliderCalculator.props';

function CurrencySliderCalculator({
  currency,
  onChange,
  value,
}: CurrencySliderCalculatorProps) {
  return (
    <div className="currency-slider-calculator">
      <div className="currency-slider-calculator__information">
        <p className="currency-slider-calculator__information-value">
          {value ? Number(value) * 1000 : ''}
        </p>
        {false && (<span className="currency-slider-calculator__information-currency">
          {currency}
        </span>)}
      </div>
      <RangeSlider value={value} onChange={onChange} />
    </div>
  );
}

export default CurrencySliderCalculator;
