import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ProgramInterface,
  TariffInterface,
} from '../../interfaces/program.interface';

import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';

const mockPrograms: ProgramInterface[] = [{ id: 1, type: 'Golden' }];
const mockTariffs: { golden: TariffInterface[]; silver: TariffInterface[] } = {
  golden: [
    { id: 1, from: '10 000', to: '50 000', rate: 10, special: false },
    { id: 2, from: '50 001', to: '100 000', rate: 20, special: false },
    { id: 3, from: '100 001', to: '250 000', rate: 30, special: false },
    { id: 3, from: '250 001', to: '500 000', rate: 50, special: false },
  ],
  silver: [
    { id: 1, from: '50 000', to: '80 000', rate: 10, special: false },
    { id: 2, from: '80 001', to: '100 000', rate: 20, special: false },
    { id: 3, from: '100 001', to: '350 000', rate: 30, special: false },
    { id: 3, from: '350 001', to: '500 000', rate: 50, special: false },
  ],
};

export const programApi = createApi({
  reducerPath: 'programApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    getTariffs: builder.query<
      { golden: TariffInterface[]; silver: TariffInterface[] },
      void
    >({
      query: () => 'people/me',
      transformResponse: () => mockTariffs,
    }),
    getPrograms: builder.query<ProgramInterface[], void>({
      query: () => 'people/me',
      transformResponse: () => mockPrograms,
    }),
  }),
});

export const { useGetTariffsQuery, useGetProgramsQuery } = programApi;
