import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import PersonalCabinetInformation from '../../components/PersonalCabinetInformation/PersonalCabinetInformation';

function PersonalCabinet() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <PersonalCabinetInformation />
      </AnimationWrapper>
    </section>
  );
}

export default PersonalCabinet;
