import { ProductInformationProps } from './ProductInformation.props';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const SmallCard = ({ icon, name }: Omit<ProductInformationProps, 'type'>) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const onToggleHovered = () => {
    setIsHovered((prevState) => !prevState);
  };

  return (
    <div
      className="product-information-card product-information-card--small"
      onMouseEnter={onToggleHovered}
      onMouseLeave={onToggleHovered}
    >
      <div className="product-information-card--background" />
      <div className="product-information-card--icon">{icon(isHovered)}</div>
      <p className="product-information-card--name">{t(name)}</p>
    </div>
  );
};

const ExtendedCard = ({
  icon,
  name,
  title = '',
  to = '',
}: Omit<ProductInformationProps, 'type'>) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const onToggleHovered = () => {
    setIsHovered((prevState) => !prevState);
  };

  return (
    <NavLink
      className="product-information-card product-information-card--extended"
      onMouseEnter={onToggleHovered}
      onMouseLeave={onToggleHovered}
      to={to}
    >
      <div className="product-information-card--background" />
      <p className="product-information-card--name product-information-card--extended-name">
        {t(name)}
      </p>
      <div className="product-information-card--icon product-information-card--extended-icon">
        {icon(isHovered)}
      </div>
      <p className="product-information-card--extended-title">{t(title)}</p>
    </NavLink>
  );
};

function ProductInformationCard({
  icon,
  name,
  type,
  title,
  to,
}: ProductInformationProps) {
  const getCardByType = () => {
    switch (type) {
      case 'small':
        return <SmallCard name={name} icon={icon} />;
      case 'extended':
        return <ExtendedCard name={name} icon={icon} title={title} to={to} />;
    }
  };
  return getCardByType();
}

export default ProductInformationCard;
