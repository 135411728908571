import { createApi } from '@reduxjs/toolkit/query/react';
// import { CountryModel } from '../../components/CountryList/CountryList.model';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';

type ApiItem = {
  id: string;
  name: string;
};
type Response = ApiItem[];
// type Countries = CountryModel[];

export const countryApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    getCountryList: builder.query<Response, void>({
      query: () => 'countries',
      transformResponse(baseQueryReturnValue: Response) {
        return baseQueryReturnValue.map((item: ApiItem) => ({
          id: item.id,
          name: item.name,
        }));
      },
    }),
  }),
});

export const { useGetCountryListQuery } = countryApi;
