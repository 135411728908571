import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import Cookies from 'universal-cookie';

// TODO - make it env variable
// const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const baseUrl = 'https://7api.profitcode.by/api/';
export function sevenFlagsG3BaseQuery() {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers: Headers) => {
      const cookies = new Cookies();
      const isUserLoggedIn = cookies.get('logged_in');
      headers.set('Accept', `application/json`);
      if (isUserLoggedIn) {
        headers.set('Authorization', `Bearer ${isUserLoggedIn.token}`);
      }
      return headers;
    },
    // credentials: 'include',
  });
}
