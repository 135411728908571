import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { AnimationWrapperProps } from './AnimationWrapper.props';

function AnimationWrapper({ children, className }: AnimationWrapperProps) {
  const animation = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };

  return (
    <motion.div
      className={className}
      variants={animation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
}

export default AnimationWrapper;
