import React from 'react';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';
import LoginInitiateForm from '../../components/LoginInitiateForm/LoginInitiateForm';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';

function UserSignIn() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <main className="main-login">
        <div className="wrapper">
          <div className="container">
            <LoginInitiateForm />
          </div>
        </div>
      </main>
    </>
  );
}

export default UserSignIn;
