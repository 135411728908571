import { createApi } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'universal-cookie';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { FirstLineInterface } from '../../interfaces/firstLine.interface';

const cookies = new Cookies();
const person = cookies.get('person_id');
export const personFirstLineApi = createApi({
  reducerPath: 'personFirstLine',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    personFirstLine: builder.query<FirstLineInterface, void>({
      query: () => ({
        url: `people?parentPersonId=${person.personId}`,
        method: 'GET',
      }),
    }),
  }),
});
export const { usePersonFirstLineQuery } = personFirstLineApi;
