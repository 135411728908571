import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import { PersonIdInterface } from '../../interfaces/personId.interface';
import { PersonLoginInterface } from '../../interfaces/personLogin.interface';
import Input from '../Input/Input';
import { NavLink, useNavigate } from 'react-router-dom';
import { getUserToken } from '../../app/features/userToken';
import { getValue } from '../../app/features/personIdentifier';
import { useAppDispatch } from '../../hooks/redux';
import { useModal } from '../../hooks/useModal';
import ModalWindow from '../ModalWindow/ModalWindow';
import { useLoginInitiateMutation } from "../../app/services/login.api";
import { redirectUrl } from '../../helpers/utilits';
import { usePersonIdMutation} from "../../app/services/personId.api";

function LoginInitiateForm() {
  const [sendInitiateRequest] = useLoginInitiateMutation();
  const [sendPersonIdRequest] = usePersonIdMutation();
  const [modalMessage, setModalMessage] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { isShown, toggle } = useModal();

  const loginInitiateSchema = yup
    .object({
      username: yup
        .string()
        .required(),
      password: yup.string().required(),
    })
    .required();

  const { handleSubmit, reset, control, setError } =
    useForm<PersonLoginInterface>({
      defaultValues: {
        username: '',
        password: '',
      },
      resolver: yupResolver(loginInitiateSchema),
      mode: 'onChange',
    });

  const onSubmit: SubmitHandler<PersonLoginInterface> = async(
    formData: PersonLoginInterface
  ) => {
    await sendInitiateRequest({ ...formData, redirectUrl })
      .unwrap()
      .then((response) => {
        toggle();
        if (response) {
          cookies.set('logged_in', response, {
            path: '/',
            //secure: true,
            // expires: new Date(3600 * 1000),
          });
          console.log('Getted token: ', getUserToken(cookies.get('logged_in')))
          dispatch(getUserToken(cookies.get('logged_in')));
        }
        reset();
      })
      .catch((error) => {
        setError('username', {
          type: 'email-user',
          message: error?.data?.message?.email,
        });
      })
    await sendPersonIdRequest()
      .unwrap()
      .then((response) => {
        if (response) {
          cookies.set('person_id', response, {
            path: '/',
            //secure: true,
            // expires: new Date(3600 * 1000),
          });
          dispatch(getValue(cookies.get('person_id')));
        }
      }).catch((error) => {
        console.log(error)
      })
    if (cookies.get('logged_in') && cookies.get('person_id')) {
      const person = cookies.get('person_id');
      console.log(person)
      if (person) {
        const onboardingStatus = person.person.onboardingStep
        console.log(onboardingStatus)
        // switch navigation to onboarding if onboarding is not completed yet onboardingStatus < 3
        switch (onboardingStatus) {
          case 0:
            navigate('/candidates/welcome');
            break;
          case 1:
            navigate('/candidates/agreement');
            break;
          case 2:
            navigate('/candidates/upload-documents');
            break;
          case 3:
            navigate('/candidates/awaiting-approval');
            break;
          case 4:
            navigate('/candidates/documents');
            break;
          case 5:
            navigate('/candidates/awaiting-approval');
            break;1

        }
      }
      // navigate('/candidates/cabinet');
    } else {
      setModalMessage('wrong credentials');
      toggle()
    }
  }
  return (
    <>
      <ModalWindow
        isShown={isShown}
        hide={toggle}
        modalContent={modalMessage}
      />
      <form
        className="login__form login__form--white"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="login__form-heading">{t('CandidateLogin.signIn')}</h1>

        <div className="login__form-inputs">
          <Input
            className="login__form-input"
            control={control}
            name="username"
            placeholder={t('CandidateLogin.login')}
          />

          <Input
            className="login__form-input"
            type="password"
            control={control}
            name="password"
            placeholder={t('CandidateLogin.password')}
          />
        </div>

        <div className="login__btn-container">
          <button className="login__btn btn btn--black" type="submit">
            {t('CandidateLogin.formButton')}
          </button>
        </div>
        <div className={'login__forgot-container'}>
          <NavLink
            className="login__forgot link cursor"
            to="/candidates/forgot-password"
          >
            <>
              {t('CandidateLogin.forgotPassword')}?
            </>
          </NavLink>
        </div>
      </form>
    </>
  )
}

export default LoginInitiateForm;
