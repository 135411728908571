import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import { CandidateRegistrationFormInterface } from '../../interfaces/registration.interface';
import Input from '../Input/Input';
import { NavLink, useNavigate } from 'react-router-dom';
import { getUserToken } from '../../app/features/userToken';
import { useAppDispatch } from '../../hooks/redux';

const MOCK_VALID_EMAIL = 'victor.slavin';
const MOCK_VALID_PASSWORD = 'Rjk,fcfDfhtyyfz1989';

function RegistrationInitiateForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const registrationInitiateSchema = yup
    .object({
      login: yup
        .string()
        .required(t('registrationInitiate.formEmailValidation')),
      password: yup.string().required(),
    })
    .required();

  const { handleSubmit, control, setError } =
    useForm<CandidateRegistrationFormInterface>({
      defaultValues: {
        login: '',
        password: '',
      },
      resolver: yupResolver(registrationInitiateSchema),
      mode: 'onChange',
    });

  const onSubmit = async (formData: CandidateRegistrationFormInterface) => {
    if (
      formData.login === MOCK_VALID_EMAIL &&
      formData.password === MOCK_VALID_PASSWORD
    ) {
      const customToken = 'user test token till the backend is ready';
      cookies.set('logged_in', customToken, {
        path: '/',
        //secure: true,
        // expires: new Date(3600 * 1000),
      });
      dispatch(getUserToken(customToken));
      navigate('/candidates/cabinet');
      return;
    }

    console.log('WERE');
    setError('password', {
      message: 'Credentials are invalid',
    });
  };

  return (
    <>
      <form
        className="login__form login__form--white"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="login__form-heading">{t('CandidateLogin.signIn')}</h1>

        <div className="login__form-inputs">
          <Input
            className="login__form-input"
            control={control}
            name="login"
            placeholder={t('CandidateLogin.login')}
          />

          <Input
            className="login__form-input"
            type="password"
            control={control}
            name="password"
            placeholder={t('CandidateLogin.password')}
          />
        </div>

        <div className="login__btn-container">
          <button className="login__btn btn btn--black" type="submit">
            {t('CandidateLogin.formButton')}
          </button>
        </div>
        <div className={'login__forgot-container'}>
          <NavLink
            className="login__forgot link cursor"
            to="/candidates/forgot-password"
          >
            <>
              {t('CandidateLogin.forgotPassword')}?
            </>
          </NavLink>
        </div>
      </form>
    </>
  );
}

export default RegistrationInitiateForm;
