import { useMemo, useRef, useState } from 'react';
import { SelectProps } from './Select.props';
import ArrowIcon from '../../assets/media/arrow.svg';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

function Select({ options, placeholder, value, onChange }: SelectProps) {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useState(false);
  // const [selectedValue, setSelectedValue] = useState('');
  const componentRef = useRef<HTMLDivElement>(null);

  const onToggleOpen = () => {
    toggleOpen((prevState) => !prevState);
  };

  const onSelect = (key: string) => {
    onChange(key);
  };

  const selectedLabel = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  useClickOutside(componentRef, onToggleOpen, isOpen);
  return (
    <div className="select" ref={componentRef}>
      <div
        className={`select__header ${isOpen ? 'select__header-open' : ''}`}
        onClick={onToggleOpen}
      >
        <p className="select__header-select-value">
          {t(selectedLabel?.label || placeholder || '')}
        </p>
        <img className="select__header-arrow" src={ArrowIcon} alt="arrow" />
      </div>
      {isOpen && (
        <ul className={'select__list'}>
          {options.map(({ label, value }) => {
            return (
              <li
                className={'select__list-item'}
                onClick={() => onSelect(value)}
              >
                {t(label)}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default Select;
