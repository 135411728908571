import React from 'react';

interface Props {
  isActive?: boolean;
}
export const DeksisIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="41"
      height="48"
      viewBox="0 0 41 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0003 15.654C24.0003 15.654 27.2372 15.6228 27.9842 16.2142C27.9842 16.2142 25.5877 15.8718 24.0003 16.4009C24.0003 16.4009 24.8095 17.9883 27.5485 18.1128C30.0384 18.2062 30.7854 16.4321 30.3808 15.7473C30.1007 15.2805 28.9491 14.0355 27.0505 14.1911C25.1208 14.3467 24.4049 14.9692 24.0003 15.654Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M22.3507 11.4214C22.3507 11.4214 18.8336 13.569 18.2111 15.8411C18.2111 15.8411 19.1137 16.0901 19.674 15.81C20.0786 15.6232 23.8446 13.6624 24.934 12.2307C26.0233 10.799 26.1478 9.67848 24.6539 9.27386C23.1599 8.86924 21.2924 9.11824 19.5495 10.7056C17.8065 12.2929 17.8687 14.6895 17.8687 14.9385C17.8687 14.9696 19.4561 12.1996 22.3507 11.4214Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M14.2618 10.6436C14.2618 10.6436 14.4797 8.2781 13.7016 6.41063C12.8924 4.51204 11.4295 2.8002 11.4295 2.8002C11.4295 2.8002 13.6705 4.16967 14.4486 5.88151C15.2267 7.59336 15.7869 9.58532 15.7869 9.58532C15.7869 9.58532 17.561 5.57027 15.8492 2.58232C14.1685 -0.405621 9.9978 -0.530119 8.25483 0.839356C6.51186 2.20883 7.25885 4.41867 8.62832 6.03714C10.0289 7.68673 11.9275 9.70982 14.2618 10.6436Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M8.00396 11.6396C8.75095 12.1998 9.46681 12.3554 10.1515 12.4177C10.1515 12.4177 9.46681 10.8303 8.5642 10.1767C8.5642 10.1767 9.90255 10.9237 10.6807 12.0442C10.6807 12.0442 11.0542 11.1416 10.3694 10.2079C9.71581 9.27412 8.22183 7.9669 7.25697 8.83838C6.44774 9.492 7.1636 10.986 8.00396 11.6396Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M1.21915 15.4674C1.62377 15.7786 3.33561 16.7435 8.0354 14.8137C8.0354 14.8137 6.60368 13.5065 5.01633 13.2886C3.42899 13.0708 2.8065 13.2264 2.8065 13.2264C2.8065 13.2264 3.42899 12.5105 5.42095 12.7595C7.41292 13.0085 8.06653 13.5065 8.06653 13.5065C8.06653 13.5065 6.82155 11.4834 5.14083 10.9543C3.46011 10.4252 1.59265 11.1099 0.752287 12.7284C-0.0880724 14.3158 0.783412 15.1561 1.21915 15.4674Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M12.2671 17.7713C12.2671 17.7713 11.5513 16.184 10.0262 16.0284C8.50107 15.8727 7.19385 16.2462 7.19385 17.18C7.16272 18.1137 8.12758 18.9541 9.62155 19.0474C11.1155 19.1408 12.0804 18.3938 12.0804 18.3938C12.0804 18.3938 10.991 17.678 9.27918 17.5223C9.27918 17.5223 10.6798 17.2422 12.2671 17.7713Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M8.68833 24.1207C8.68833 24.1207 6.41625 23.5605 2.83694 25.6458C2.83694 25.6458 4.76665 23.685 8.15921 23.2803C8.15921 23.2803 5.63814 21.8486 2.89919 23.1558C0.160235 24.432 -0.182133 26.6729 0.0668624 27.6066C0.284733 28.5404 1.68533 28.7582 3.58392 27.8868C5.42026 27.0464 8.84395 25.3968 8.68833 24.1207Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M9.09356 29.6292C9.09356 29.6292 8.84457 28.6021 9.93392 26.9525C9.93392 26.9525 9.18694 26.8592 8.3777 27.8551C7.56847 28.8511 7.38172 30.2828 7.72409 30.8431C8.09758 31.4033 9.00019 31.8391 9.7783 30.9365C10.5564 30.0338 11.21 28.322 10.4008 27.2638C10.4319 27.2326 9.18694 28.571 9.09356 29.6292Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M39.5619 31.7767L39.593 31.7455L24.5599 20.5096L16.2497 27.9484H16.2186C16.2186 27.7927 16.2186 27.6371 16.2186 27.5126C16.2497 26.3299 17.1834 22.8751 18.9887 20.7586C20.3581 19.1401 21.821 18.2064 22.4123 17.8329C22.4435 17.8018 22.4746 17.7707 22.4435 17.7395C22.4435 17.7084 22.4123 17.6773 22.3501 17.6773C21.821 17.6773 20.7939 17.7084 20.0158 17.9885C18.9264 18.362 18.335 18.8289 17.4324 18.7666C16.5298 18.7044 16.4053 17.9885 16.2497 16.8058C16.0941 15.6853 15.0981 12.8219 13.6041 11.2968C13.573 11.2657 13.5419 11.2657 13.5108 11.2968C13.4796 11.3279 13.4485 11.359 13.4796 11.3902C13.6353 11.9504 13.9776 13.1331 14.0399 14.2225C14.1644 15.7165 14.0399 18.1753 13.2929 19.109C12.5459 20.0427 12.1724 20.2917 11.2698 20.4474C9.68245 20.7275 9.05996 19.7315 7.59712 19.7626C6.97463 19.7938 4.73367 19.6693 3.36419 20.1984C3.33307 20.1984 3.33307 20.2295 3.33307 20.2295C3.33307 20.2606 3.33307 20.2606 3.36419 20.2606C3.92443 20.2606 5.57403 20.4162 7.317 21.2877C9.49571 22.4082 10.9586 23.9644 11.7678 25.7074C12.5148 27.2947 12.7015 27.9795 12.7949 29.6913V47.8991H36.325V33.3329L37.7567 34.2666C38.0057 34.4222 38.2858 34.4845 38.5659 34.4845C39.4374 34.4845 40.1222 33.7997 40.1222 32.9283C40.1844 32.4925 39.9354 32.0568 39.5619 31.7767ZM33.1192 44.6933H29.6955V35.9473H24.6844V44.6933H16.1252V32.2124C16.1875 32.2124 16.1875 32.2124 16.1875 32.2124C16.1875 32.2124 16.1875 32.1813 16.1875 32.1502L24.7156 24.7114L33.088 30.9363V44.6933H33.1192Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M22.9413 35.9473H19.5487V39.3398H22.9413V35.9473Z"
        fill={!isActive ? 'black' : 'white'}
      />
    </svg>
  );
};
