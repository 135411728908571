import React from 'react';

import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';

function ForgotPassword() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <main className="main-login">
        <div className="wrapper">
          <div className="container">
            <ForgotPasswordForm />
          </div>
        </div>
      </main>
    </>
  );
}

export default ForgotPassword;
