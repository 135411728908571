import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import { GoldenProgramInformation } from '../../components/Programs/Golden/Golden';

function GoldenProgram() {
  return (
    <section className="section-launch-product">
      <AnimationWrapper>
        <GoldenProgramInformation />
      </AnimationWrapper>
    </section>
  );
}

export default GoldenProgram;
