import React from 'react';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import { InviteList } from '../../components/InviteList/InviteList';

function UserInvitation() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <InviteList />
      </AnimationWrapper>
    </section>
  );
}

export default UserInvitation;
