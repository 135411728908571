import React from 'react';

interface Props {
  isActive?: boolean;
}
export const RaitingIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="28"
      height="38"
      viewBox="0 0 28 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5459 6.18681L23.1052 1.2311C23.0025 1.10271 22.8484 1 22.6687 1H4.97703C4.79729 1 4.64323 1.07703 4.54052 1.2311L1.09977 6.18681C0.971381 6.36655 0.971381 6.57196 1.07409 6.75171L7.92992 17.947H7.03121C6.74876 17.947 6.51767 18.1781 6.51767 18.4605V21.0539C6.51767 21.3364 6.74876 21.5675 7.03121 21.5675H9.88139C7.80153 22.7486 6.28657 24.9312 5.92709 27.2935C5.87574 27.576 6.08115 27.8327 6.3636 27.8841C6.64605 27.9354 6.90283 27.73 6.95418 27.4476C7.46773 24.0839 10.4206 21.5675 13.81 21.5675C17.6359 21.5675 20.7429 24.6744 20.7429 28.5003C20.7429 32.3263 17.6359 35.4332 13.81 35.4332C10.4206 35.4332 7.46773 32.9168 6.95418 29.5531C6.90283 29.2707 6.64605 29.0652 6.3636 29.1166C6.08115 29.1679 5.87574 29.4247 5.92709 29.7072C6.20954 31.5816 7.1596 33.2763 8.59753 34.5345C10.0355 35.7927 11.9099 36.486 13.81 36.486C18.2008 36.486 21.7956 32.9168 21.7956 28.5003C21.7956 25.5218 20.178 22.9284 17.7643 21.5675H20.6145C20.8969 21.5675 21.128 21.3364 21.128 21.0539V18.4862C21.128 18.2038 20.8969 17.9727 20.6145 17.9727H19.7158L26.5716 6.77738C26.6743 6.57196 26.6743 6.36655 26.5459 6.18681ZM21.8213 2.05277L19.7671 5.98139H17.071C16.7886 5.98139 16.5575 6.21248 16.5575 6.49493C16.5575 6.77738 16.7886 7.00848 17.071 7.00848H19.2279L13.8357 17.3564L8.44346 7.00848H17.3791C17.6616 7.00848 17.8927 6.77738 17.8927 6.49493C17.8927 6.21248 17.6616 5.98139 17.3791 5.98139H7.87856L5.82438 2.05277H21.8213ZM2.12686 6.49493L4.9 2.51496L12.9627 17.9727H9.13675L2.12686 6.49493ZM20.6402 21.3877H6.97986V18.4605H20.6402V21.3877ZM18.5089 17.9727H14.683L22.72 2.51496L25.4932 6.49493L18.5089 17.9727Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.770321"
        stroke-miterlimit="10"
      />
      <path
        d="M14.8359 32.5317C14.5534 32.6088 14.3994 32.8912 14.4507 33.1737C14.6048 33.4818 14.8102 33.6102 15.0927 33.5588C17.3779 32.9682 18.9956 30.9141 18.9956 28.5518C18.9956 25.7016 16.6846 23.3906 13.8345 23.3906C10.9843 23.3906 8.67334 25.7016 8.67334 28.5518C8.67334 30.9141 10.2653 32.9682 12.5763 33.5588C12.8587 33.6359 13.1412 33.4561 13.2182 33.1737C13.2952 32.8912 13.1155 32.6088 12.8331 32.5317C11.01 32.0695 9.72611 30.4262 9.72611 28.5261C9.72611 26.2665 11.5749 24.4177 13.8345 24.4177C16.0941 24.4177 17.9428 26.2665 17.9428 28.5261C17.9428 30.4262 16.659 32.0695 14.8359 32.5317Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.770321"
        stroke-miterlimit="10"
      />
      <path
        d="M18.7647 21.5938C20.8959 23.1344 22.2825 25.6508 22.2825 28.5009C22.2825 33.1999 18.4823 37.0001 13.7834 37.0001C9.08441 37.0001 5.28418 33.1999 5.28418 28.5009C5.28418 25.6764 6.67075 23.1601 8.80196 21.5938"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M18.7647 21.5938C20.8959 23.1344 22.2825 25.6508 22.2825 28.5009C22.2825 33.1999 18.4823 37.0001 13.7834 37.0001C9.08441 37.0001 5.28418 33.1999 5.28418 28.5009C5.28418 25.6764 6.67075 23.1601 8.80196 21.5938"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.10847"
        stroke-miterlimit="10"
      />
      <path
        d="M15.6062 30.4266H14.9129V25.5479C14.9129 24.9573 14.4507 24.4951 13.8601 24.4951H11.96C11.3694 24.4951 10.9072 24.9573 10.9072 25.5479C10.9072 26.1385 11.3694 26.6007 11.96 26.6007H12.8073V30.4266H12.1141C11.5235 30.4266 11.0613 30.8888 11.0613 31.4793C11.0613 32.0699 11.5235 32.5321 12.1141 32.5321H15.6062C16.1967 32.5321 16.6589 32.0699 16.6589 31.4793C16.6589 30.8888 16.1711 30.4266 15.6062 30.4266Z"
        fill={!isActive ? 'white' : '#D4A387'}
      />
    </svg>
  );
};
