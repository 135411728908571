import React, { useState } from 'react';
import { CheckCard } from '../CheckCard/CheckCard';
import { InvoiceTypes } from './InvoiceDetails.model';
import DefaultTitle from '../Titles/Default';
import BankAccountDetails from '../BankAccountForm/BankAccountDetails';
import AccountReplenishmentCard from '../InvoiceCards/AccountReplenishmentCard/AccountReplenishmentCard';
import MembershipCard from '../InvoiceCards/MembershipCard/MembershipCard';
import BackButton from '../BackButton/BackButton';
import { useTranslation } from 'react-i18next';

export function InvoiceDetails() {
  const { t } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencyAmount, setCurrencyAmount] = useState('0');
  const [paymentOptions, setPaymentOptions] =
    useState<InvoiceTypes>('membership');

  const onCurrencySelect = (currency: string) => {
    setSelectedCurrency(currency);
  };

  const onRangeChange = (value: string) => {
    setCurrencyAmount(value);
  };

  return (
    <div className="section-invoice-details">
      <BackButton className="back-button">{t('Button.backButton')}</BackButton>
      <div className="section-invoice-details__wrapper">
        <div className="section-invoice-details__col">
          <DefaultTitle>{t('Invoice.paymentTypeTitle')}</DefaultTitle>
          <div className="section-invoice-details__col-variety">
            <div className="section-invoice-details__col-variety__option">
              <CheckCard
                value={paymentOptions === 'membership'}
                selected={paymentOptions === 'membership'}
                onClick={() => setPaymentOptions('membership')}
              >
                {t('Invoice.membershipCard')}
              </CheckCard>
              {paymentOptions === 'membership' && (
                <p className="section-invoice-details__col-variety__option-information">
                  {t('Invoice.membershipFeeInfo')}
                </p>
              )}
            </div>
            <div className="section-invoice-details__col-variety__option">
              <CheckCard
                value={paymentOptions === 'account_replenishment'}
                selected={paymentOptions === 'account_replenishment'}
                onClick={() => setPaymentOptions('account_replenishment')}
              >
                {t('Invoice.accountReplenishment')}
              </CheckCard>
              {paymentOptions === 'account_replenishment' && (
                <p className="section-invoice-details__col-variety__option-information">
                  {t('Invoice.accountReplenishmentInfo')}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="section-invoice-details__col">
          <DefaultTitle>Инвойс</DefaultTitle>
          <div className="section-invoice-details__col-create-invoice">
            {paymentOptions === 'account_replenishment' ? (
              <AccountReplenishmentCard
                onCurrencySelect={onCurrencySelect}
                selectedCurrency={selectedCurrency}
                onRangeChange={onRangeChange}
                currencyAmount={currencyAmount}
              />
            ) : (
              <MembershipCard
                currencyFrom="EUR"
                currencyTo={selectedCurrency}
                sumFrom={129.15}
                sumTo={11693.1267}
                onCurrencySelect={onCurrencySelect}
                selectedCurrency={selectedCurrency}
              />
            )}
            <BankAccountDetails showForm={!!selectedCurrency} />
          </div>
        </div>
      </div>
    </div>
  );
}
