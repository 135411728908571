import React from 'react';

interface Props {
  isActive?: boolean;
}
export const PracticumGroupIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_235)">
        <path
          d="M21.3848 0C9.57765 0 0 9.57765 0 21.3848C0 33.1919 9.57765 42.7696 21.3848 42.7696C33.1919 42.7696 42.7696 33.1919 42.7696 21.3848C42.7696 9.57765 33.1919 0 21.3848 0ZM16.5618 38.4244C9.12266 36.3314 3.68546 29.4837 3.68546 21.3848C3.68546 19.4965 3.98121 17.6538 4.54995 15.9476C6.05144 16.9258 9.4639 19.1781 11.9436 21.1573C14.9011 23.546 15.4471 24.3877 15.9703 26.5945C16.6983 29.6884 16.6301 35.9219 16.5618 38.4244ZM39.0841 21.3848C39.0841 29.6884 33.3739 36.6499 25.6845 38.5609C25.6162 36.1494 25.5252 29.7339 26.276 26.5945C26.7992 24.3877 27.3452 23.546 30.3027 21.1573C33.0554 18.9278 36.9911 16.4026 38.1286 15.6518C38.7656 17.4491 39.0841 19.3828 39.0841 21.3848ZM30.3482 17.7903C28.3462 18.9278 27.1405 19.4056 25.548 19.7923C23.9555 20.179 22.818 19.4056 22.545 18.7458C22.2948 18.0861 22.2948 16.9031 22.2948 16.9031C22.2948 16.9031 23.9783 16.4026 23.5005 13.6954C23.2275 12.1939 22.6133 11.6479 21.9308 11.4886C21.5668 11.3976 20.6795 11.3976 20.3838 11.4659C19.7013 11.6024 18.9961 12.1029 18.7003 13.6954C18.2226 16.4026 19.906 16.9031 19.906 16.9031C19.906 16.9031 19.906 18.0861 19.6558 18.7458C19.4056 19.4056 18.2453 20.179 16.6528 19.7923C15.0603 19.4056 13.8546 18.9278 11.8526 17.7903C10.3739 16.9486 6.96143 14.9693 5.2552 13.9911C8.09892 7.89417 14.2641 3.68546 21.3848 3.68546C28.4145 3.68546 34.5114 7.80317 37.3551 13.7409C35.8764 14.6053 31.9634 16.8803 30.3482 17.7903Z"
          fill={!isActive ? '#010202' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_235">
          <rect width="42.7696" height="42.7696" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
