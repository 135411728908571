import React, { useState } from 'react';
import { Toggle } from '../../Toggle/Toggle';
import { EuroIcon, PrivacyStarsIcon, UsdIcon } from '../../../assets/media';

export const PrivacyForm = () => {
  const [optionsValues, setOptionsValues] = useState({
    myBalance: false,
    myContracts: false,
    myTransactions: false,
  });
  const onOptionChange = (type: keyof typeof optionsValues) => () => {
    console.log('INSIDE');
    setOptionsValues((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  console.log({ optionsValues });
  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <div className="section-launch-product-main__form section-launch-settings-main__form">
              <div className="section-launch-product-main__form section-launch-settings-main__form">
                <div className="section-launch-product-main__form-section first-section section-launch-settings-main__form-section">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Мой баланс</span>
                    <Toggle
                      checked={optionsValues.myBalance}
                      onChange={onOptionChange('myBalance')}
                      name={'myBalance'}
                    />
                  </div>
                  <div className="section-launch-settings-main__form-section-description">
                    Скрыть баланс в заголовке кабинета{' '}
                  </div>
                  <div className="section-launch-settings-main__privity-row">
                    <div className="section-launch-settings-main__privity-block">
                      <div className="section-launch-settings-main__privity-block-title">
                        В личном кабинете
                      </div>
                      <div className="section-launch-settings-main__privity-block-content">
                        <div className="section-launch-settings-main__privity-title">
                          Доступный баланс
                        </div>
                        <div className="section-launch-settings-main__privity-currency">
                          <div className="section-launch-settings-main__privity-currency-icon">
                            <UsdIcon theme={'black'} />
                          </div>
                          <div className="section-launch-settings-main__privity-currency-amount">
                            <span
                              data-privity-hide="star"
                              style={{ opacity: 1 }}
                            >
                              {!optionsValues.myBalance ? (
                                '1 000,00'
                              ) : (
                                <PrivacyStarsIcon theme={'black'} />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="section-launch-settings-main__privity-currency">
                          <div className="section-launch-settings-main__privity-currency-icon">
                            <EuroIcon theme={'black'} />
                          </div>
                          <div className="section-launch-settings-main__privity-currency-amount">
                            <span
                              data-privity-hide="star"
                              style={{ opacity: 1 }}
                            >
                              {!optionsValues.myBalance ? (
                                '1 000,00'
                              ) : (
                                <PrivacyStarsIcon theme={'black'} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Мои контракты</span>
                    <Toggle
                      checked={optionsValues.myContracts}
                      onChange={onOptionChange('myContracts')}
                      name={'myContracts'}
                    />
                  </div>
                  <p className="section-launch-settings-main__form-section-description">
                    Скрыть суммы контракта в кабинете
                  </p>
                  <div className="section-launch-settings-main__privity-row">
                    <div className="section-launch-settings-main__privity-block">
                      <p className="section-launch-settings-main__privity-block-title">
                        В личном кабинете
                      </p>
                      <div className="section-launch-settings-main__privity-block-content">
                        <div className="section-launch-settings-main__privity-title">
                          Сумма активив:{' '}
                          <span
                            className={
                              optionsValues.myContracts ? 'el-blured' : ''
                            }
                          >
                            20 000,00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-launch-product-main__form-section section-launch-settings-main__form-section">
                  <div className="section-launch-settings-main__form-section-heading">
                    <span>Мои транзакции</span>
                    <Toggle
                      checked={optionsValues.myTransactions}
                      onChange={onOptionChange('myTransactions')}
                      name={'myTransactions'}
                    />
                  </div>
                  <p className="section-launch-settings-main__form-section-description">
                    Скрыть транзакции в кабинете
                  </p>
                  <div className="section-launch-settings-main__privity-row">
                    <div className="section-launch-settings-main__privity-block">
                      <p className="section-launch-settings-main__privity-block-title">
                        В личном кабинете
                      </p>
                      <div className="section-launch-settings-main__privity-block-content">
                        <div className="section-launch-settings-main__privity-title">
                          Внутренний перевод на сумму:{' '}
                          <span
                            className={
                              optionsValues.myTransactions ? 'el-blured' : ''
                            }
                          >
                            1 000,00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Приватность
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                Клуб 7 flags рекомендует воспользоваться функцией скрытия
                баланса, транзакций и информации о контрактах. Допустим, у вас в
                работе находится крупная сумма по программам First или First
                Professional и к вам поступают просьбы провести презентацию
                продукта, сделать снимок экрана для знакомого, объяснить работу
                внутренних переводов и т.п. Во всех этих случаях Клуб
                настоятельно рекомендует делать финансовые данные невидимыми во
                избежание случайного распространения информации о вашем
                благосостоянии.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
