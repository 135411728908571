import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import { InvoiceDetails } from '../../components/InvoiceDetails/InvoiceDetails';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';

function Invoice() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <section className="section-cabinet">
        <AnimationWrapper className="container">
          <InvoiceDetails />
        </AnimationWrapper>
      </section>
    </>
  );
}

export default Invoice;

