import { createApi } from '@reduxjs/toolkit/query/react';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { CitizenshipsModel } from '../../components/CitizenshipsList/CitizenshipsList.model';

export const citizenshipsApi = createApi({
  reducerPath: 'citizenshipsApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    getCitizenshipsApi: builder.query<CitizenshipsModel, void>({
      query: () => 'citizenships/fetch',
    }),
  }),
});

export const { useGetCitizenshipsApiQuery } = citizenshipsApi;
