import { RefObject, useEffect } from 'react';

type Callback = () => void;

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: Callback,
  isActive: boolean,
) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(<Node>e.target) && isActive) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
