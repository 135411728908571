interface Props {
  isActive?: boolean;
}
export const CurrtexIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="39"
      height="45"
      viewBox="0 0 39 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5335 9.97175C11.1995 17.1611 14.5623 24.9301 15.2967 26.0897C14.6782 24.9301 14.369 23.616 14.369 22.2245C14.369 17.6249 18.1183 13.8756 22.7179 13.8756C24.7665 13.8756 26.6218 14.61 28.0519 15.8082C29.8685 13.3731 33.6951 8.34835 35.4731 5.95192C34.0043 4.79235 32.3809 3.82605 30.6416 3.13031C26.5058 3.053 21.1718 3.74874 16.5335 9.97175Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.79989"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9743 28.7561C26.5441 29.9156 24.7275 30.6114 22.7176 30.6114C19.5094 30.6114 16.6878 28.7947 15.2964 26.0891C14.562 24.9295 11.1992 17.1604 16.5332 9.93245C21.1715 3.7481 26.5055 3.05236 30.6413 3.12966C28.2062 2.1247 25.5392 1.54492 22.7176 1.54492C11.2765 1.54492 2 10.8214 2 22.2625C2 33.7035 11.2765 42.9801 22.7176 42.9801C28.4381 42.9801 33.6561 40.6609 37.4054 36.873C34.3905 34.2833 29.9069 30.3795 27.9743 28.7561Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.79989"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
