import React from 'react';

// interface Props {}
export function Footer() {
  return (
    <footer className="footer">
      If you have any questions
      <a
        href="https://gitlab.com/ari_company_team/cl520/react/-/tree/dev"
        className="footer__email-us"
      >
        Email us
      </a>
    </footer>
  );
}
