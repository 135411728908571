import React, { useEffect, useState } from "react";
import { CheckCard } from '../CheckCard/CheckCard';
import DefaultTitle from '../Titles/Default';
import { Button } from '../Button/Button';
import { useModal } from '../../hooks/useModal';
import ApprovalModal from '../ApprovalModal/ApprovalModal';
import { useGetDocumentsQuery, useSignDocumentMutation } from '../../app/services/document.api';
import BackButton from '../BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import { CheckCardDocs } from '../CheckCard/CheckCardDocs';
import { DocumentSignatureInterface } from '../../interfaces/documentSignature.interface';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Loader from 'react-loader-advanced';
import { useNavigate } from 'react-router-dom';

function DocumentsInformation() {
  const documentUrl = process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : 'https://7api.profitcode.by';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetDocumentsQuery();
  const [signDocument] = useSignDocumentMutation();
  const { isShown: isOpenApprovalModal, toggle: onToggleApprovalModal } =
    useModal();
  const [selectedDocumentId, setSelectedDocumentId] = useState<null | string>(
    null,
  );

  // Temporary till backend is ready
  const [signedDocuments, setSignedDocuments] = useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [documentSigningStart, setDocumentSigningStart] = useState<boolean>(false);
  const fpPromise = FingerprintJS.load()
  ;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    return result
  })()
  //getting fingerprint from fingerprintjs
  const [fingerprint, setFingerprint] = useState({ } as any)
  useEffect(() => {
    ;(async () => {
      const fp = await fpPromise
      const result = await fp.get()
      setFingerprint(result)
    })()
  })
  // pass already signed documents to signed array
  useEffect(() => {
    if (data) {
      const signedDocuments = data.filter(
        (document) => document.isSigned,
      );
      setSignedDocuments(signedDocuments.map((document) => document.id));
      setIsLoading(false);
    }
  }, [data]);
  const onDocumentSigned = () => {
    if (selectedDocumentId) {
      setDocumentSigningStart(true);
      const payload: DocumentSignatureInterface = {
        documentId: selectedDocumentId,
        fingerprint: JSON.stringify(fingerprint),
        hostname: window.location.href,
      }
      signDocument(payload).then((response) => {
        if (response) {
          setSignedDocuments([...signedDocuments, selectedDocumentId]);
          setSelectedDocumentId(null);
          setDocumentSigningStart(false);
        }
      }).catch((error) => {
        setDocumentSigningStart(false);
        console.log(error)
      })
      // setSignedDocuments((prevState) => [...prevState, selectedDocumentId]);
      onToggleApprovalModal();
    }
  };

  const onDocumentRejected = () => {
    onToggleApprovalModal();
    setSelectedDocumentId(null);
  };
  const openInNewTab = (url: string | URL | undefined) => {
    window.open(documentUrl + url, '_blank', 'noopener,noreferrer');
  };
  const onChooseDocument = (id: string) => {
    console.log('id', id);
    setSelectedDocumentId(id);
    onToggleApprovalModal();
  }
  const onNextStep = () => {
    navigate('/candidates/chose-program')
  }

  return (
    <div className="section-documents-information">
      <BackButton className="back-button">{t('Button.backButton')}</BackButton>
      <DefaultTitle>Подпишите документы</DefaultTitle>
      <ApprovalModal
        content={`${t('SignDocuments.modalInformation')}?`}
        onReject={onDocumentRejected}
        onSuccess={onDocumentSigned}
        isOpen={isOpenApprovalModal}
      />
      <Loader show={isLoading} message={'Loading...'} style={{minHeight: '200px'}}>
        <div className="sign_documents-wrapper mt-24">
        {/* <div className="section-documents-information__documents"></div> */}
        {data &&
          data.length &&
          data.map(({ id, name, content, documentPath }) => {
            const isSigned = signedDocuments.includes(id);
            return (
              <div className="sign_documents-item" key={id}>
                {/* <div
                className="section-documents-information__documents-document"
                key={id}
              > */}
                {/* <div className="section-documents-information__documents-document__wrapper"> */}
                <Loader show={documentSigningStart && id === selectedDocumentId} message={'Signing...'} style={{minHeight: '200px'}}>
                  <CheckCardDocs
                    value={isSigned}
                    additionalHeaderTitle={t('SignDocuments.open')}
                    onClick={() => {
                      openInNewTab(documentPath)
                    }}
                  >
                    {name}
                  </CheckCardDocs>

                {/* <CheckCard
                    value={isSigned}
                    type="medium"
                    additionalHeaderTitle={t('SignDocuments.open')}
                  >
                    {name}
                  </CheckCard> */}

                  <p className="section-documents-information__documents-document__description">
                    {t('SignDocuments.documentDetails')}
                  </p>
                  <Button
                    onClick={() => onChooseDocument(id)}
                    transformed={isSigned}
                    disabled={isSigned}
                    classNames={isSigned ? 'btn--transparent-bronze' : ''}
                  >
                    {!isSigned
                      ? t('SignDocuments.sign')
                      : t('SignDocuments.signed')}
                  </Button>
                </Loader>
                {/* </div> */}
              </div>
            );
          })}
      </div>
      </Loader>
      <div className="sign_documents-wrapper mt-24">
        { signedDocuments.length > 3 && (<Button onClick={onNextStep} transformed>
          Next
        </Button>)}
      </div>
    </div>
  );
}

export default DocumentsInformation;

