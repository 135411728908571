import React from 'react';
import { usePersonFirstLineQuery } from "../../../app/services/personFirstLine.api";
// import { FirstLineInterface } from "../../../interfaces/firstLine.interface";

export const FirstLineForm = () => {
  // const [isLoading, setIsLoading] = React.useState(true);
  // const [data, setData] = React.useState([]);
  const { data } = usePersonFirstLineQuery();
  console.log(data);
  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <div className="section-launch-product-main__form section-launch-settings-main__form">
              <div className="other-seans section-launch-product-main__form-section section-launch-settings-main__form-section section-launch-settings-main__form-section--main">
                <p className="section-launch-product-main__form-section-heading">
                  Моя первая линия
                </p>
              </div>
            </div>
            {data && data.map((item) => {
                return (
                  <div style={{marginBottom: '15px'}}>
                  <div className="savings-account-preview-header">
                    <div className="savings-account-preview-header__info">
                      <div className="savings-account-preview-header__balance">
                        <span>{item?.firstName} </span><span>{item?.middleName}</span><span>{item?.lastName}</span></div>
                      <div className="savings-account-preview-header__goal"><span
                        className="subheader">Сумма активов: </span><span>31&nbsp;406&nbsp;3444 USD</span></div>
                    </div>
                    <div className="savings-account-preview-header__primary">
                      <div className="savings-account-preview-header__progress">
                        <div className="circular-chart">
                        </div>
                      </div>
                      <div className="savings-account-preview-header__primary--actions">
                        <ul className="savings-account-preview-header__primary--download-menu ">
                          <li className="savings-account-preview-header__primary--download-menu-item">Выписка общая</li>
                          <li className="savings-account-preview-header__primary--download-menu-item">Выписка за 3
                            месяца
                          </li>
                          <li className="savings-account-preview-header__primary--download-menu-item">Выписка за 6
                            месяца
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>
                )
              }
            )}

          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Моя первая линия
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                В данном разделе вы можете посмотреть свою первую линию.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
