import React from 'react';

interface Props {
  isActive?: boolean;
}
export const AttorneyIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.04484 37.0006H21.1734C21.7628 37.0006 22.2183 36.5184 22.2183 35.9558V33.7053C22.2183 32.339 21.3342 31.1602 20.1018 30.7316V29.3652C20.1018 27.7042 18.7355 26.3379 17.0745 26.3379H6.14383C4.4828 26.3379 3.11647 27.7042 3.11647 29.3652V30.7316C1.88409 31.1602 1 32.339 1 33.7053V35.9558C1 36.5184 1.48223 37.0006 2.04484 37.0006ZM6.14383 28.4008H17.1012C17.6103 28.4008 18.0121 28.8026 18.0121 29.3117V30.4905H5.20615V29.3117C5.20615 28.8294 5.6348 28.4008 6.14383 28.4008Z"
        fill={!isActive ? 'black' : 'white'}
        stroke="white"
        stroke-width="0.282791"
        stroke-miterlimit="10"
      />
      <path
        d="M13.1352 22.9615C14.18 24.3546 16.1625 24.6493 17.5557 23.5777C18.5201 22.8811 18.9488 21.7559 18.8148 20.6843C19.7257 20.5235 20.6098 20.2824 21.4403 19.9341L31.3261 33.0616C32.3709 34.4547 34.3535 34.7494 35.7466 33.6778C37.1397 32.633 37.4076 30.6504 36.3628 29.2573L26.477 16.1299C27.0396 15.4333 27.5218 14.6564 27.9237 13.8259C28.9417 14.2545 30.1473 14.1206 31.0582 13.4508C32.4513 12.4059 32.7192 10.4234 31.6744 9.03031L26.6109 2.25225C25.5661 0.859127 23.5836 0.59122 22.1905 1.63606C21.2528 2.33262 20.8241 3.45783 20.9581 4.52946C16.993 5.19923 13.5906 7.74435 11.8492 11.3879C10.8312 10.9592 9.65238 11.0664 8.7147 11.7898C7.29479 12.8346 7.02689 14.8171 8.07173 16.2102L13.1352 22.9615ZM34.4606 31.99C34.0052 32.3383 33.3354 32.2579 32.9871 31.7757L26.0215 22.5061L27.7093 21.2469L34.6749 30.5165C35.0232 30.9719 34.9429 31.6417 34.4606 31.99ZM23.4496 3.32388C23.9051 2.9756 24.5748 3.05597 24.9231 3.5382L29.9866 10.2895C30.3348 10.7449 30.2545 11.4147 29.7722 11.763C29.3168 12.1112 28.647 12.0309 28.2987 11.5486L23.2353 4.79737C22.887 4.31513 22.9942 3.67216 23.4496 3.32388ZM21.8958 6.51198C22.3512 7.12816 25.8072 11.7094 26.2626 12.3256C24.8427 15.8352 21.6011 18.2731 17.8504 18.675L13.4835 12.8614C14.9302 9.3518 18.1451 6.94063 21.8958 6.51198ZM9.97387 13.4508C10.4293 13.1025 11.0991 13.1829 11.4474 13.6651L16.5108 20.4164C16.8591 20.8718 16.7787 21.5416 16.2965 21.8899C15.841 22.2382 15.1713 22.1578 14.823 21.6755L9.75954 14.9511C9.41126 14.4688 9.49164 13.8259 9.97387 13.4508Z"
        fill={!isActive ? 'black' : 'white'}
        stroke="white"
        stroke-width="0.282791"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
