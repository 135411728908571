import React, { useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import ArrowIcon from '../../assets/media/arrow.svg';
import { useClickOutside } from '../../hooks';

interface Props {
  placeholder?: string;
}
export const RangeDatepicker = ({ placeholder }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const componentRef = useRef<HTMLDivElement>(null);

  const onSelect = (ranges: any) => {
    setValue(ranges.selection);
  };

  const onToggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  useClickOutside(componentRef, onToggleOpen, isOpen);

  return (
    <div className="range-datepicker">
      <div className="range-datepicker__header" onClick={onToggleOpen}>
        <p className="range-datepicker__header-value">{placeholder}</p>
        <img
          className="range-datepicker__header-arrow"
          src={ArrowIcon}
          alt="arrow"
        />
      </div>
      {isOpen && (
        <div className="range-datepicker__datepicker" ref={componentRef}>
          <DateRangePicker ranges={[value]} onChange={onSelect} />
        </div>
      )}
    </div>
  );
};
