export const OpenEye = () => {
  return (
    <svg
      id="privity-show-default"
      viewBox="125 50 323 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M280.7,65.3c57.1,0.4,117,22.8,145.8,71.7c1.4,2.3,3.1,5.8,3.1,5.8c1.7,4.6-2.9,9.8-6.4,15
					c-30.3,45.5-88.2,66.8-144.2,66.8c-56.9,0-117.3-22.1-145.4-71.7c-1.4-2.5-3.2-6.4-3.2-6.4c-1.2-3.5,1.7-7.4,3.9-11.1
					C162.1,89.1,220,65.8,278,65.3C278.9,65.3,279.8,65.3,280.7,65.3z M278,74.7c-50.7,0.4-102.5,18.3-131.4,59.1
					c-2.5,3.5-4.8,7.2-6.7,11c0,0,7.2,13.2,15.9,22.8c31.4,34.8,81.2,48.7,128.4,47.7c53.5-1.1,109.8-22.1,135.6-69.8
					c0,0,0.1-1.1-0.4-2C394,98.1,336.5,75.1,280.6,74.7C279.8,74.7,278.9,74.7,278,74.7L278,74.7z M280.5,109.8
					c23.2,0.4,42.1,27.8,31.2,50.4c-9.5,19.7-39.5,25.9-55.9,10.2c-19.3-18.4-8.3-60,23.8-60.6C280,109.8,280,109.8,280.5,109.8z
					 M279.7,119.2c-17,0.3-30.9,20.4-22.9,36.9c7,14.5,29,19,41,7.5c8.1-7.7,10.3-21,4.9-30.9C298.2,124.4,289.8,119.2,279.7,119.2z
					"
        fill="#257eaa"
      ></path>
      <g>
        <path
          fill="#257eaa"
          d="M280.7,68.3c44.6,0.5,91.8,13.8,124.2,45.8c8,7.9,15.1,17,20.5,26.9c1.7,3.2,1.9,4.9,0.1,8.2c-1.4,2.5-3.2,4.7-4.8,7
						c-3.5,5-7.1,9.9-11.3,14.4c-15.4,16.9-35.1,29.3-56.3,37.6c-42.3,16.4-91,17.8-134.4,4.7c-21.1-6.4-41.4-16.6-57.9-31.3
						c-8.3-7.4-15.6-15.8-21.5-25.2c-2.1-3.3-6.9-9.1-5.8-13.1c1.6-5.4,6.2-10.9,9.5-15.4C174,85.4,230,68.2,280.7,68.3
						c3.9,0,3.9-6,0-6c-48.6-0.1-100.8,15.1-134.3,52c-3.9,4.3-7.4,8.8-10.7,13.5c-3.1,4.5-7.7,10.1-8.4,15.6
						c-0.6,4.3,2.5,8.7,4.5,12.2c2.9,5,6.2,9.7,9.7,14.2c14.7,18.7,34.8,32.5,56.5,41.8c44.7,19.1,97.5,21,143.9,6.7
						c23.1-7.1,45-18.5,62.9-34.8c9.3-8.5,16.9-18.2,23.9-28.6c2.8-4.2,5.5-8.9,3.4-13.9c-2.1-5-5.4-9.8-8.6-14.2
						c-28.3-39.9-77-59.4-124.4-63.7c-6.2-0.6-12.3-0.8-18.5-0.9C276.8,62.3,276.8,68.3,280.7,68.3z"
        ></path>
        <path
          fill="#257eaa"
          d="M278,71.7C233,72.2,185.2,85.9,154,120c-3.6,3.9-6.9,8-9.9,12.3c-2.4,3.4-8.3,9.6-6.7,14.1c1,2.7,3.1,5.4,4.7,7.7
						c3.2,5,6.7,9.9,10.6,14.4c14.9,17.3,35.1,29.7,56.3,37.7c42.3,16,92,16.4,134.9,2.3c20.4-6.7,39.7-17,55.4-31.7
						c7.8-7.3,14.7-15.6,20.3-24.8c2.5-4.1,4.3-6.7,1.8-11.3c-2.6-4.7-5.8-9.2-9.2-13.4C380.8,87.8,326.8,71.6,278,71.7
						c-1,0-2.1,0.6-2.6,1.5c0,0,0,0,0,0c-1.7,3.4,3.5,6.5,5.2,3c0,0,0,0,0,0c-0.9,0.5-1.7,1-2.6,1.5c32.4,0,65.4,7,93.6,23.2
						c13.2,7.6,25.2,17.2,34.9,29c2.4,2.9,4.6,5.9,6.6,9c0.9,1.4,2.5,3.3,3,4.8c0.6,2.2-1,4-2.2,5.8c-4,6.5-8.7,12.5-13.9,18
						c-21.6,22.6-51.5,35.5-81.8,41.2c-31.8,6-65.7,4.6-96.8-4.7c-33.2-10-62-30.1-78.9-60.8c0,1,0,2,0,3
						c25.6-48.5,83.7-68,135.5-68.6C281.9,77.6,281.9,71.6,278,71.7z"
        ></path>
        <path
          fill="#257eaa"
          d="M280.5,112.8c14.8,0.6,27.2,12.3,30.6,26.4c3.6,15-4.9,29.3-19.1,34.7c-12.4,4.7-27.9,2.5-36.5-8.3
						c-6.9-8.7-7.7-21.1-4.2-31.4C255.6,121.5,266.8,112.8,280.5,112.8c3.9,0,3.9-6,0-6c-29.6-0.1-47.7,36.3-31.2,60.5
						c20,29.4,71.5,11.9,68.4-23.9c-1.6-19.3-17.7-35.9-37.3-36.7C276.6,106.7,276.6,112.7,280.5,112.8z"
        ></path>
        <path
          fill="#257eaa"
          d="M279.7,116.2c-24.1,1.2-38.6,33.4-18.8,49.9c8.4,7,20.8,9.1,30.9,4.9c11.3-4.7,17.8-16.9,16.7-29
						C307.1,127,294.4,116.5,279.7,116.2c-3.9-0.1-3.9,5.9,0,6c13.7,0.3,24.4,11.5,22.8,25.4c-1.5,12.5-12.4,20.8-24.7,19.4
						c-11.4-1.3-21-10.4-20.3-22.3c0.7-11.6,10.5-21.9,22.2-22.5C283.5,122,283.5,116,279.7,116.2z"
        ></path>
      </g>
    </svg>
  );
};

export const ClosedEye = () => {
  return (
    <svg viewBox="125 50 323 200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill="#257eaa"
          d="M201.8,205.2c2.4-3.5-2-7-2-7c-25.9-11.5-49.7-29.6-63.3-54.4c2.1-4.1,4.5-7.9,7.2-11.6c39.2-54.5,115.1-68.4,180.8-53.1
			c5.8,1.4,8.1-1,7.9-4.4c-0.2-2.4-2.5-4-4.3-4.4c-16.8-4.1-34-5.8-51.3-5.9h0c-60.3-0.1-123.8,25.5-148.8,75.5
			c-1.6,3.3-0.9,6.1-0.9,6.1c13.4,27.5,39,47,66.6,59.7C196.8,207.1,200.2,207.6,201.8,205.2z"
        ></path>
        <g>
          <path
            fill="#257eaa"
            d="M204.4,206.7c2.6-5.2-0.3-9.8-5.2-12c-8.1-3.7-16-8.1-23.4-13.2c-15-10.2-27.8-23.3-36.7-39.2c0,1,0,2,0,3
				c18.4-34.9,55.1-56.4,92.7-64.6c18.9-4.2,38.5-5.2,57.8-3.9c9.8,0.7,19.6,2.1,29.2,4.1c5.7,1.2,15.7,3.8,16.5-4.9
				c1-9.9-14-10.4-20.5-11.4c-12.2-2-24.6-3.1-37-3.1c-19.4-0.1-39,2.4-57.8,7.4c-35.5,9.6-70.2,29.3-90,61.2
				c-2.4,3.8-5.6,8.3-6,12.9c-0.5,4.3,2,7.8,4.2,11.4c5.8,9.9,13.4,18.9,21.9,26.6c8.2,7.5,17.3,14,27,19.5c4.9,2.8,10,5.6,15.2,7.8
				c4.1,1.8,8.4,2.5,11.8-1c2.7-2.8-1.6-7.1-4.2-4.2c-2.7,2.9-13-4.1-15.6-5.5c-5-2.7-9.9-5.7-14.6-8.9
				c-9.1-6.2-17.6-13.5-24.9-21.8c-3.5-4-6.8-8.4-9.6-12.9c-1.4-2.2-2.7-4.4-3.9-6.7c-1.7-3.3-1-5,0.7-8.3
				c4.5-8.7,10.5-16.6,17.2-23.6c28.5-29.9,71-44.3,111.4-47.4c10.9-0.8,21.8-0.8,32.6,0c6.7,0.5,13.5,1.2,20.1,2.3
				c3.2,0.5,6.3,1.1,9.4,1.8c1.4,0.3,8.9,1.3,6.1,4.2c-1.5,1.5-13.2-2.2-15.4-2.6c-4.8-0.8-9.6-1.5-14.4-2.1
				c-10.1-1.1-20.2-1.6-30.3-1.3c-38.5,1-77.8,12.4-107.3,38c-8,7-15.2,15-21.2,23.7c-2.8,4.1-8.1,9.6-5.4,14.6
				c2.9,5.3,6.4,10.2,10.2,14.8c7.4,9.2,16.3,17.1,25.9,23.9c5.2,3.6,10.6,7,16.2,9.9c2.2,1.2,13.9,5,12.2,8.4
				C197.5,207.1,202.7,210.2,204.4,206.7z"
          ></path>
        </g>
      </g>
      <g>
        <path
          fill="#257eaa"
          d="M434.2,143.9C421.5,115.8,393.7,96.1,365,84c-1.1-0.5-1.2-0.9-3.7-1.3c-3.1,0-5.4,2.4-5.3,4.9c0.1,1.9,1.5,3.6,3.5,4.3
			c26.5,10.8,52.1,28.8,65.2,53.9l0,0c-2,3.9-4.4,7.5-6.9,11.1c-38.3,53.2-114.7,69.3-180.7,55.2c-5.8-1.3-8.4,1.6-7.6,5.1
			c0.7,3,4.9,3.9,4.9,3.9c15.3,3.3,31,4.5,46.6,4.6c60,0.5,125.8-24.6,152.2-75.9C434.9,146.6,434.2,143.9,434.2,143.9z"
        ></path>
        <g>
          <path
            fill="#257eaa"
            d="M436.8,142.3c-10-21.5-28-37.9-48.1-49.8c-4.9-2.9-10-5.6-15.1-8c-4-1.9-9.9-5.8-14.5-4.6c-3.7,1-6.4,4.6-5.9,8.4
				c0.6,5.4,6.5,6.7,10.7,8.6c11.3,5.1,22.2,11.4,31.9,19.1c4.9,3.9,9.5,8.2,13.7,12.9c1.9,2.1,3.7,4.3,5.4,6.6c1.9,2.5,5,6,5.9,9
				c0.6,2.3,1.3,0.4-0.3,3c-0.7,1-1.3,2.1-2,3.2c-1.9,2.9-3.9,5.8-6.1,8.5c-3.4,4.4-7.2,8.5-11.2,12.3c-8.1,7.8-17.3,14.5-27.1,20.1
				c-39.3,22.3-88,26.9-131.9,18.4c-3.9-0.8-8.7-2.4-12.4,0c-3.2,2-4.2,6.1-2.5,9.4c1.6,3.2,5.1,4.3,8.3,5c7,1.5,14.1,2.5,21.2,3.2
				c24.3,2.4,48.9,1.4,72.7-3.8c24-5.2,47.5-14.5,67.5-28.7c9.5-6.8,18.3-14.7,25.7-23.7c3.6-4.4,6.9-9.1,9.9-14
				c2.6-4.3,5.6-9,4.6-14.3c-0.7-3.8-6.5-2.2-5.8,1.6c0.7,3.8-3.9,9.6-5.8,12.6c-2.8,4.4-5.9,8.5-9.3,12.4
				c-6.4,7.4-13.8,14-21.8,19.8c-16.6,12-35.6,20.3-55.3,25.7c-20.1,5.5-40.9,7.9-61.8,7.5c-13.5-0.3-27-1.3-40.2-4.1
				c-1.1-0.2-4.5-0.6-4.7-2.3c-0.3-2.7,5.3-1,6.8-0.7c5,1,10.1,1.8,15.1,2.5c39.6,5.2,81.5,0.2,117.3-18.2
				c17.3-8.9,32.9-21.1,45-36.4c3.2-4.1,6.3-8.4,9-12.9c0.8-1.3,2.3-3.1,2.2-4.8c-0.1-2.3-2.3-5-3.5-6.9c-5.6-9-12.7-17.1-20.8-24.1
				c-9.4-8.1-19.9-14.9-31-20.4c-2.6-1.3-5.2-2.5-7.9-3.6c-1-0.4-1.9-0.8-2.9-1.2c-2.7-0.5-2.7-1.7,0.2-3.7c0.3,0.1,0.5,0.2,0.8,0.4
				c2.4,0.3,5.5,2.4,7.7,3.5c2.8,1.3,5.6,2.7,8.3,4.2c22.1,11.7,42.1,28.4,52.8,51.5C433.2,148.9,438.4,145.8,436.8,142.3z"
          ></path>
        </g>
      </g>
      <g>
        <path
          fill="#257eaa"
          d="M393.9,28c-2.7-0.3-3.1,0.5-4.1,1.3l-225,225c-2.3,2.6-1.1,6.8,1.9,7.8c1.6,0.5,2.6,0.7,4.7-1.2l87.9-87.9
			c2.1,1.7,4.5,3.2,7.1,4.3c17.1,7.6,39.3-1.4,46.6-18.5c5-11.8,2.1-24.7-5.2-34.3l88.6-88.6C398.9,33,397.3,28.8,393.9,28z
			 M280.6,170.9c-5.2,0-10.2-1.8-14.3-4.9l35.5-35.5c0.4,0.6,0.9,1.3,1.2,2C312.6,149.4,298.1,170.9,280.6,170.9z"
        ></path>
        <g>
          <path
            fill="#257eaa"
            d="M393.9,25c-6.3-0.2-11.9,7.9-16,11.9c-10.1,10.1-20.2,20.2-30.3,30.3c-30.1,30.1-60.3,60.3-90.4,90.4
				c-26.3,26.3-52.6,52.6-78.9,78.9c-5.1,5.1-10.7,10-15.4,15.4c-6.5,7.4,3,17.8,10.6,11.1c2.7-2.4,5.1-5.1,7.7-7.7
				c26.1-26.1,52.2-52.2,78.2-78.2c0.7-0.7,1.4-1.4,2-2c-1.2,0.2-2.4,0.3-3.6,0.5c14.2,10.8,34.1,9.1,47.6-2
				c15.6-12.8,16.4-34.7,4.9-50.6c-0.2,1.2-0.3,2.4-0.5,3.6c27.8-27.8,55.5-55.5,83.3-83.3c1.7-1.7,3.5-3.3,5-5
				c4.2-4.8,2.2-11.1-3.6-13.2c-3.6-1.3-5.2,4.5-1.6,5.8c4.6,1.7-6.1,10.2-7.3,11.4c-7.1,7.1-14.1,14.1-21.2,21.2
				c-15.9,15.9-31.7,31.7-47.6,47.6c-3.8,3.8-7.5,7.5-11.3,11.3c-0.9,0.9-1.2,2.6-0.5,3.6c10,13.8,9.8,32.4-4.2,43.4
				c-11.8,9.3-28.1,10.1-40.2,0.9c-1-0.8-2.8-0.4-3.6,0.5c-22.9,22.9-45.8,45.8-68.7,68.7c-5.1,5.1-10.2,10.2-15.3,15.3
				c-1,1-2.3,2.9-3.6,3.6c-1.7,0.9-3.7,0.1-2.6-2.1c0.7-1.5,3.1-3.1,4.3-4.3c3.2-3.2,6.5-6.5,9.7-9.7c24.4-24.4,48.8-48.8,73.1-73.1
				c29.5-29.5,59-59,88.5-88.5c10.9-10.9,21.9-21.9,32.8-32.8c3.4-3.4,6.9-6.9,10.3-10.3c1.8-1.8,3.5-4,5.5-5.5c1-0.8,0.5-1.1,2.6-1
				C397.7,31.1,397.7,25.1,393.9,25z"
          ></path>
          <path
            fill="#257eaa"
            d="M280.6,167.9c-4.8-0.2-8.9-1.7-12.8-4.4c0.2,1.6,0.4,3.1,0.6,4.7c11.8-11.8,23.7-23.7,35.5-35.5
				c-1.6-0.2-3.1-0.4-4.7-0.6C308.7,146.5,298.2,167.4,280.6,167.9c-3.9,0.1-3.9,6.1,0,6c21.9-0.6,35.9-26.5,23.8-44.9
				c-1-1.6-3.3-2-4.7-0.6c-11.8,11.8-23.7,23.7-35.5,35.5c-1.4,1.4-1,3.6,0.6,4.7c4.8,3.3,10,5.1,15.8,5.3
				C284.4,174,284.4,168,280.6,167.9z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const DefaultEyeIcon = () => {
  return (
    <svg id="eye" viewBox="0 0 14 8">
      <path d="M7.00009 0C4.18546 0 1.67004 1.49451 0 3.84101C1.67004 6.1877 4.18546 7.68202 7.00009 7.68202C9.81453 7.68202 12.3301 6.1877 14.0002 3.84101C12.3301 1.49451 9.81453 0 7.00009 0ZM7.00009 6.00067C5.80742 6.00067 4.84043 5.03387 4.84043 3.84101C4.84043 2.64815 5.80742 1.68135 7.00009 1.68135C8.19295 1.68135 9.15975 2.64815 9.15975 3.84101C9.15975 5.03387 8.19295 6.00067 7.00009 6.00067Z"></path>
    </svg>
  );
};
