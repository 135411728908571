import React from 'react';
import { ReactComponent as SpinnerIcon } from './spinnerImg.svg';

function Spinner() {
  return (
    <div style={{ width: '200px', height: '40px', margin: '0 auto' }}>
      <SpinnerIcon />
    </div>
  );
}

export default Spinner;
