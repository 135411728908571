import React from 'react';

interface Props {
  theme: 'black' | 'white';
}
export const EuroIcon = ({ theme = 'white' }: Props) => {
  return (
    <svg
      id="currency_eur"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433283 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM12.108 8.884L11.768 9.672H9.316L9.304 10.132H11.636L11.296 10.92H9.4C9.63334 12.0747 10.1733 12.6513 11.02 12.65C11.568 12.65 12.13 12.42 12.706 11.96L12.694 13.614C12.1781 13.9061 11.5927 14.0533 11 14.04C10.5884 14.057 10.1779 13.9869 9.79533 13.8342C9.41276 13.6815 9.06676 13.4497 8.78 13.154C8.26934 12.6207 7.936 11.876 7.78 10.92H6.938L7.278 10.132H7.716V9.846C7.71241 9.78706 7.71644 9.7279 7.728 9.67H6.928L7.268 8.882H7.826C7.95698 8.09046 8.33666 7.36121 8.91 6.8C9.1973 6.52277 9.53704 6.30567 9.90932 6.16143C10.2816 6.01719 10.6789 5.94871 11.078 5.96C11.7787 5.98163 12.4642 6.16946 13.078 6.508L12.4 7.876C11.9793 7.57709 11.4815 7.4056 10.966 7.382C10.1993 7.382 9.68867 7.882 9.434 8.882H12.108V8.884Z"
        fill={theme === 'black' ? 'black' : 'white'}
      />
    </svg>
  );
};
