import React from 'react';

interface Props {
  isActive?: boolean;
}
export const MobileIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="21"
      height="36"
      viewBox="0 0 21 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6888 0H4.31384C2.25591 0 0.554688 1.67377 0.554688 3.75913V6.75V29.25V32.2409C0.554688 34.2988 2.22847 36 4.31384 36H16.7163C18.7742 36 20.4754 34.3262 20.4754 32.2409V29.25V6.75V3.75913C20.448 1.70121 18.7742 0 16.6888 0ZM15.7559 3.01829C16.1675 3.01829 16.4968 3.34755 16.4968 3.75913C16.4968 4.17072 16.1675 4.49999 15.7559 4.49999C15.3443 4.49999 15.0151 4.17072 15.0151 3.75913C15.0151 3.34755 15.3443 3.01829 15.7559 3.01829ZM9.74676 3.01829H11.2559C11.6675 3.01829 11.9968 3.34755 11.9968 3.75913C11.9968 4.17072 11.6675 4.49999 11.2559 4.49999H9.74676C9.33518 4.49999 9.00591 4.17072 9.00591 3.75913C9.00591 3.34755 9.33518 3.01829 9.74676 3.01829ZM17.9785 29.0579H3.05164C2.66749 29.0579 2.33822 28.7286 2.33822 28.3445V7.68293C2.33822 7.29878 2.66749 6.9695 3.05164 6.9695H17.9785C18.3626 6.9695 18.6919 7.29878 18.6919 7.68293V28.3445C18.6644 28.7286 18.3626 29.0579 17.9785 29.0579Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M10.5154 34.2993C11.4852 34.2993 12.2715 33.5131 12.2715 32.5432C12.2715 31.5733 11.4852 30.7871 10.5154 30.7871C9.54551 30.7871 8.75928 31.5733 8.75928 32.5432C8.75928 33.5131 9.54551 34.2993 10.5154 34.2993Z"
        fill={!isActive ? 'white' : '#D4A387'}
      />
    </svg>
  );
};
