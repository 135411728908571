import {
  BinomMobileIcon,
  CharityFundIcon,
  CurrtexIcon,
  DeksisIcon,
  EurosimTravelIcon,
  IQeonIcon,
  LifeJournalIcon,
  NadiaFryEventsIcon,
  NeedTourIcon,
  PracticumGroupIcon,
  StrongholdSolutionsIcon,
  TrainingCentreIcon,
  UnionPayIcon,
  ValidatePlatformIcon,
  ZPlexIcon,
} from '../../assets/media';
import {
  AttorneyIcon,
  CalculatorIcon,
  CalendarIcon,
  CardIcon,
  EurosimIcon,
  InsuranceIcon,
  MobileIcon,
  NoteIcon,
  RaitingIcon,
  WebmailIcon,
  WorkshopIcon,
} from '../../assets/media/services';

export const SERVICES = [
  {
    icon: WebmailIcon,
    title: 'manage your mailing',
    name: 'Cabinet.invitationList',
    to: '/candidates/invitation',
  },
  { icon: LifeJournalIcon, title: 'get information', name: 'Life Journal' },
  { icon: CalculatorIcon, title: 'get information', name: 'Calculator' },
  { icon: WorkshopIcon, title: 'gold', name: 'Workshops' },
  { icon: InsuranceIcon, title: 'be in touch', name: 'Insurance' },
  { icon: CardIcon, title: 'text', name: 'Cards' },
  { icon: EurosimIcon, title: 'text', name: 'Eurosim' },
  { icon: RaitingIcon, title: 'text', name: 'Raitings' },
  { icon: AttorneyIcon, title: 'text', name: 'Attorney' },
  { icon: CalendarIcon, title: 'text', name: 'Calendar' },
  { icon: NoteIcon, title: 'text', name: 'Notes' },
  { icon: MobileIcon, title: 'text', name: 'Mobile' },
];
export const PARTNERS = [
  { icon: IQeonIcon, name: 'IQeon' },
  { icon: CurrtexIcon, name: 'Currtex' },
  { icon: BinomMobileIcon, name: 'Binom Mobile' },
  { icon: TrainingCentreIcon, name: 'Training Centre' },
  { icon: CharityFundIcon, name: 'Charity Fund' },
  { icon: DeksisIcon, name: 'Deksisn' },
  { icon: ZPlexIcon, name: 'zPlex' },
  { icon: LifeJournalIcon, name: 'Life Journal' },
  { icon: NeedTourIcon, name: 'Need Tour' },
  { icon: PracticumGroupIcon, name: 'Practicum Group' },
  { icon: StrongholdSolutionsIcon, name: 'Stronghold Solutions' },
  { icon: ValidatePlatformIcon, name: 'Validate Platform' },
  { icon: EurosimTravelIcon, name: 'Eurosim Travel' },
  { icon: UnionPayIcon, name: 'UnionPay Program' },
  { icon: NadiaFryEventsIcon, name: 'Nadia Fry Events' },
];
