import { NavLink } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import DefaultTitle from '../Titles/Default';
import ProgramCard from '../ProgramCard/ProgramCard';
import ShieldWhite from '../../assets/media/specials/shield-white.svg';
import ThunderWhite from '../../assets/media/specials/thunder-white.svg';
import TariffCard from '../TariffCard/TariffCard';
import { useGetTariffsQuery } from '../../app/services/program.api';
import { ProgramCardTypes } from '../ProgramCard/ProgramCard.model';
import BackButton from '../BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import { Button } from "../Button/Button";
import CurrencyInput from 'react-currency-input-field';
import CurrenciesList from "../InvoiceCards/CurrenciesList/CurrenciesList";
import axiosApi from "../../helpers/axios.utils";
import { useNavigate } from 'react-router-dom';

function ChoseProgramDetailInformation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeProgramType, setActiveProgramType] = useState<ProgramCardTypes>(
    '' as ProgramCardTypes,
  );
  const { data: tariffs } = useGetTariffsQuery();
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencyAmount, setCurrencyAmount] = useState('10000');
  const [programCode, setProgramCode] = useState('');
  const onChangeProgram = (type: ProgramCardTypes) => {
    setActiveProgramType(type);
    handleProgramCode();
    console.log(activeProgramType)
  };

  const data = useMemo(
    () => (tariffs ? tariffs[activeProgramType] : []),
    [tariffs, activeProgramType],
  );
  const handleCurrencyChange = (value: string) => {
    console.log(value)
    setSelectedCurrency(value);
  }
  const handleAmountChange = (value: string) => {
    console.log(value)
    setCurrencyAmount(value);
  }
  // send selected program to backend via axios
  // requiered params: porgramCode, contractAmount, currencyId
  const handleProgramCreate = async () => {
    try {
      const payload = {
        programCode: Number(programCode),
        contractAmount: Number(currencyAmount),
        currencyId: selectedCurrency
      }
      console.log(payload)
      await axiosApi.post('programs/create', payload)
      navigate('/candidates/invoice')
      } catch (error) {
        console.log(error)
    }
  }
  // if selected program is silver = set programCode to '1', if gold = '3'
  const handleProgramCode = () => {
    if (activeProgramType === 'silver') {
      setProgramCode('1')
    } else {
      setProgramCode('3')
    }
  }


  return (
    <div className="section-chose-program-detail-information">
      <BackButton className="back-button">{t('Button.backButton')}</BackButton>
      <div className="section-chose-program-detail-information-wrapper">
        <div className="section-chose-program-detail-information__col">
          <DefaultTitle>{t('SelectPrograms.title')}</DefaultTitle>
          <div className="section-chose-program-detail-information__programs">
            <ProgramCard
              icon={ShieldWhite}
              name="Golden Shield"
              type="golden"
              onChange={onChangeProgram}
            />
            <ProgramCard
              icon={ThunderWhite}
              name="First"
              type="silver"
              onChange={onChangeProgram}
            />
          </div>
          {activeProgramType.length > 0 && (<div className="section-chose-program-detail-information__programs">

            { 'test'.length > 3 && (
              <DefaultTitle>{t('SelectPrograms.selectCurrency')}</DefaultTitle>
            )}
          </div>)}
          {activeProgramType.length > 0 && (<div className="section-chose-program-detail-information__programs">

            { 'test'.length > 3 && (
              <CurrenciesList selectedCurrency={selectedCurrency} onCurrencySelect={handleCurrencyChange} />
            )}
          </div>)}
          {activeProgramType.length > 0 && (<div className="section-chose-program-detail-information__programs">

            { 'test'.length > 3 && (
              <DefaultTitle>{t('SelectPrograms.inputAmount')}</DefaultTitle>
            )}
          </div>)}
            {activeProgramType.length > 0 && (<div className="section-chose-program-detail-information__programs">
            { 'test'.length > 3 && (
              <CurrencyInput
                min={10000}
                className="input input-default"
                style={{ width: '100%' }}
                id="input-example"
                name="contract-amount"
                placeholder="Please enter a number"
                defaultValue={10000}
                decimalsLimit={2}
                step={1000}
                onValueChange={(value, name) => handleAmountChange(String(value))}
              />
            )}
          </div>)}
          {activeProgramType.length > 0 && (
            <div className="section-chose-program-detail-information__programs">
              { activeProgramType.length > 3 && (
                <Button onClick={handleProgramCreate} transformed disabled={currencyAmount.length < 3 || selectedCurrency.length < 3 || activeProgramType.length < 1}>
                  {t('SelectPrograms.createProgram')}
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="section-chose-program-detail-information__col">
          {data && data.length > 0 && (
            <DefaultTitle>{t('SelectPrograms.tariffs')}</DefaultTitle>
          )}
          <div className="section-chose-program-detail-information__tariffs">
            {data && data.length > 0 ? (
              data.map(({ from, to, rate, special }) => (
                <TariffCard
                  from={from}
                  to={`${to} у.е.`}
                  rate={`${rate}%`}
                  information={t('SelectPrograms.cardInformation')}
                  special={special}
                  key={`${from}-${to}`}
                />
              ))
            ) : (
              <div className="section-chose-program-detail-information__tariffs-no-content">
                <p>{t('SelectPrograms.noPrograms')}</p>
                <NavLink
                  className="section-chose-program-detail-information__tariffs-no-content__go-back"
                  to="/candidates/dashboard"
                >
                  {t('SelectPrograms.goBack')}
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoseProgramDetailInformation;
