import { LifeJournalIcon } from './lifeJournal';
import { AttorneyIcon } from './attorney';
import { CardIcon } from './card';
import { NoteIcon } from './note';
import { EurosimIcon } from './eurosim';
import { CalculatorIcon } from './calculator';
import { CalendarIcon } from './calendar';
import { InsuranceIcon } from './insurance';
import { MobileIcon } from './mobile';
import { RaitingIcon } from './raiting';
import { WebmailIcon } from './webmail';
import { WorkshopIcon } from './workshop';

export {
  LifeJournalIcon,
  RaitingIcon,
  MobileIcon,
  InsuranceIcon,
  CardIcon,
  CalendarIcon,
  CalculatorIcon,
  EurosimIcon,
  NoteIcon,
  WorkshopIcon,
  AttorneyIcon,
  WebmailIcon,
};
