import React from 'react';

export const StripeLinesIcon = () => {
  return (
    <svg viewBox="0 0 52 33" fill="none" width="50" height="50">
      <path
        d="M0.907227 18.6204C1.03852 24.0035 7.06556 34.7249 14.4883 30.8182C19.6718 28.0901 20.5312 18.2063 20.1471 13.2131C19.9342 10.4457 16.4982 10.6981 14.4883 10.6981C11.5603 10.6981 12.2248 12.4667 12.2248 14.9736C12.2248 20.6118 19.3329 23.8476 24.6741 23.0845C30.4494 22.2595 32.5964 10.13 32.5964 5.60517C32.5964 1.99495 26.9377 -0.375577 26.9377 3.34165C26.9377 9.94006 29.0115 9.46096 34.2941 12.3957C40.927 16.0806 46.2498 9.73485 50.7046 6.17105"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};
