import Select from '../../Select/Select';
import {
  PdfIcon,
  StripeCurveLinesIcon,
  StripeLinesIcon,
  XlsIcon,
} from '../../../assets/media';
import { RangeDatepicker } from '../../RangeDatepicker/RangeDatepicker';
import { useTranslation } from 'react-i18next';

export const Statement = () => {
  const { t } = useTranslation();
  const accounts = [
    { label: 'SE79ABA20150506100130:  268 043,12 USD', value: '1' },
    { label: 'SE79ABA20150506100130:  268 043,12 USD', value: '2' },
  ];
  return (
    <div className="statement">
      <div className="statement-wrapper">
        <div className="statement-header">
          <div className="statement-header__info">
            <p className="statement-header__title">
              {t('Programs.accountStatement')}
            </p>
          </div>
          <div className="statement-header__toolbox">
            <div className="statement-header__toolbox-item">
              <button className="link">
                <PdfIcon />
                pdf
              </button>
            </div>{' '}
            <div className="statement-header__toolbox-item">
              <button className="link">
                <XlsIcon /> xls
              </button>
            </div>
          </div>
        </div>
        <div className="statement-filters">
          <div className="statement-filters__group">
            <Select
              onChange={() => {}}
              options={accounts}
              placeholder={t('Programs.chooseAccountNumber')}
            />
          </div>{' '}
          <div className="statement-filters__group">
            <RangeDatepicker placeholder={t('Programs.chooseDate')} />
          </div>
        </div>
        <div className="statement-table">
          <div className="statement-table__header">
            <div className="statement-table__header-cell statement-table__header-cell--conterparty">
              {t('Programs.operation')}
            </div>{' '}
            <div className="statement-table__header-cell statement-table__header-cell--date">
              {t('Programs.date')}
            </div>{' '}
            <div className="statement-table__header-cell statement-table__header-cell--amount">
              {t('Programs.sum')}
            </div>{' '}
            <div className="statement-table__header-cell statement-table__header-cell--balance">
              {t('Programs.balance')}
            </div>
          </div>
          <div className="statement-table__body">
            <div className="statement-table__body-no-data">
              <span className="statement-table__body-no-data-icon stripe-lines">
                <StripeLinesIcon />
              </span>{' '}
              <span className="statement-table__body-no-data-icon stripe-curve-lines">
                <StripeCurveLinesIcon />
              </span>
              <span className="statement-table__body-no-data__text">
                {t('Programs.noAccountData')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
