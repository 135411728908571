import React from 'react';

interface Props {
  isActive?: boolean;
}
export const UnionPayIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="55"
      height="40"
      viewBox="0 0 55 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_306)">
        <path
          d="M53.4186 3.72595C52.9421 3.16542 52.2415 2.8291 51.4287 2.8291H38.1441H23.0658H10.7902C9.75321 2.8291 8.77228 3.19345 7.98753 3.81003C7.20279 4.42661 6.64226 5.29544 6.39002 6.30439L0.0840454 33.4341C-0.140167 34.387 0.168125 35.3119 0.812736 35.9285C1.28919 36.3769 1.90577 36.6572 2.63446 36.6572H14.9101H29.9603H43.2169C44.4221 36.6572 45.5431 36.2087 46.3839 35.424C47.0846 34.8074 47.5891 33.9666 47.8133 32.9857L54.0071 6.02413C54.1753 5.15531 53.9231 4.31451 53.4186 3.72595Z"
          fill={!isActive ? '#231F20' : 'white'}
        />
        <path
          d="M12.2746 11.4053C12.2746 11.4053 11.9102 12.8627 11.714 13.7315C11.5179 14.6003 11.1535 16.4501 10.9293 17.0666C10.7051 17.6832 10.2847 18.0476 9.83626 18.1597C9.38783 18.2718 8.88336 18.2438 8.60309 17.9915C8.32283 17.7393 8.26677 17.431 8.37888 16.5902C8.51901 15.7494 8.7152 15.0207 8.99546 14.0117C9.33178 12.7225 9.6681 11.4894 9.6681 11.4894H7.36992C7.36992 11.4894 7.06163 13.0028 6.86544 13.8996C6.66926 14.7965 6.22083 16.8424 6.13675 17.5431C6.05267 18.2438 6.30491 18.8323 6.66926 19.1406C7.0336 19.4209 8.04256 19.6451 8.57506 19.6451C9.07954 19.6451 9.97639 19.6171 10.8452 19.2807C11.714 18.9444 11.9383 18.524 12.2746 18.0476C12.6109 17.5711 12.723 16.8985 13.1154 15.4131C13.4797 13.9277 14.1243 11.5454 14.1243 11.5454L12.2746 11.4053Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M17.178 14.7962C16.7015 14.8522 16.085 15.2726 16.085 15.2726C16.085 15.2726 16.169 14.9083 16.1971 14.7962C16.2531 14.6841 16.141 14.7401 15.7767 14.7962C15.4123 14.8522 14.2352 15.0484 14.2352 15.0484C14.2072 15.5249 13.983 16.758 13.8428 17.4026C13.7027 18.0472 13.3384 19.5607 13.3384 19.5607H15.1321C15.1321 19.5607 15.8047 16.3376 15.8327 16.2816C15.8607 16.2255 16.0289 16.0854 16.3933 16.0854C16.7576 16.0854 16.7015 16.5899 16.6455 16.9262C16.5894 17.2625 16.0569 19.5607 16.0569 19.5607L17.8506 19.5327C17.8506 19.5327 18.271 17.4587 18.3831 16.9262C18.5233 16.3937 18.6914 15.8892 18.5233 15.3006C18.3831 14.7121 17.6264 14.7401 17.178 14.7962Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M29.3174 16.253C29.3454 16.1969 29.5136 16.0568 29.8779 16.0568C30.2423 16.0568 30.1862 16.5613 30.1302 16.8976C30.0741 17.2339 29.5416 19.5321 29.5416 19.5321L31.3353 19.504C31.3353 19.504 31.7557 17.4301 31.8678 16.8976C32.008 16.3651 32.1761 15.8606 32.008 15.272C31.8398 14.6835 31.0831 14.6835 30.6347 14.7395C30.1862 14.7956 29.5416 15.216 29.5416 15.216C29.5416 15.216 29.6257 14.8516 29.6537 14.7395C29.7098 14.6274 29.5977 14.6835 29.2333 14.7395C28.869 14.7956 27.6919 14.9918 27.6919 14.9918C27.6638 15.4682 27.4396 16.7014 27.2995 17.346C27.1594 17.9906 26.795 19.504 26.795 19.504H28.6167C28.6167 19.5321 29.2894 16.309 29.3174 16.253Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M20.9368 15.8328C20.6285 15.8889 19.7036 16.001 19.5635 16.029C19.3673 16.029 19.4794 16.2532 19.2832 17.2622C19.1151 18.103 18.9469 18.8036 18.8348 19.0279C18.7227 19.2521 18.6947 19.5604 18.6947 19.5604C18.6947 19.5604 20.5444 19.5604 20.5725 19.5604C20.6005 19.5604 20.6565 19.3642 20.6565 19.224C20.6565 19.0839 20.7686 18.4393 20.8807 17.9909C20.9929 17.5425 21.3852 15.8889 21.3852 15.7768C21.4413 15.6927 21.2451 15.7768 20.9368 15.8328Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M20.4604 14.3479C19.8718 14.488 19.4794 14.9084 19.5635 15.2447C19.6476 15.5811 20.2081 15.7492 20.7687 15.5811C21.3572 15.4409 21.7496 15.0205 21.6655 14.6842C21.5814 14.3479 21.0489 14.2078 20.4604 14.3479Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M25.2236 19.3364C25.672 19.0841 26.0644 18.7198 26.3166 18.1312C26.5689 17.5427 26.8772 16.7579 26.7651 16.0573C26.653 15.3566 26.1205 14.9642 25.588 14.8521C25.0555 14.768 24.579 14.768 24.4108 14.7961C24.0745 14.8241 23.4299 14.9362 22.8974 15.4407C22.3929 15.9451 22.1687 16.2815 21.9165 17.1783C21.6923 18.0752 21.8324 18.6357 22.0286 18.972C22.2528 19.2803 22.6171 19.5606 23.3178 19.6166C24.0185 19.6727 24.7752 19.5886 25.2236 19.3364ZM23.7382 17.1783C23.9064 16.5057 24.0185 16.2815 24.1306 16.0853C24.3828 15.749 24.6631 15.805 24.8312 15.8891C24.9994 15.9451 25.0274 16.3375 24.9433 16.814C24.8873 17.2904 24.6911 17.879 24.607 18.0471C24.5229 18.2433 24.2707 18.5516 24.0185 18.5516C23.7662 18.5516 23.6541 18.4115 23.6261 18.2994C23.5981 18.1873 23.57 17.851 23.7382 17.1783Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M35.0039 16.9541C35.0599 16.7299 35.088 16.6178 35.3402 16.6178C35.5644 16.6178 36.3211 16.6739 37.0778 16.5337C37.8346 16.3936 38.6193 16.1974 39.1798 15.4687C39.7404 14.712 40.0767 13.9553 40.0767 12.9183C40.0767 11.9094 39.1798 11.545 38.5352 11.4609C37.8906 11.3768 34.3032 11.4049 34.3032 11.4049C34.2472 11.6291 34.079 12.582 33.7987 13.8152C33.5185 15.0483 32.3974 19.5326 32.3974 19.5326H34.4433C34.4433 19.5326 34.9478 17.1783 35.0039 16.9541ZM35.6765 14.0954C35.8727 13.2827 36.0128 12.61 36.0128 12.61C36.0128 12.61 37.0778 12.638 37.3581 12.7221C37.6384 12.8062 38.0027 13.0584 37.9467 13.7311C37.8906 14.4037 37.3861 14.9082 36.9938 15.1604C36.6294 15.4127 35.3963 15.3846 35.3963 15.3846C35.3963 15.3846 35.4803 14.9362 35.6765 14.0954Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M39.3491 16.085C38.9287 16.6736 38.7325 18.0189 38.7605 18.3832C38.7605 18.7475 38.9567 19.1119 39.321 19.3922C39.6854 19.6724 40.4141 19.7285 40.7504 19.6724C41.0867 19.6164 41.423 19.3081 41.423 19.3081L41.367 19.6444H43.2728C43.2728 19.6444 43.3288 19.4482 43.3569 19.2801C43.4129 19.0839 43.525 18.2431 43.7773 17.122C44.0575 16.029 44.3098 14.8799 44.3098 14.8799C44.3098 14.8799 44.1977 14.8799 43.7492 14.964C43.3008 15.02 42.432 15.1601 42.3759 15.1601C42.2919 15.1601 42.2919 15.3563 42.2919 15.3563C42.2919 15.3563 42.1237 15.1882 42.0116 15.0761C41.8995 14.964 41.7033 14.7958 41.0026 14.9359C40.302 15.048 39.7695 15.4965 39.3491 16.085ZM40.7224 17.066C40.9466 16.3092 41.1147 16.1131 41.2549 16.029C41.395 15.9449 41.7313 15.8608 41.9275 16.1411C42.1237 16.4213 42.0676 16.7016 42.0116 17.066C41.8995 17.6265 41.7594 18.0749 41.5912 18.3271C41.423 18.5514 41.0867 18.6635 40.9466 18.6635C40.7784 18.6635 40.5822 18.5233 40.5822 18.2991C40.5542 18.0749 40.4982 17.7946 40.7224 17.066Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M49.9135 14.7966C49.8294 14.7405 49.6893 14.7966 49.437 14.8526C49.1848 14.9087 48.2039 15.0208 48.1478 15.0208C48.0917 15.0208 48.0077 15.273 47.8675 15.5813C47.7274 15.8896 47.0267 17.1228 47.0267 17.1228C47.0267 17.1228 46.9427 15.4132 46.9427 15.1049C46.9427 14.7686 46.9146 14.7686 46.8306 14.7686C46.7465 14.7686 46.4942 14.8246 46.0738 14.9087C45.6534 14.9928 45.205 15.0768 45.1209 15.1049C45.0369 15.1329 45.1209 15.3851 45.177 15.6654C45.233 15.9457 45.6534 19.6732 45.6534 19.6732C45.6534 19.6732 44.2521 22.0555 44.14 22.2236C44.0279 22.3918 43.9718 22.4478 43.8317 22.4478C43.6916 22.4478 43.4954 22.4478 43.4954 22.4478L43.1871 23.4568C43.1871 23.4568 44.0839 23.5128 44.5324 23.4568C44.9808 23.4007 45.149 23.2045 45.4572 22.7561C45.7655 22.3357 46.0178 21.7752 46.5783 20.7943C47.1388 19.8133 48.7364 16.9827 49.2689 16.0578C49.7733 15.1329 49.9695 14.8526 49.9135 14.7966Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M24.6649 21.6068C24.6649 21.6068 24.2445 22.4196 24.1604 22.5597C24.0764 22.7279 23.9643 22.7839 23.8241 22.812C23.684 22.812 23.3477 22.812 23.3477 22.812L23.0394 23.8209C23.0394 23.8209 23.7681 23.8209 23.9923 23.8209C24.2445 23.8209 24.8891 23.6247 25.1414 23.2604L27.5797 23.2324L27.888 22.1674L26.1223 22.1393L26.4306 21.5508L24.6649 21.6068Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M24.5795 28.5295C24.4674 28.4734 24.4954 28.3613 24.5234 28.2212C24.5795 28.081 24.8878 26.988 24.8878 26.988H26.6534L26.9617 25.951H25.2241L25.4763 25.0822H27.214L27.5223 24.0732H23.2062L22.8979 25.0822L23.8788 25.1102L23.5985 25.979H22.6176L22.2813 27.0721H23.2622C23.2622 27.0721 22.7858 28.5575 22.7297 28.8097C22.6737 29.0339 22.6456 29.1741 22.7577 29.3703C22.8699 29.5664 23.01 29.7066 23.3743 29.7066C23.7387 29.7066 25.5604 29.7346 25.5604 29.7346L25.8967 28.5575C25.6165 28.5855 24.7196 28.5855 24.5795 28.5295Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M28.2486 28.1647C28.2766 27.9965 28.9492 25.8665 28.9492 25.8665C28.9492 25.8665 31.1914 25.8945 31.6118 25.8665C32.0322 25.8385 32.7048 25.8104 32.9851 25.362C33.2653 24.9136 33.4335 24.269 33.6857 23.5403C33.9099 22.7836 34.2463 21.6345 34.2463 21.6345C34.2463 21.6345 32.3405 21.6345 31.892 21.6345C31.4436 21.6345 30.4627 21.8307 29.9582 22.3632L30.1544 21.6064L28.6409 21.6345C28.6409 21.6345 26.595 28.473 26.5109 28.8093C26.4268 29.1176 26.3988 29.2297 26.4829 29.3418C26.595 29.4539 26.7071 29.622 26.9594 29.622C27.0434 29.622 29.1734 29.6501 29.1734 29.6501L29.5098 28.501C29.5098 28.501 28.5849 28.5851 28.4448 28.5851C28.3046 28.5851 28.1925 28.4169 28.2486 28.1647ZM29.8461 22.7555H32.3124L32.1443 23.3441C32.1443 23.3441 31.3875 23.3721 30.855 23.3721C30.3225 23.3721 29.5378 23.9046 29.5378 23.9046L29.8461 22.7555ZM29.3696 24.3811L31.836 24.4091L31.6958 24.8856H29.2295L29.3696 24.3811Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M31.7539 26.3992C31.6698 26.6795 31.5857 26.7075 31.5017 26.7075C31.4176 26.7075 30.9972 26.7075 30.9972 26.7075L31.0252 26.1189L29.5958 26.0909C29.5958 26.0909 29.5118 28.8655 29.5398 29.1458C29.5398 29.4261 29.792 29.5662 30.0162 29.5662C30.2405 29.5662 31.9221 29.5662 31.9221 29.5662L32.2303 28.5012C32.2303 28.5012 31.3055 28.5292 31.1653 28.5292C31.0252 28.5292 30.9692 28.4451 30.9692 28.305C30.9692 28.1649 30.9692 27.4922 30.9692 27.4922C30.9692 27.4922 31.5297 27.4922 32.0061 27.4362C32.4826 27.4082 32.7628 27.0438 32.8469 26.8476C32.931 26.6514 33.0431 26.0068 33.0431 26.0068H31.894C31.866 26.0629 31.838 26.147 31.7539 26.3992Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M38.9018 28.1647H38.4534L40.0789 22.6995L40.6114 22.7275L40.7796 22.167C40.7796 22.167 40.7796 22.7555 40.8356 22.9517C40.8917 23.1479 41.0318 23.4001 41.4802 23.4282C41.9287 23.4562 42.7975 23.4562 42.7975 23.4562L43.1058 22.3912C43.1058 22.3912 42.7134 22.4192 42.6293 22.4192C42.5452 22.4192 42.4892 22.3632 42.4612 22.139C42.4612 21.9147 42.4612 21.6345 42.4612 21.6345C42.4612 21.6345 39.3782 21.6625 38.9578 21.6905C38.5375 21.7186 37.9489 21.7466 37.4164 22.167L37.5565 21.6064H35.987L33.9691 28.2487H33.6328L33.3245 29.2857H36.5195L36.4074 29.622H37.9769L38.061 29.2857H38.4814L38.9018 28.1647ZM36.9119 28.1647H35.6507L36.0151 26.9595H37.2482C37.2202 26.9595 36.9119 28.1647 36.9119 28.1647ZM37.6126 25.8104C36.6036 25.9225 36.2112 26.2308 36.2112 26.2308L36.6316 24.8015H37.8928L37.6126 25.8104ZM37.7247 23.7645C37.4164 23.7925 36.8839 24.0728 36.8839 24.0728L37.2763 22.6995H38.5375L38.2292 23.7365C38.2292 23.7365 38.033 23.7365 37.7247 23.7645Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M39.9369 24.7739L41.7586 24.8019L41.5344 25.6427H39.6846L39.4044 26.5676L41.0579 26.5956C41.0579 26.5956 39.9089 28.1651 39.7687 28.3613C39.6286 28.5575 39.4044 28.6135 39.2923 28.6416C39.1802 28.6696 38.7878 28.6416 38.7878 28.6416L38.4795 29.6505C38.4795 29.6505 39.4324 29.6785 39.8528 29.6505C40.2732 29.6225 40.5254 29.3983 40.7497 29.2021C40.9458 29.0059 41.7586 27.8848 41.7586 27.8848C41.7586 27.8848 41.8707 28.6696 41.9268 29.0339C41.9828 29.3983 42.4312 29.6225 42.6555 29.6225C42.8797 29.6225 43.6364 29.6225 43.6364 29.6225L43.9727 28.4734C43.9727 28.4734 43.5803 28.4734 43.4963 28.4734C43.4122 28.4734 43.3561 28.4174 43.3001 28.2772C43.244 28.1371 43.1039 27.016 43.1039 27.016H42.3191L42.6274 26.6237H44.5052L44.8135 25.6988L43.1039 25.6707L43.3561 24.83H45.0938L45.4021 23.793H40.2732L39.9369 24.7739Z"
          fill={!isActive ? 'white' : '#D4A387'}
        />
        <path
          d="M23.0939 1.96094C22.2531 2.63357 21.6365 3.55845 21.3843 4.65149L20.067 9.50008"
          fill="#070707"
        />
        <path
          d="M23.0939 1.96094C22.2531 2.63357 21.6365 3.55845 21.3843 4.65149L20.067 9.50008"
          stroke={!isActive ? 'white' : '#D4A387'}
          stroke-width="2.19456"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5909 24.3818L14.2367 34.4714C13.9845 35.4804 14.3488 36.4893 15.0215 37.1619"
          fill="#070707"
        />
        <path
          d="M16.5909 24.3818L14.2367 34.4714C13.9845 35.4804 14.3488 36.4893 15.0215 37.1619"
          stroke={!isActive ? 'white' : '#D4A387'}
          stroke-width="2.19456"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.3577 1.0918C39.2367 1.0918 38.1717 1.48417 37.3309 2.15681C36.4901 2.82944 35.8735 3.75432 35.6212 4.84736L34.9206 7.90225"
          stroke={!isActive ? 'white' : '#D4A387'}
          stroke-width="2.19456"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.1471 32.7617L28.7547 34.4713C28.5025 35.4803 28.8668 36.4893 29.5394 37.1619C30.0439 37.6383 30.7446 37.9466 31.5013 37.9466"
          stroke={!isActive ? 'white' : '#D4A387'}
          stroke-width="2.19456"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_306">
          <rect width="54.0632" height="39.041" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
