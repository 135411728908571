import React from 'react';

interface Props {
  percent: number;
  mainColor: string;
}
export const CircleChart = ({ percent, mainColor }: Props) => {
  return (
    <div className="circular-chart">
      <span className="circular-chart-label" style={{ color: mainColor }}>
        {percent}%
      </span>
      <svg
        viewBox="0 0 36 36"
        className="circular-chart-draw"
        style={{ stroke: mainColor }}
      >
        <path
          className="circular-chart-draw-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circular-chart-draw-circle"
          stroke-dasharray={`${percent}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
  );
};
