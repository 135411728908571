import React from 'react';

interface Props {
  isActive?: boolean;
}
export const WebmailIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="56"
      height="35"
      viewBox="0 0 56 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.1403 33.5263H3.85877C2.54499 33.5263 1.47754 32.4619 1.47754 31.152V3.8479C1.47754 2.53796 2.54499 1.47363 3.85877 1.47363H47.1403C48.4541 1.47363 49.5215 2.53796 49.5215 3.8479V31.152C49.5215 32.4619 48.4541 33.5263 47.1403 33.5263Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.95459 3.11128L24.1921 18.2809C25.3006 19.0586 26.7376 19.0586 27.8461 18.2809L48.0836 3.11128C48.5352 2.7838 48.33 2.08789 47.7552 2.08789H3.28304C2.70826 2.08789 2.50298 2.82473 2.95459 3.11128Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
