import { createApi } from '@reduxjs/toolkit/query/react';

import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { DocumentInterface } from '../../interfaces/document.interface';
import { InvitationApplyInterface } from '../../interfaces/invitationApply.interface';

export const invitationApplyApi = createApi({
  reducerPath: 'invitationApplyApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    getInvitationApply: builder.query<DocumentInterface[], void>({
      query: () => 'people/me',
    }),
    signInvitationApply: builder.mutation<
      InvitationApplyInterface,
      InvitationApplyInterface
    >({
      query: (body) => ({
        url: `invites/accept`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetInvitationApplyQuery, useSignInvitationApplyMutation } =
  invitationApplyApi;
