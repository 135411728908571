import React from 'react';

import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import { Footer } from '../components/Footer/Footer';

interface Props {
  type: 'golden' | 'silver';
}
export const LayoutProduct = ({ type }: Props) => {
  return (
    <div className="content">
      <Header classes={type === 'golden' ? 'nav--bronze' : 'nav--blue'} />
      <Outlet />
      <Footer />
    </div>
  );
};
