import React from 'react';

export const ShieldIcon = () => {
  return (
    <svg id="shield" viewBox="0 0 12 14">
      <path d="M11.9875 3.57761C11.9733 3.20812 11.9733 2.85285 11.9733 2.51179C11.9733 2.24178 11.7601 2.02861 11.4901 2.02861C9.40108 2.0144 7.80945 1.41754 6.48784 0.138557C6.30309 -0.0461856 5.99045 -0.0461856 5.80571 0.138557C4.48409 1.41754 2.89246 2.0144 0.817661 2.0144C0.547652 2.0144 0.334488 2.22757 0.334488 2.49758C0.334488 2.83864 0.334488 3.19391 0.320277 3.57761C0.249222 7.08772 0.149746 11.8768 5.99045 13.8948C6.0473 13.909 6.08993 13.9232 6.14677 13.9232C6.20362 13.9232 6.26046 13.909 6.30309 13.8948C12.1438 11.8768 12.0443 7.08772 11.9875 3.57761Z"></path>
      <path
        d="M7.82394 5.26872L5.47913 7.61353L4.47015 6.60455C4.28541 6.4198 3.97276 6.4198 3.77381 6.60455C3.58907 6.78929 3.58907 7.10193 3.77381 7.30088L5.12385 8.65093C5.22333 8.7504 5.33702 8.79304 5.46492 8.79304C5.59281 8.79304 5.72071 8.7504 5.80598 8.65093L8.50606 5.95084C8.6908 5.7661 8.6908 5.45346 8.50606 5.25451C8.32132 5.06976 8.00868 5.06976 7.82394 5.26872Z"
        fill="none"
      ></path>
    </svg>
  );
};
