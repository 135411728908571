import React from 'react';

export const EnteringHistoryForm = () => {
  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <div className="section-launch-product-main__form section-launch-settings-main__form">
              <div className="other-seans section-launch-product-main__form-section section-launch-settings-main__form-section section-launch-settings-main__form-section--main">
                <p className="section-launch-product-main__form-section-heading">
                  Последний вход
                </p>
                <div
                  className="section-launch-product-main__form-section-content"
                  style={{ marginTop: 0 }}
                >
                  <div className="section-launch-product-main__form-section">
                    <div className="section-launch-settings-main__form-section-heading">
                      <span>Windows</span>
                    </div>
                    <div className="section-launch-settings-main__form-section-description section-launch-settings-main__form-section-description--has-item">
                      <span>, </span>
                      <span className="section-launch-settings-main__activity-now">
                        сейчас активен
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="other-seans section-launch-product-main__form-section section-launch-settings-main__form-section section-launch-settings-main__form-section--main">
                <p className="section-launch-product-main__form-section-heading">
                  Другие сеансы
                </p>
                <div className="section-launch-product-main__form-section-content">
                  <div className="section-launch-product-main__form-section">
                    <div className="section-launch-settings-main__form-section-heading">
                      Windows
                    </div>
                    <div className="section-launch-settings-main__form-section-description section-launch-settings-main__form-section-description--has-item">
                      <span>,</span>
                      <span>22 часа назад</span>
                      <span>IP 46.219.134.70, 162.158.203.30</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            История входов
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                В данном разделе вы можете посмотреть историю входов в свой
                личный кабинет, которая содержит даты, точное время, устройства
                и IP-адреса, с которых проходила авторизация в аккаунт. Порой
                информация об устройстве или сервисе может быть неточной. Однако
                если вы видите, что вход в кабинет осуществлялся с чужого
                IP-адреса или неизвестного устройства, и переживаете за
                безопасность данных, обратитесь в службу технической поддержки и
                смените пароль от личного кабинета.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
