import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ExpiredRegistrationFormInterface,
  ConfirmEmailInterface,
  GetConfirmEmailInterface,
  GetRegistrationOffersFormInterface,
  RegistrationOffersFormInterface,
  RegistrationTypeFormInterface,
  GetRegistrationTypeFormInterface,
  RegistrationPassportDateFormInterface,
  GetRegistrationPassportDateFormInterface,
  RegistrationAddressFormInterface,
  GetRegistrationAddressFormInterface,
  CandidateRegistrationFormInterface,
} from '../../interfaces/registration.interface';

import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';

export const registrationCandidatesApi = createApi({
  reducerPath: 'registrationInitiate',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    registrationInitiate: builder.mutation<
      CandidateRegistrationFormInterface,
      CandidateRegistrationFormInterface
    >({
      query: (post) => ({
        url: `candidates/become`,
        method: 'POST',
        body: post,
      }),
    }),

    expiredRegistration: builder.mutation<
      ExpiredRegistrationFormInterface,
      ExpiredRegistrationFormInterface
    >({
      query: (post) => ({
        url: `candidates/resent-register-mail`,
        method: 'POST',
        body: post,
      }),
    }),
    confirmEmail: builder.mutation<
      GetConfirmEmailInterface,
      ConfirmEmailInterface
    >({
      query: (post) => ({
        url: `candidates/login`,
        method: 'POST',
        body: post,
      }),
    }),
    getRegistrationOffers: builder.query<
      GetRegistrationOffersFormInterface,
      void
    >({
      query: () => 'candidates/register?step=1',
    }),
    registrationOffers: builder.mutation<
      RegistrationOffersFormInterface,
      RegistrationOffersFormInterface
    >({
      query: (post) => ({
        url: `candidates/register?step=1`,
        method: 'POST',
        body: post,
      }),
    }),
    getRegistrationTypeForm: builder.query<
      GetRegistrationTypeFormInterface,
      void
    >({
      query: () => 'candidates/register?step=2',
    }),
    registrationTypeForm: builder.mutation<
      RegistrationTypeFormInterface,
      RegistrationTypeFormInterface
    >({
      query: (post) => ({
        url: `candidates/register?step=2`,
        method: 'POST',
        body: post,
      }),
    }),
    getRegistrationPassportDate: builder.query<
      GetRegistrationPassportDateFormInterface,
      void
    >({
      query: () => 'candidates/register?step=3',
    }),
    registrationPassportDate: builder.mutation<
      RegistrationPassportDateFormInterface,
      RegistrationPassportDateFormInterface
    >({
      query: (post) => ({
        url: `candidates/register?step=3`,
        method: 'POST',
        body: post,
      }),
    }),
    getRegistrationAddress: builder.query<
      GetRegistrationAddressFormInterface,
      void
    >({
      query: () => 'candidates/register?step=4',
    }),
    RegistrationAddress: builder.mutation<
      RegistrationAddressFormInterface,
      RegistrationAddressFormInterface
    >({
      query: (post) => ({
        url: `candidates/register?step=4`,
        method: 'POST',
        body: post,
      }),
    }),
  }),
});

export const {
  useRegistrationInitiateMutation,
  useExpiredRegistrationMutation,
  useConfirmEmailMutation,
  useGetRegistrationOffersQuery,
  useRegistrationOffersMutation,
  useLazyGetRegistrationTypeFormQuery,
  useRegistrationTypeFormMutation,
  useLazyGetRegistrationPassportDateQuery,
  useRegistrationPassportDateMutation,
  useRegistrationAddressMutation,
  useLazyGetRegistrationAddressQuery,
} = registrationCandidatesApi;
