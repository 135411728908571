import React from 'react';

interface Props {
  theme: 'black' | 'white';
}
export const PrivacyStarsIcon = ({ theme }: Props) => {
  return (
    <svg viewBox="0 0 42 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.97266 5.292L8.96866 3.64C9.08066 3.584 9.16465 3.416 9.08066 3.276L8.66066 2.576C8.57666 2.436 8.40866 2.408 8.29666 2.492L5.38466 4.256L5.46866 0.839997C5.46866 0.699997 5.35666 0.587997 5.18866 0.559998H4.32066C4.18066 0.587997 4.06866 0.699997 4.06866 0.839997L4.12466 4.256L1.21266 2.492C1.10066 2.408 0.932656 2.436 0.848656 2.576L0.428656 3.276C0.344656 3.416 0.428656 3.584 0.540656 3.64L3.53666 5.292L0.540656 6.944C0.428656 7 0.372656 7.168 0.428656 7.308L0.848656 8.008C0.932656 8.148 1.10066 8.176 1.21266 8.092L4.12466 6.328L4.06866 9.744C4.06866 9.884 4.18066 9.996 4.32066 10.024H5.18866C5.35666 9.996 5.46866 9.884 5.46866 9.744L5.38466 6.328L8.29666 8.092C8.40866 8.176 8.57666 8.148 8.66066 8.008L9.08066 7.308C9.16465 7.168 9.08066 7 8.96866 6.944L5.97266 5.292Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
      <path
        d="M22.2914 5.292L25.2874 3.64C25.3994 3.584 25.4834 3.416 25.3994 3.276L24.9794 2.576C24.8954 2.436 24.7274 2.408 24.6154 2.492L21.7034 4.256L21.7874 0.839997C21.7874 0.699997 21.6754 0.587997 21.5074 0.559998H20.6394C20.4994 0.587997 20.3874 0.699997 20.3874 0.839997L20.4434 4.256L17.5314 2.492C17.4194 2.408 17.2514 2.436 17.1674 2.576L16.7474 3.276C16.6634 3.416 16.7474 3.584 16.8594 3.64L19.8554 5.292L16.8594 6.944C16.7474 7 16.6914 7.168 16.7474 7.308L17.1674 8.008C17.2514 8.148 17.4194 8.176 17.5314 8.092L20.4434 6.328L20.3874 9.744C20.3874 9.884 20.4994 9.996 20.6394 10.024H21.5074C21.6754 9.996 21.7874 9.884 21.7874 9.744L21.7034 6.328L24.6154 8.092C24.7274 8.176 24.8954 8.148 24.9794 8.008L25.3994 7.308C25.4834 7.168 25.3994 7 25.2874 6.944L22.2914 5.292Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
      <path
        d="M38.6102 5.292L41.6062 3.64C41.7182 3.584 41.8022 3.416 41.7182 3.276L41.2982 2.576C41.2142 2.436 41.0462 2.408 40.9342 2.492L38.0222 4.256L38.1062 0.839997C38.1062 0.699997 37.9942 0.587997 37.8262 0.559998H36.9582C36.8182 0.587997 36.7062 0.699997 36.7062 0.839997L36.7622 4.256L33.8502 2.492C33.7382 2.408 33.5702 2.436 33.4862 2.576L33.0662 3.276C32.9822 3.416 33.0662 3.584 33.1782 3.64L36.1742 5.292L33.1782 6.944C33.0662 7 33.0102 7.168 33.0662 7.308L33.4862 8.008C33.5702 8.148 33.7382 8.176 33.8502 8.092L36.7622 6.328L36.7062 9.744C36.7062 9.884 36.8182 9.996 36.9582 10.024H37.8262C37.9942 9.996 38.1062 9.884 38.1062 9.744L38.0222 6.328L40.9342 8.092C41.0462 8.176 41.2142 8.148 41.2982 8.008L41.7182 7.308C41.8022 7.168 41.7182 7 41.6062 6.944L38.6102 5.292Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
    </svg>
  );
};
