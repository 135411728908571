import React from 'react';

interface Props {
  isActive?: boolean;
}
export const BinomMobileIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="41"
      height="35"
      viewBox="0 0 41 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_294)">
        <path
          d="M25.4066 18.5545H26.0442C26.5223 18.5545 26.873 18.3951 27.1599 18.0763C27.383 17.7894 27.5105 17.4069 27.5105 16.8969V8.44952C27.5105 8.29013 27.5424 8.1945 27.6061 8.09887C27.6699 8.00324 27.7974 7.93949 27.9568 7.87573C28.1162 7.81198 28.3712 7.7801 28.7218 7.74822C29.0725 7.71635 29.5188 7.68447 30.0925 7.68447H33.9815C34.5234 7.68447 34.9378 7.71635 35.2885 7.74822C35.6072 7.7801 35.8623 7.84385 36.0535 7.87573C36.2129 7.93949 36.3085 8.00324 36.3723 8.09887C36.436 8.16262 36.4679 8.29013 36.4679 8.41764V16.8969C36.4679 17.4069 36.5954 17.7894 36.8186 18.0763C37.0736 18.3951 37.4561 18.5545 37.9343 18.5545H38.5718C39.0499 18.5545 39.4006 18.3951 39.6875 18.0763C39.9106 17.7894 40.0381 17.4069 40.0381 16.8969V8.44952C40.0381 6.98318 39.4962 5.89936 38.4124 5.1662C37.3923 4.4649 35.926 4.11426 33.9815 4.11426H30.0607C28.1162 4.11426 26.618 4.4649 25.5979 5.1662C24.5141 5.89936 23.9722 7.01506 23.9722 8.48139V16.9288C23.9722 17.4388 24.0997 17.8213 24.3228 18.1082C24.5778 18.3951 24.9604 18.5545 25.4066 18.5545Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M20.658 5.19922H20.5943C19.3192 5.19922 18.586 5.19922 18.586 6.85682V16.9299C18.586 17.4399 18.7135 17.8225 18.9367 18.1094C19.1917 18.4281 19.5742 18.5875 20.0524 18.5875H20.658C21.6462 18.5875 22.1244 18.0456 22.1244 16.9299V6.82494C22.1244 6.31491 21.9968 5.93239 21.7737 5.6455C21.4868 5.3586 21.1362 5.19922 20.658 5.19922Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M15.2387 5.35833C14.1868 4.59329 12.6567 4.21077 10.6485 4.21077H4.04995V2.20252C4.04995 1.72437 3.92244 1.30997 3.6993 1.02308C3.44429 0.704306 3.06176 0.544922 2.58361 0.544922H1.94607C1.4998 0.544922 1.11727 0.704306 0.862258 1.02308C0.63912 1.30997 0.511612 1.69249 0.511612 2.20252V13.9013C0.511612 14.9533 0.862258 15.8458 1.53167 16.579C2.16921 17.2803 3.12552 17.8222 4.40059 18.2047C4.84687 18.3322 5.26127 18.4279 5.67567 18.4916C6.12195 18.5554 6.53635 18.5872 6.95075 18.5872H10.6485C11.1585 18.5872 11.6685 18.5554 12.1148 18.4916C12.593 18.4279 13.0074 18.3641 13.4218 18.2366C14.5693 17.9178 15.43 17.4078 16.0038 16.6746C16.6094 15.9096 16.8963 14.9852 16.8963 13.8376V8.92855C16.8963 7.3347 16.3544 6.15526 15.2387 5.35833ZM13.3899 13.7101C13.3899 13.997 13.358 14.252 13.2624 14.4114C13.1986 14.5708 13.0711 14.6664 12.9117 14.7301C12.7523 14.7939 12.4973 14.8576 12.1467 14.8895C11.7642 14.9214 11.2541 14.9533 10.6803 14.9533H7.23764C6.79136 14.9533 6.40884 14.9214 6.02632 14.8576C5.64379 14.7939 5.29315 14.6983 4.97438 14.5708C4.62373 14.4432 4.36872 14.2839 4.17746 14.1245C4.08182 14.0288 4.01807 13.9332 4.01807 13.8376V7.84473H10.7122C11.7004 7.84473 12.4336 7.97224 12.8798 8.19538C13.2305 8.38664 13.3899 8.64165 13.3899 8.9923V13.7101Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M34.4274 19.957H32.7698C31.4629 19.957 30.2834 20.3077 29.2315 20.9771C28.2114 20.3077 27.032 19.957 25.725 19.957H24.0993C19.3178 19.957 18.2977 22.6028 18.2977 24.8342V32.6759C18.2977 33.7279 18.8396 34.3335 19.7322 34.3335H20.3697C21.2623 34.3335 21.8042 33.7279 21.8042 32.6759V24.5473C21.8042 23.8779 22.5692 23.5591 24.1312 23.5591H25.725C26.5538 23.5591 26.8726 23.6866 27.0001 23.7504C27.0957 23.8141 27.4145 24.0054 27.4145 24.8661V32.6759C27.4145 33.7279 27.9564 34.3335 28.8808 34.3335H29.5184C30.4428 34.3335 30.9847 33.7279 30.9847 32.6759V24.898C30.9847 24.2285 31.2078 23.5591 32.8017 23.5591H34.3955C36.5313 23.5591 36.5313 24.2923 36.5313 24.5154V32.6759C36.5313 33.7279 37.0732 34.3335 37.9976 34.3335H38.6352C39.5277 34.3335 40.0696 33.7279 40.0696 32.6759V24.8342C40.0059 22.6028 39.0496 19.957 34.4274 19.957Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M10.6162 19.957H6.6316C4.62335 19.957 3.06138 20.4989 1.94569 21.5828C0.989384 22.4434 0.51123 23.5272 0.51123 24.7704V29.6476C0.51123 30.3808 0.670615 31.0502 1.02126 31.7196C1.94569 33.4728 3.82643 34.3335 6.6316 34.3335H10.6162C12.1144 34.3335 13.3257 34.0466 14.2821 33.5366C15.7484 32.7078 16.4816 31.4008 16.4816 29.6476V24.7386C16.4816 22.5391 15.4615 19.957 10.6162 19.957ZM12.9113 29.6476C12.9113 29.7751 12.9113 30.1576 12.0825 30.4764C11.6044 30.6677 11.0944 30.7633 10.5206 30.7633H6.66347C6.02593 30.7633 5.42027 30.6677 4.94212 30.5083C4.01769 30.2214 4.01769 29.807 4.01769 29.6476V24.7704C4.01769 24.6111 4.01769 24.1648 4.91024 23.8141C5.42027 23.6229 5.99406 23.5272 6.6316 23.5272H10.5206C12.1144 23.5272 12.9113 23.8779 12.9113 24.5473V29.6476Z"
          fill={!isActive ? 'black' : 'white'}
        />
        <path
          d="M19.7017 4.27488H21.0087C21.6781 4.27488 22.1244 3.8286 22.1244 3.15919V2.13913C22.1244 1.46971 21.6781 1.02344 21.0087 1.02344H19.7017C19.0323 1.02344 18.586 1.46971 18.586 2.13913V3.19107C18.586 3.8286 19.0323 4.27488 19.7017 4.27488Z"
          fill={!isActive ? 'black' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_294">
          <rect
            width="39.4955"
            height="33.7895"
            fill="white"
            transform="translate(0.511719 0.544922)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
