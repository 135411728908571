import { Button } from '../Button/Button';
import { ApprovalModalProps } from './ApprovalModal.props';
import { useTranslation } from 'react-i18next';

function ApprovalModal({
  content,
  onReject,
  onSuccess,
  isOpen,
}: ApprovalModalProps) {
  
  const { t } = useTranslation();
  return (
    <div className={`approval-modal ${isOpen ? 'approval-modal-open' : ''}`}>
      <div className="approval-modal-position-wrapper">
        <p className="approval-modal__content">{content}</p>
        <div className="approval-modal__actions">
          <Button onClick={onReject} transparent>
            {t('ApprovalModal.reject')}
          </Button>
          <Button onClick={onSuccess}> {t('ApprovalModal.submit')}</Button>
        </div>
      </div>
    </div>
  );
}

export default ApprovalModal;

