import { MembershipCardProps } from './MembershipCard.props';
import CurrenciesList from '../CurrenciesList/CurrenciesList';
import ExchangeIcon from '../../../assets/media/currencies/exchange.svg';
import { useTranslation } from 'react-i18next';

function MembershipCard({
  currencyFrom,
  currencyTo,
  sumFrom,
  sumTo,
  onCurrencySelect,
  selectedCurrency,
}: MembershipCardProps) {
  const { t } = useTranslation();
  return (
    <div className="invoice-cards invoice-cards-membership">
      <div className="invoice-cards-membership__header">
        <h4 className="invoice-cards__header-title">{t('Invoice.price')}</h4>
        <div className="invoice-cards-membership__header-convertor">
          <div className="invoice-cards-membership__header-convertor__item">
            {sumFrom} <sup>{currencyFrom}</sup>
          </div>
          <div
            className={`invoice-cards-membership__header-convertor__item ${
              !currencyTo
                ? 'invoice-cards-membership__header-convertor__item-neutral'
                : ''
            }`}
          >
            <img
              className="invoice-cards-membership__header-convertor__item-icon"
              src={ExchangeIcon}
              alt="alt"
            />
            {currencyTo ? sumTo : '0.0'}{' '}
            {false && (<sup>{currencyTo ? currencyTo.toUpperCase() : currencyFrom}</sup>)}
          </div>
        </div>
      </div>

      <div className="invoice-cards__body">
        <p className="invoice-cards_subtitle">
          {t('Invoice.currencySelection')}
        </p>
        <CurrenciesList
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={selectedCurrency}
        />
      </div>
    </div>
  );
}

export default MembershipCard;
