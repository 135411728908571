import { useLocation } from 'react-router-dom';
import { CheckCardProps } from './CheckCard.props';

export function CheckCard({
  children,
  value,
  selected,
  disabled,
  type = 'small',
  additionalHeaderTitle,
  onClick,
}: CheckCardProps) {
  const location = useLocation();
  return (
    <div
      className={`check-card ${type || ''} check-card-auto_height ${
        selected ? 'check-card-selected' : ''
      } ${onClick ? 'check-card-clickable' : ''} ${location.pathname === '/candidates/documents' ? 'check-card-fixed_height' : ''}`}
      onClick={onClick}
      aria-hidden
    >
      <div className="check-card__header">
        <div>
          <input
            type="checkbox"
            id="check"
            className="check-card__header-input"
            name="check"
            checked={value}
            disabled={disabled}
            readOnly
          />
          <label className="check-card__header-label" htmlFor="check" />
        </div>
        {additionalHeaderTitle && (
          <span className="check-card__header-additional-title">
            {additionalHeaderTitle}
          </span>
        )}
      </div>
      <div > </div>
      <div
        style={{ whiteSpace: 'pre-line', marginTop: '10px' }}
        className={`check-card__body ${
          location.pathname === '/candidates/documents' ? 'mt-24' : ''
        }`}
      >
        {'\n'}{children}
      </div>
    </div>
  );
}

