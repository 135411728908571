import FormBase from '../FormBase/FormBase';
import ImageUploader from '../ImageUploader/ImageUploader';
import DefaultTitle from '../Titles/Default';
import BackButton from '../BackButton/BackButton';
import { useForm, useWatch } from "react-hook-form";
import Select from 'react-select';
import {
  IDENTIFICATIONS,
  PERSONAL_IDENTIFICATIONS, SEX
} from "../Select/Select.model";
import React, { useEffect, useState } from "react";
import { Button } from '../Button/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import  axiosApi  from '../../helpers/axios.utils';
import { useModal } from '../../hooks/useModal';
import ModalWindow from '../ModalWindow/ModalWindow';
import Loader from 'react-loader-advanced';
import RadioButton from "../RadioButton/RadioButton";
import Input from "../Input/Input";
import { PersonalDataTypeFormInterface } from '../../interfaces/personalData.interface';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { CountryModel } from "../CountryList/CountryList.model";
import { useGetCountryListQuery } from '../../app/services/country.api';
import { CheckCard } from "../CheckCard/CheckCard";
import moment from "moment";
import {
  usePostUserMutation,
  usePostCompanyMutation,
} from '../../app/services/personal-data.api';

interface DocumentSelect {
  value: string;
  label: string;
}


function UploadDocumentsForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rerender, setRerender] = useState(false);

  const [isCompany, setToggle] = useState(false);
  const [showIdentificationUploader, setShowIdentificationUploader] = useState(false);
  const [showRegistrationUploader, setShowRegistrationUploader] = useState(false);
  const [postUser] = usePostUserMutation();
  const [postCompany] = usePostCompanyMutation();

  const uploadDocumentsScheme = yup.object({
    companyName: !isCompany
      ? yup.string()
      : yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Please enter your full name.'),
    companyRegistrationNumber: !isCompany
      ? yup.string()
      : yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    companyLegalDocument: !isCompany
      ? yup.string()
      : yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    // step: yup.string().required('Required4'),
    firstName: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    lastName: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    sex: yup.number().required('Required'),
    birthDate: yup
      .string()
      // Check is user older then 18 years
      .test('DOB', t('Validators.tooYoung'), (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      })
      .required('Required8'),
    citizenCountryId: yup.string().required(t('Validators.notEmptyField')),
    documentType: yup
      .string()
      .required(t('Validators.notEmptyField')),
    documentNumber: yup
      .string()
      .required(t('Validators.notEmptyField'))
      //Match only latin letters, symbols and digits
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    documentExpiredDate: yup
      .string()
      .test('DOB', t('Validators.tooShort'), (value) => {
        return moment().diff(moment(value), 'days') <= -181;
      })
      .required('Required'),
    registrationAddressCountryId: yup
      .string()
      .required(t('Validators.notEmptyField')),
    registrationAddressPostalCode: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressCity: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressStreet: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressHouse: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    isRegistrationAddressSameWithPostal: yup
      .boolean()
      .required(t('Validators.notEmptyField')),
    postalAddressCountryId: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          t('Validators.onlyLatin'),
        ),
    }),
    postalAddressPostalCode: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressCity: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressStreet: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressHouse: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    personalImage: yup.mixed().required(t('Validators.notEmptyField')),
    personalVerification1Type: yup
      .string()
      .required(t('Validators.notEmptyField')),
    personalVerification1: yup.mixed().required(t('Validators.notEmptyField')),
    personalVerification2: yup.mixed().required(t('Validators.notEmptyField')),
    addressVerification: yup.mixed().required(t('Validators.notEmptyField')),

  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState,
  } = useForm<PersonalDataTypeFormInterface>({
    defaultValues: {
      companyName: '',
      companyRegistrationNumber: '',
      companyLegalDocument: '',
      // step: '',
      documentType: '',
      firstName: '',
      lastName: '',
      sex: 0,
      birthDate: '',
      citizenCountryId: '',
      documentNumber: '',
      documentExpiredDate: '',
      registrationAddressCountryId: '',
      registrationAddressPostalCode: '',
      registrationAddressCity: '',
      registrationAddressStreet: '',
      registrationAddressHouse: '',
      isRegistrationAddressSameWithPostal: true,
      postalAddressCountryId: '',
      postalAddressPostalCode: '',
      postalAddressCity: '',
      postalAddressStreet: '',
      postalAddressHouse: '',
      personalImage: null,
      personalVerification1Type: '',
      personalVerification1: null,
      personalVerification2: null,
      addressVerification: null,
    },
    mode: 'onChange',
    resolver: yupResolver(uploadDocumentsScheme),
  });
  // useEffects to watch on form changes
   const { dirtyFields, touchedFields } = formState;
   const errors = formState.errors;
   console.log('dirtyFields', dirtyFields, 'touchedFields', touchedFields);
  const changeRadio = () => {
    setToggle((prev) => !prev);
    // createAsideMenu();
  };
  const { isShown, toggle } = useModal();
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [registration, setRegistration] = useState(false);

  const { data } = useGetCountryListQuery();
  interface Citizenship {
    value: string;
    label: string;
  }
  let citezenship: Citizenship[] = [];
  if (data) {
    citezenship = data?.map((country: any) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  }
  type Sex = {
    label: string;
    value: number;
  };

  //find country by name
  const findCountryByName = (name: string) => {
    return data?.find((country: any) => country.name === name);
  }
  //find country name by id
  const findCountryNameById = (id: string) => {
    return data?.find((country: any) => country.id === id)?.name;
  }

  const changeRegistration = () => {
    setRegistration((prev) => !prev);
    setValue('isRegistrationAddressSameWithPostal', registration);
  };

  const onSubmit = async (data: any) => {
    uploadDocumentsScheme.validate(data).then((res) => {
      console.log(res);
    })
    console.log(isCompany)
    console.log(data)
    const formDataPersonalImage = new FormData();
    formDataPersonalImage.append('file', data.personalImage);
    formDataPersonalImage.append('documentTypeId', 'd3d3cc70-8748-4f37-9970-83b482a7e9f7');

    const formDataPersonalVerification1 = new FormData();
    formDataPersonalVerification1.append('file', data.personalVerification1);
    formDataPersonalVerification1.append(
      'documentTypeId',
      data.personalVerification1Type,
    );

    const formDataPersonalVerification2 = new FormData();
    formDataPersonalVerification2.append('file', data.personalVerification2);
    formDataPersonalVerification2.append(
      'documentTypeId',
      data.documentType,
    );
    formDataPersonalVerification2.append(
      'documentData',
      JSON.stringify({
        documentNumber: data.documentNumber,
        documentExpiredDate: data.documentExpiredDate,
        firstName: data.firstName,
        lastName: data.lastName,
        sex:  data.sex,
        birthDate: data.birthDate,
        citizenCountry: findCountryNameById(data.citizenCountryId),
      })
    );

    const formDataAddressVerification = new FormData();
    formDataAddressVerification.append(
      'file',
      data.addressVerification,
    );
    formDataAddressVerification.append(
      'documentTypeId',
      '3aaee35c-3e96-44cc-aeb3-ac95b8beccf9',
    );
    formDataAddressVerification.append(
      'documentData',
      JSON.stringify({
        country: findCountryNameById(data.registrationAddressCountryId),
        postalCode: data.registrationAddressPostalCode,
        city: data.registrationAddressCity,
        street: data.registrationAddressStreet,
        house: data.registrationAddressHouse,
      })
    );

    //Here should be Backend API
    setLoading(true);

    // Send personalData to backend

    if (!isCompany) {
      // delete unnececery fields
      delete data.companyRegistrationNumber;
      delete data.companyLegalDocument;
      delete data.companyName;
    }
    if (!registration) {
      //delete unnececery fields from postal address
      delete data.postalAddressCountryId;
      delete data.postalAddressPostalCode;
      delete data.postalAddressCity;
      delete data.postalAddressStreet;
      delete data.postalAddressHouse;
    }
    if (!isCompany) {
      setLoading(true);
      postUser({ ...data })
        .unwrap()
        .then((data) => {
          setLoading(false);
          navigate('/candidates/upload-documents');
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(true);
      postCompany({ ...data })
        .unwrap()
        .then((data) => {
          setLoading(false);
          // navigate('/candidates/upload-documents');
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
    const [personalImage, personalVerification1, personalVerification2, addressVerification] = await Promise.all([
      axiosApi.post('/documents', formDataPersonalImage),
      axiosApi.post('/documents', formDataPersonalVerification1),
      axiosApi.post('/documents', formDataPersonalVerification2),
      axiosApi.post('/documents', formDataAddressVerification),
    ]);
    if (personalImage && personalVerification1 && personalVerification2 && addressVerification) {
      setLoading(false);
      console.log('success');
      setModalMessage('Документы успешно загружены');
      toggle();
      navigate('/candidates/awaiting-approval');
      // navigate('/registration-complete');
    } else {
      setLoading(false);
      console.log('error');
      setModalMessage('Произошла ошибка при загрузке документов, попробуйте еще раз');
      toggle();
    }
  };

  const onUploadImage = (name: keyof PersonalDataTypeFormInterface) => (file: File) => {
    setValue(name, file);
    setRerender((prevState) => !prevState);
  };

  const onSelectChange = (name: keyof PersonalDataTypeFormInterface, value: any)  => {
    setValue(name, value.value);
    setRerender((prevState) => !prevState);
  };

  const value = getValues();

  // useWatch to watch on form changes for fields:
  //       firstName: '',
  //       lastName: '',
  //       birthDate: '',
  //       citizenCountryId: '',
  //       documentNumber: '',
  //       documentExpiredDate: '',
  const firstName = useWatch({
    control,
    name: 'firstName',
  })
  const lastName = useWatch({
    control,
    name: 'lastName',
  })
  const birthDate = useWatch({
    control,
    name: 'birthDate',
  })
  const citizenCountryId = useWatch({
    control,
    name: 'citizenCountryId',
  })
  const documentNumber = useWatch({
    control,
    name: 'documentNumber',
  })
  const documentExpiredDate = useWatch({
    control,
    name: 'documentExpiredDate',
  })

  useEffect(() => {
    // if fields length > 0 then show upload form
      setShowIdentificationUploader(firstName.length > 0 && lastName.length > 0 && birthDate.length > 0 && citizenCountryId.length > 0 && documentNumber.length > 0 && documentExpiredDate.length > 0)
    }, [firstName, lastName, birthDate, citizenCountryId, documentNumber, documentExpiredDate])


  // watch on registration address with fields:
  //        registrationAddressCountryId: '',
  //       registrationAddressPostalCode: '',
  //       registrationAddressCity: '',
  //       registrationAddressStreet: '',
  //       registrationAddressHouse: '',

  const registrationAddressCountryId = useWatch({
    control,
    name: 'registrationAddressCountryId',
  })
  const registrationAddressPostalCode = useWatch({
    control,
    name: 'registrationAddressPostalCode',
  })
  const registrationAddressCity = useWatch({
    control,
    name: 'registrationAddressCity',
  })
  const registrationAddressStreet = useWatch({
    control,
    name: 'registrationAddressStreet',
  })
  const registrationAddressHouse = useWatch({
    control,
    name: 'registrationAddressHouse',
  })
  useEffect(() => {
    // if fields length > 0 then show upload form
    setShowRegistrationUploader(registrationAddressCountryId.length > 0 && registrationAddressPostalCode.length > 0 && registrationAddressCity.length > 0 && registrationAddressStreet.length > 0 && registrationAddressHouse.length > 0)
  })
  return (
    <>
        <ModalWindow
          isShown={isShown}
          hide={toggle}
          modalContent={modalMessage}
        />
        <Loader show={loading} message={'Загрузка...'}>
          <div className="section-upload-documents">
            <div className="section-upload-documents-information">
              { /* <BackButton className="back-button">Назад</BackButton> */ }
              <DefaultTitle>Загрузка документов</DefaultTitle>
            </div>
            <FormBase
              sections={[
                {
                  name: t('DocumentsUpload.sections.personalValidation'),
                  key: 'personal_validation',
                },
                {
                  name: t('DocumentsUpload.sections.personalIdentification'),
                  key: 'personal_identification',
                },
                {
                  name: t('DocumentsUpload.sections.addressVerification'),
                  key: 'address_verification',
                },
              ]}
            >
              <div className="main-launch-product__inner">
                <div className="section-launch-product-main">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="personal-inner">
                      <RadioButton
                        children={t('PersonalDataType.inputLabelType')}
                        onClick={changeRadio}
                        checked={!isCompany}
                        yesId="step-2-radiobutton3"
                        noId="step-2-radiobutton4"
                      />
                      {isCompany && (
                        <>
                          <h4
                            id="form-base-company"
                            className="personal__form-heading"
                          >
                            {t('PersonalDataType.inputTitleVariantNo')}
                          </h4>
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="companyName"
                              placeholder={t(
                                'PersonalDataType.inputLabelNameCompany',
                              )}
                              control={control}
                            />
                          </div>
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="companyRegistrationNumber"
                              placeholder={t(
                                'PersonalDataType.inputLabelRegistrationNumber',
                              )}
                              control={control}
                            />
                          </div>

                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="companyLegalDocument"
                              placeholder={t(
                                'PersonalDataType.inputLabelSignDocument',
                              )}
                              control={control}
                            />
                          </div>
                        </>
                      )}
                      <div><hr/></div>
                    </div>
                    <div className="form-general">
                      <section
                        id="form-base-personal_validation"
                        className="form-general__section"
                      >
                        <h4 className="form-general__section-title">
                          {t('DocumentsUpload.sections.personalValidation')}
                        </h4>
                        <div className="form-general__section__fields-field">
                          <div className="form-general__section__fields-field">
                            <p className="form-general__section__fields-field-label">
                              {t('DocumentsUpload.personalImage')}
                            </p>
                            <ImageUploader
                              formats={['jpg', 'jpeg', 'png']}
                              minSize={100}
                              onUpload={onUploadImage('personalImage')}
                            />
                            {errors.personalImage?.message && (
                              <p className="form-general__section__fields-field-error">
                                {t(errors.personalImage.message)}
                              </p>
                            )}
                          </div>
                          <div><hr /></div>
                          <div className="form-general__section__fields-field">
                            <p className="form-general__section__fields-field-label">
                              {t('DocumentsUpload.photoWithDocument')}
                            </p>
                            <Select<DocumentSelect>
                              placeholder={'Выберите тип документа'}
                              isSearchable={false}
                              options={IDENTIFICATIONS.map((item:{label: string, value: string}) => ({
                                label: t(item.label),
                                value: item.value,
                              }) as DocumentSelect)}
                              onChange={selectedOption =>onSelectChange(
                                'personalVerification1Type', selectedOption,
                              )}
                              // value={value.personal_verification_1_type}
                            />
                            {(errors.personalVerification1Type?.message ||
                              errors.personalVerification1Type?.message) && (
                              <p className="form-general__section__fields-field-error">
                                {t(
                                  errors.personalVerification1Type?.message ||
                                  errors.personalVerification1Type?.message ||
                                  '',
                                )}
                              </p>
                            )}
                            <ImageUploader
                              formats={['jpg', 'jpeg', 'png']}
                              minSize={100}
                              onUpload={onUploadImage('personalVerification1')}
                              // disabled={!Boolean(value.personalImage)}
                            />
                            {(errors.personalVerification1?.message ||
                              errors.personalVerification1?.message) && (
                              <p className="form-general__section__fields-field-error">
                                {t(
                                  errors.personalVerification1?.message ||
                                    errors.personalVerification1Type?.message ||
                                    '',
                                )}
                              </p>
                            )}
                          </div>
                          <div><hr /></div>
                        </div>
                      </section>
                      <section
                        id="form-base-personal_identification"
                        className="form-general__section"
                      >
                        <h4 className="form-general__section-title">
                          {t('DocumentsUpload.sections.personalIdentification')}
                        </h4>
                        <div className="form-general__section__fields-field">
                          <div className="form-general__section__fields-field">
                            <p className="form-general__section__fields-field-label">
                              {t('DocumentsUpload.photoOfDocument')}
                            </p>
                            <div className="form-general__section__fields-field">
                              <div className="personal__type-inner">
                                <div className="personal__wrapper-fields">
                                  <div className="personal-input__wrapper">
                                    <Select<DocumentSelect>
                                      isSearchable={false}
                                      options={PERSONAL_IDENTIFICATIONS.map((item:{label: string, value: string}) => ({
                                        label: t(item.label),
                                        value: item.value,
                                      }) as DocumentSelect)}
                                      placeholder={'Тип документа'}
                                      onChange={selectedOption => onSelectChange(
                                        'documentType', selectedOption,
                                      )}
                                      // value={value.personal_verification_2_type}
                                    />
                                    {(errors.documentType?.message ||
                                      errors.documentType?.message) && (
                                      <p className="form-general__section__fields-field-error">
                                        {t(
                                          errors.documentType?.message ||
                                          errors.documentType?.message ||
                                          '',
                                        )}
                                      </p>
                                    )}
                                  </div>
                                  <div className="personal-input__wrapper">
                                    <Input
                                      className="input input-default"
                                      name="lastName"
                                      placeholder={t(
                                        'PersonalPassportData.inputLabelSurname',
                                      )}
                                      control={control}
                                    />
                                  </div>

                                  <div className="personal-input__wrapper">
                                    <Input
                                      className="input input-default"
                                      name="firstName"
                                      placeholder={t('PersonalPassportData.inputLabelName')}
                                      control={control}
                                    />
                                  </div>
                                </div>

                                <div className="personal__wrapper-fields">
                                  <div className="personal-input__wrapper">
                                    <Select<Sex>
                                      options={SEX}
                                      placeholder={t('PersonalPassportData.inputLabelSex')}
                                      onChange={(selectedOption) =>
                                        onSelectChange('sex', selectedOption)
                                      }
                                      //value={value.sex}
                                    />
                                    {(errors.sex?.message ||
                                      errors.sex?.message) && (
                                      <p className="form-general__section__fields-field-error">
                                        {t(
                                          errors.sex?.message ||
                                          errors.sex?.message ||
                                          '',
                                        )}
                                      </p>
                                    )}
                                  </div>

                                  <div className="personal-input__wrapper">
                                    <Input
                                      type="text"
                                      className="input input-default"
                                      name="birthDate"
                                      placeholder={t(
                                        'PersonalPassportData.inputLabelBirthday',
                                      )}
                                      control={control}
                                      setfocustype="date"
                                    />
                                  </div>
                                </div>

                                <div className="personal-input__wrapper">
                                  <Select<CountryModel>
                                    options={citezenship}
                                    placeholder={t(
                                      'PersonalPassportData.inputLabelCitizenship',
                                    )}
                                    onChange={(selectedOption) =>
                                      onSelectChange('citizenCountryId', selectedOption)
                                    }
                                    //onChange={onSelectChange('citizenship')}
                                    // value={value.citizenship.toString()}
                                  />
                                </div>
                                {(errors.citizenCountryId?.message ||
                                  errors.citizenCountryId?.message) && (
                                  <p className="form-general__section__fields-field-error">
                                    {t(
                                      errors.citizenCountryId?.message ||
                                      errors.citizenCountryId?.message ||
                                      '',
                                    )}
                                  </p>
                                )}

                                <div className="personal__wrapper-fields">
                                  <div className="personal-input__wrapper">
                                    <Input
                                      className="input input-default"
                                      name="documentNumber"
                                      placeholder={t(
                                        'PersonalPassportData.inputLabelDocumentNumber',
                                      )}
                                      control={control}
                                    />
                                  </div>

                                  <div className="personal-input__wrapper">
                                    <Input
                                      type="text"
                                      setfocustype="date"
                                      className="input input-default"
                                      name="documentExpiredDate"
                                      placeholder={t(
                                        'PersonalPassportData.inputLabelValidityPeriod',
                                      )}
                                      control={control}
                                    />
                                  </div>
                                </div>
                              </div>
                              {showIdentificationUploader && <ImageUploader
                                formats={['jpg', 'jpeg', 'png']}
                                minSize={100}
                                onUpload={onUploadImage('personalVerification2')}
                                //disabled={!Boolean(value.personalVerification2)}
                              />}
                              {(errors.personalVerification2?.message) && (
                                <p className="form-general__section__fields-field-error">
                                  {t(
                                    errors.personalVerification2?.message || '',
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div><hr /></div>
                      </section>
                      <section
                        id="form-base-address_verification"
                        className="form-general__section"
                      >
                        <h4 className="form-general__section-title">
                          {t('DocumentsUpload.sections.addressVerification')}
                        </h4>
                        <div className="personal__type-inner">
                          <div className="personal__wrapper-fields">
                            <p className="form-general__section__fields-field-label">
                              {t('DocumentsUpload.address')}
                            </p>
                          </div>
                          <div className="personal-input__wrapper">
                            <Select<CountryModel>
                              options={citezenship}
                              placeholder={t('PersonalAddress.inputLabelCountry')}
                              onChange={(selectedOption) =>
                                onSelectChange(
                                  'registrationAddressCountryId',
                                  selectedOption,
                                )
                              }
                              // onChange={onSelectChange('country')}
                              // value={value.country.toString()}
                            />
                          </div>
                          {(errors.registrationAddressCountryId?.message ||
                            errors.registrationAddressCountryId?.message) && (
                            <p className="form-general__section__fields-field-error">
                              {t(
                                errors.registrationAddressCountryId?.message ||
                                errors.registrationAddressCountryId?.message ||
                                '',
                              )}
                            </p>
                          )}
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="registrationAddressPostalCode"
                              placeholder={t('PersonalAddress.inputLabelIndex')}
                              control={control}
                            />
                          </div>
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="registrationAddressCity"
                              placeholder={t('PersonalAddress.inputLabelCity')}
                              control={control}
                            />
                          </div>
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="registrationAddressStreet"
                              placeholder={t('PersonalAddress.inputLabelStreet')}
                              control={control}
                            />
                          </div>
                          <div className="personal-input__wrapper">
                            <Input
                              className="input input-default"
                              name="registrationAddressHouse"
                              placeholder={t('PersonalAddress.inputLabelHouse')}
                              control={control}
                            />
                          </div>
                        </div>
                        <div className="personal__checkbox-wrapper">
                          <CheckCard
                            value={registration}
                            selected={registration}
                            onClick={changeRegistration}
                            type="large"
                            additionalHeaderTitle={t(
                              'PersonalAddress.inputSwitcherDescr',
                            )}
                          >
                            {' '}
                          </CheckCard>
                        </div>

                        {registration && (
                          <div className="personal__type-inner">
                            <h2
                              id="form-base-registration_address_2"
                              className="form-general__section personal__form-heading"
                            >
                              {t('PersonalAddress.title')}
                            </h2>
                            <div className="personal-input__wrapper">
                              <Select<CountryModel>
                                options={citezenship}
                                placeholder={t('PersonalAddress.inputLabelCountry')}
                                onChange={(selectedOption) =>
                                  onSelectChange(
                                    'postalAddressCountryId',
                                    selectedOption,
                                  )
                                }
                                //onChange={onSelectChange('citizenship')}
                                // value={value.citizenship.toString()}
                              />
                            </div>
                            <div className="personal-input__wrapper">
                              <Input
                                className="input input-default"
                                name="postalAddressPostalCode"
                                placeholder={t('PersonalAddress.inputLabelIndex')}
                                control={control}
                              />
                            </div>
                            <div className="personal-input__wrapper">
                              <Input
                                className="input input-default"
                                name="postalAddressCity"
                                placeholder={t('PersonalAddress.inputLabelCity')}
                                control={control}
                              />
                            </div>
                            <div className="personal-input__wrapper">
                              <Input
                                className="input input-default"
                                name="postalAddressStreet"
                                placeholder={t('PersonalAddress.inputLabelStreet')}
                                control={control}
                              />
                            </div>
                            <div className="personal-input__wrapper">
                              <Input
                                className="input input-default"
                                name="postalAddressHouse"
                                placeholder={t('PersonalAddress.inputLabelHouse')}
                                control={control}
                              />
                            </div>
                          </div>
                        )}
                        {showRegistrationUploader && <div className="form-general__section__fields-field">
                          <div className="form-general__section__fields-field">

                            <ImageUploader
                              formats={['jpg', 'jpeg', 'png']}
                              minSize={100}
                              onUpload={onUploadImage('addressVerification')}
                              // disabled={!Boolean(value.addressVerification)}
                            />
                            {errors.addressVerification?.message && (
                              <p className="form-general__section__fields-field-error">
                                {t(errors.addressVerification.message)}
                              </p>
                            )}
                          </div>
                        </div> }
                      </section>
                    </div>
                    <div className="form-general-actions">
                      <Button type="submit">{t('Button.submit')}</Button>
                    </div>
                  </form>
                </div>
              </div>
              <aside className="section-launch-product-right">
                <div className="section-launch-product-right__section">
                  <p className="section-launch-product-right__section-heading">
                    For partners
                  </p>
                </div>
                <div className="section-launch-product-right__section-content">
                  <div className="section-launch-product-right__section-content-text">
                    <p>
                      Минимальная сумма перевода на карту составляет 100 у.е. В целях
                      безопасности для транзакций свыше 500 у.е. вам будет
                      дополнительно выслано смс-уведомление. Максимальная сумма всех
                      переводов на карту за день составляет 1 000 у.е. Максимальная
                      сумма переводов за месяц - 8 000 у.е. Обработка зачислений
                      выполняется в рабочие дни. Переводы по заявкам, отправленным до
                      17:00 (по МСК), поступают на карту в первой половине следующего
                      дня. Комиссия составляет 2.5% от суммы (минимум 3.95 у.е.).
                    </p>
                  </div>
                </div>
              </aside>
            </FormBase>
          </div>
        </Loader>
    </>
  );
}

export default UploadDocumentsForm;
