import React from 'react';

interface Props {
  isActive?: boolean;
}
export const CardIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="50"
      height="37"
      viewBox="0 0 50 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.914 6.40625H5.68524C2.56236 6.40625 0 8.92857 0 12.0915V30.7887C0 33.9116 2.56236 36.4739 5.68524 36.4739H36.874C39.9968 36.4739 42.5592 33.9116 42.5592 30.7887V12.0915C42.5992 8.92857 40.0369 6.40625 36.914 6.40625ZM39.8367 30.7887C39.8367 32.3902 38.5155 33.7114 36.914 33.7114H5.68524C4.08376 33.7114 2.76254 32.3902 2.76254 30.7887V18.3773H39.7966V30.7887H39.8367ZM39.8367 12.7721H2.76254V12.0915C2.76254 10.49 4.08376 9.16879 5.68524 9.16879H36.874C38.4754 9.16879 39.7966 10.49 39.7966 12.0915L39.8367 12.7721Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M44.2411 0H13.0524C9.92955 0 7.36719 2.56236 7.36719 5.68524V24.4225C7.36719 27.5454 9.92955 30.1077 13.0524 30.1077H44.2411C47.364 30.1077 49.9264 27.5454 49.9264 24.4225V5.68524C49.9264 2.56236 47.4041 0 44.2411 0ZM47.1638 24.4225C47.1638 26.024 45.8426 27.3452 44.2411 27.3452H13.0524C11.4509 27.3452 10.1297 26.024 10.1297 24.4225V11.971H47.1638V24.4225ZM47.1638 6.4059H10.1297V5.72527C10.1297 4.1238 11.4509 2.80258 13.0524 2.80258H44.2411C45.8426 2.80258 47.1638 4.1238 47.1638 5.72527V6.4059Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M47.164 5.6853V6.40596H10.1299V5.72534C10.1299 4.12386 11.4511 2.80264 13.0526 2.80264H44.2413C45.8428 2.76261 47.164 4.08382 47.164 5.6853Z"
        fill={!isActive ? 'white' : '#D4A38'}
      />
      <path
        d="M10.1299 11.9707V24.4222C10.1299 26.0236 11.4511 27.3449 13.0526 27.3449H44.2413C45.8428 27.3449 47.164 26.0236 47.164 24.4222V11.9707H10.1299ZM13.4529 15.8142C13.6932 15.574 14.0535 15.4139 14.4539 15.4139H21.5004C22.2611 15.4139 22.9016 16.0545 22.9016 16.8152C22.9016 17.2155 22.7415 17.5358 22.5013 17.7761C22.2611 18.0163 21.9007 18.1764 21.5004 18.1764H14.4539C13.6932 18.1764 13.0526 17.5358 13.0526 16.7751C13.0526 16.4148 13.2127 16.0945 13.4529 15.8142ZM25.6242 22.9008C25.3839 23.141 25.0236 23.3011 24.6232 23.3011H14.4539C13.6932 23.3011 13.0526 22.6605 13.0526 21.8998C13.0526 21.5395 13.2127 21.1792 13.4529 20.939C13.6932 20.6987 14.0535 20.5386 14.4539 20.5386H24.6232C25.3839 20.5386 26.0245 21.1792 26.0245 21.9399C26.0245 22.3002 25.8644 22.6205 25.6242 22.9008ZM39.9974 23.9017C39.3168 23.9017 38.6361 23.7415 38.0356 23.4613C37.435 23.7415 36.7544 23.9017 36.0738 23.9017C33.5915 23.9017 31.5496 21.8598 31.5496 19.3775C31.5496 16.8952 33.5915 14.8133 36.1138 14.8133C36.7944 14.8133 37.4751 14.9735 38.0756 15.2537C38.6762 14.9735 39.3568 14.8133 40.0374 14.8133C42.5197 14.8133 44.5616 16.8552 44.5616 19.3375C44.5616 21.8198 42.5197 23.9017 39.9974 23.9017Z"
        fill={!isActive ? 'white' : '#D4A387'}
      />
      <path
        d="M39.9971 14.8135C39.3165 14.8135 38.6358 14.9736 38.0353 15.2539C37.4347 14.9736 36.7541 14.8135 36.0735 14.8135C33.5912 14.8135 31.5493 16.8554 31.5493 19.3376C31.5493 21.8199 33.5912 23.8618 36.0735 23.8618C36.7541 23.8618 37.4347 23.7017 38.0353 23.4214C38.6358 23.7017 39.3165 23.8618 39.9971 23.8618C42.4794 23.8618 44.5213 21.8199 44.5213 19.3376C44.5213 16.8554 42.5194 14.8135 39.9971 14.8135Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M22.8608 16.8154C22.8608 17.2157 22.7006 17.536 22.4604 17.7762C22.2202 18.0165 21.8599 18.1766 21.4595 18.1766H14.453C13.6923 18.1766 13.0518 17.536 13.0518 16.7753C13.0518 16.3749 13.2119 16.0547 13.4521 15.7744C13.6923 15.5342 14.0527 15.374 14.453 15.374H21.4995C22.2602 15.4141 22.8608 16.0546 22.8608 16.8154Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M26.0237 21.9003C26.0237 22.3007 25.8636 22.621 25.6233 22.8612C25.3831 23.1014 25.0228 23.2616 24.6224 23.2616H14.453C13.6923 23.2616 13.0518 22.621 13.0518 21.8603C13.0518 21.4999 13.2119 21.1396 13.4521 20.8994C13.6923 20.6592 14.0527 20.499 14.453 20.499H24.6224C25.3831 20.499 26.0237 21.1396 26.0237 21.9003Z"
        fill={!isActive ? 'black' : 'white'}
      />
    </svg>
  );
};
