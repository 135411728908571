import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import PersonalDataForm from '../../components/PersonalDataForm/PersonalDataComponent';

function PersonalData() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <PersonalDataForm />
      </AnimationWrapper>
    </section>
  );
}

export default PersonalData;
