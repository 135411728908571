import React from 'react';

interface Props {
  isActive?: boolean;
}
export const NeedTourIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="54"
      height="32"
      viewBox="0 0 54 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_221)">
        <path
          d="M49.8621 0C45.7508 5.00021 40.0839 5.77802 35.4726 8.13923C30.8613 10.5004 28.389 13.7783 28.389 13.7783C32.667 10.806 40.0006 8.88926 44.9453 5.97247C48.7232 3.75016 49.8621 0 49.8621 0Z"
          fill={!isActive ? '#010202' : 'white'}
        />
        <path
          d="M8.63451 5.97247C13.5792 8.88926 20.9128 10.7782 25.1908 13.7783C25.1908 13.7783 22.7462 10.5004 18.1071 8.13923C13.4958 5.77802 7.82892 5.00021 3.71764 0C3.71764 0 4.85657 3.75016 8.63451 5.97247Z"
          fill={!isActive ? '#010202' : 'white'}
        />
        <path
          d="M6.49809 16.8065C6.49809 16.8065 6.35919 20.5566 12.8039 25.4179C12.8039 25.4179 17.5263 31.5849 26.4711 31.8627C26.4711 31.8627 19.6931 29.7515 15.6374 25.6957C15.6374 25.6957 17.8597 26.168 20.4987 26.2235C20.4987 26.2235 11.5816 22.8067 8.83152 18.2232C8.83152 18.2232 12.0817 20.1955 18.5819 20.14C18.5819 20.14 8.85929 16.9176 4.83135 10.834C4.83135 10.834 8.74818 13.0563 12.3317 14.0564C15.8874 15.0564 25.3878 18.3065 26.7767 23.779C28.1657 18.2788 37.666 15.0564 41.2218 14.0564C44.7775 13.0563 48.7221 10.834 48.7221 10.834C44.6941 16.8898 34.9715 20.14 34.9715 20.14C41.4718 20.1955 44.7219 18.2232 44.7219 18.2232C41.9996 22.8067 33.0547 26.2235 33.0547 26.2235C35.6937 26.168 37.9161 25.6957 37.9161 25.6957C33.8603 29.7515 27.0823 31.8627 27.0823 31.8627C36.0271 31.5849 40.7495 25.4179 40.7495 25.4179C47.1942 20.5566 47.0553 16.8065 47.0553 16.8065C55.3334 9.25061 53.3611 4.97266 53.3611 4.97266C48.5832 9.30617 37.5272 12.1952 33.1936 13.7786C28.8601 15.362 26.7767 17.1954 26.7767 17.1954C26.7767 17.1954 24.7211 15.362 20.3876 13.7786C16.054 12.1952 4.97024 9.30617 0.192268 4.97266C0.192268 4.97266 -1.78004 9.25061 6.49809 16.8065Z"
          fill={!isActive ? '#010202' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_221">
          <rect width="53.5856" height="31.8902" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
