import React from 'react';
import './assets/scss/main.scss';
import { useAppSelector } from './hooks/redux';
import Routing from './routes';

function App() {
  const userToken = useAppSelector((state) => state.userToken.token);
  return <Routing userToken={userToken} />;
}

export default App;


