import { TariffCardProps } from './TariffCard.props';
import { useTranslation } from 'react-i18next';

function TariffCard({ from, to, information, rate, special }: TariffCardProps) {
  const { t } = useTranslation();
  return (
    <div className={`tariff-card ${special ? 'special' : ''}`}>
      <div className="tariff-card__header">
        <div className="tariff-card__main">
          <p className="tariff-card__main-info">
            <span className="tariff-card__main-info__title">
              {t('SelectPrograms.from')}
            </span>
            <span className="tariff-card__main-info__value">{from}</span>
          </p>
          <span className="tariff-card__main-info__separator">—</span>
          <p className="tariff-card__main-info">
            <span className="tariff-card__main-info__title">
              {t('SelectPrograms.to')}
            </span>
            <span className="tariff-card__main-info__value">{to}</span>
          </p>
        </div>
        {special && (
          <span className="tariff-card__header-special">
            {t('SelectPrograms.special')}
          </span>
        )}
      </div>
      <div className="tariff-card__body">
        <p className="tariff-card__body-information">{information}</p>
        <p className="tariff-card__body-rate">{rate}</p>
      </div>
    </div>
  );
}

export default TariffCard;
