import React from 'react';

interface Props {
  isActive?: boolean;
}
export const InsuranceIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_2225)">
        <path
          d="M10.5234 30.7126C10.5234 32.8727 12.2682 34.6451 14.456 34.6451C16.6438 34.6451 18.3885 32.9004 18.3885 30.7126V30.5187V13.9023"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.02393 17.0873C1.77166 8.14211 9.27672 0.99707 18.4157 0.99707C27.6101 0.99707 35.1429 8.08672 35.8352 17.1149"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.4165 0.99707C18.4165 0.99707 11.5484 2.43716 11.4653 17.0873"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.3887 0.99707C18.3887 0.99707 25.4783 2.46485 25.5614 17.1149"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.02393 17.0871C2.10399 15.3978 4.01487 14.29 6.20269 14.29C8.36282 14.29 10.3568 15.4255 11.4368 17.0871"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.5605 17.1148C26.6129 15.4255 28.69 14.29 30.8224 14.29C32.9271 14.29 34.7826 15.4532 35.835 17.1148"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4653 17.0874C12.9054 15.038 15.6748 13.6533 18.5827 13.6533C21.4628 13.6533 24.1215 15.0657 25.5616 17.1151"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.03"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.4864 1.7998L6.64594 5.59387L0.85791 15.9237L7.17213 14.6498L10.8554 16.8099L14.9264 2.13213L13.4864 1.7998Z"
          fill={!isActive ? 'black' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_2225">
          <rect width="36.8329" height="35.6421" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
