import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import Input from '../../Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { PersonalDeliveryAddress } from '../../../interfaces/personalSettings.interface';
import { Checkbox } from '../../Checkbox/Checkbox';
import Select from '../../Select/Select';
import * as yup from 'yup';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

const countries = [
  { label: 'Ukraine', value: 'ukraine' },
  { label: 'Republic', value: 'chech-republic' },
];

export const DeliveryAddressForm = () => {
  const DeliverAddressSchema = yup.object({
    city: yup.string().required('Required'),
    country: yup.string().required('Required'),
    address: yup.string().required('Required'),
    postalCode: yup.string().required('Required'),
  });

  const { handleSubmit, control, setValue } = useForm<PersonalDeliveryAddress>({
    defaultValues: {
      city: '',
      country: '',
      address: '',
      postalCode: '',
    },
    resolver: yupResolver(DeliverAddressSchema),
    mode: 'onChange',
  });

  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  const onChangePolicyAgreement = () => {
    setIsPolicyAccepted((prevState) => !prevState);
  };

  const onSubmit = (data: PersonalDeliveryAddress) => {
    console.log({ data });
  };

  const onChangeCountry = (key: string) => {
    setValue('country', key);
  };

  const { field, fieldState } = useController({
    name: 'country',
    control,
    rules: { required: true },
    defaultValue: '',
  });

  console.log({ fieldState });
  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-launch-product-main__form section-launch-settings-main__form"
            >
              <div className="section-launch-product-main__form-section first-section">
                <div className="section-launch-product-main__form-section-heading">
                  Страна
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <Select
                    onChange={onChangeCountry}
                    options={countries}
                    placeholder={'Страна'}
                  />
                  {fieldState.error?.message && (
                    <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
                  )}
                </div>
              </div>{' '}
              <div className="section-launch-product-main__form-section first-section">
                <div className="section-launch-product-main__form-section-heading">
                  Город
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <Input
                    className="input input-default"
                    name="city"
                    placeholder={'City'}
                    control={control}
                  />
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Адресс
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <Input
                    className="input input-default"
                    name="address"
                    placeholder={'Address'}
                    control={control}
                  />
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-heading">
                  Почтовый индекс
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <Input
                    className="input input-default"
                    name="postalCode"
                    placeholder={'postalCode'}
                    control={control}
                  />
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-content">
                  <ul className="section-launch-product-main__form-btn-list">
                    <li className="section-launch-product-main__form-btn-list-item">
                      <Checkbox
                        value={isPolicyAccepted}
                        onChange={onChangePolicyAgreement}
                      />
                      <label>
                        <span>
                          Понимаю, что выбор надежности пароля является моей
                          персональной ответственностью
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="section-launch-product-main__form-submit-container"
                style={{ marginTop: 0 }}
              >
                <button
                  type="submit"
                  className="btn btn--white"
                  style={{ width: '200px' }}
                >
                  Подтвердить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Адрес доставки
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                Несмотря на технологический прогресс и существование клубной
                корпоративной почты, обыкновенная почтовая корреспонденция по
                сей день остается одним из основных способов связи, особенно в
                случае отправки чрезвычайно важных уведомлений. Так как
                юридический адрес или регистрация зачастую не совпадают с местом
                фактического проживания члена Клуба, получение корреспонденции
                может быть затруднено. Вы можете отредактировать и изменить
                адрес проживания в любой момент. Он будет по умолчанию
                использоваться для доставки корреспонденции, а также товаров,
                которые можно заказать у партнеров Клуба.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
