import React from 'react';
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import FormBase from '../../FormBase/FormBase';
import { Header } from '../Header/Header';
import { useTranslation } from 'react-i18next';

export const GoldenProgramInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onSectionChange = (key: string) => {
    navigate(`/goldenShield/balance/${key}`);
  };

  const sections = [
    {
      name: 'Programs.balance',
      key: 'balance',
      items: [
        { name: 'Programs.generalStatement', key: 'general-statement' },
        { name: 'Programs.accountStatement', key: 'account-statement' },
      ],
    },
  ];

  const activeSubTab = location.pathname.split('/')[3];

  if (!activeSubTab)
    return <Navigate to={'/goldenShield/balance/general-statement'} />;

  return (
    <div className="section-launch-product">
      <Header name={t('Programs.myContracts')} type="golden" />
      <FormBase
        sections={sections}
        onSectionChange={onSectionChange}
        activeSection="balance"
        activeSubSection={activeSubTab}
        isDropdown
      >
        <Outlet />
      </FormBase>
    </div>
  );
};
