import React from 'react';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import ChoseProgramDetailInformation from '../../components/ChoseProgramDetailInformation/ChoseProgramDetailInformation';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';

function ChoseProgram() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <section className="section-cabinet">
        <AnimationWrapper className="container">
          <ChoseProgramDetailInformation />
        </AnimationWrapper>
      </section>
    </>
  );
}

export default ChoseProgram;
