import React, { useRef, useState } from 'react';
import { VerticalDotsIcon } from '../../assets/media';
import { DownArrowIcon } from '../../assets/media/icons/downArrow';
import { CircleChart } from '../../components/CircleChart/CircleChart';
import { useClickOutside } from '../../hooks';
import { addSpaceToNumber } from '../../helpers';
import { useTranslation } from 'react-i18next';
import axiosApi from "../../helpers/axios.utils";
import CurrencyFormat from 'react-currency-format';
import { CURRENCIES } from "../../components/InvoiceCards/CurrenciesList/CurrenciesList.model";
interface Props {
  name: string;
  currency: string;
  maxValue: number;
  wallets: { accountStatus: number;
    accountTypeId: string;
    activationDate: string;
    contractId: string;
    currencyId: string;
    id: string;
    number: string;
    personId: string;
    balance: number;
  }[] | [];
}
export const AccountSavings = ({
  name,
  currency,
  maxValue,
  wallets,
}: Props) => {

  const { t } = useTranslation();
  const [isCollapsed, toggleIsCollapsed] = useState(true);
  const [isOpenDownloadMenu, toggleIsOpenDownloadMenu] = useState(false);
  const componentRef = useRef<HTMLUListElement>(null);
  const [accountTypes, setAccountTypes] = React.useState<any>([]);
  const currencyFormatter = require('currency-formatter');

  const accountType = async () => {
    // get account type from server by id
    const accountType = await axiosApi.get(`account_types`);
    setAccountTypes(accountType.data);
  }
  const getCurrencyLabel = (currencyId: string) => {
    const currency = CURRENCIES.find((currency) => currency.id === currencyId);
    return currency ? currency.label : '';
  }
  React.useEffect(() => {
    accountType();
  }, []);

  const onToggleCollapse = () => {
    toggleIsCollapsed((prevState) => !prevState);
  };

  const onOpenDownloadMenu = () => {
    toggleIsOpenDownloadMenu((prevState) => !prevState);
  };

  // function for rounding balance to 2 decimal places
  const roundBalance = (balance: number) => {
    return Math.round(balance * 100) / 100;
  }

  // calculate balance of contract  from all accounts with array.forEach
  const balance = (wallets as any[]).reduce((acc: number, wallet: any) => {
    return acc + roundBalance(wallet.balance);
  }
  , 0);

  useClickOutside(componentRef, onOpenDownloadMenu, isOpenDownloadMenu);

  return (
    <div className="savings-account-preview">
      <div className="savings-account-preview-header">
        <div className="savings-account-preview-header__info">
          <div className="savings-account-preview-header__balance">
            <span>{name}: </span>
            <span>
              <CurrencyFormat
                value={maxValue}
                displayType={'text'}
                thousandSeparator={true}
                prefix={''}
                suffix={' ' + getCurrencyLabel(currency)} />
            </span>
          </div>
          <div className="savings-account-preview-header__goal">
            <span className="subheader">{t('Programs.assetsSum')}: </span>
            <span>
              <CurrencyFormat
                value={roundBalance(balance)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={''}
                suffix={' ' + getCurrencyLabel(currency)} />
            </span>
          </div>
        </div>
        <div className="savings-account-preview-header__primary">
          {maxValue > 0 && (<div className="savings-account-preview-header__progress">
            <CircleChart percent={Math.round((balance/maxValue)*100)} mainColor={'red'} />
          </div>)}
          <div className="savings-account-preview-header__primary--actions">
            <span onClick={onToggleCollapse}>
              <DownArrowIcon isActive={!isCollapsed} />
            </span>
            <span onClick={onOpenDownloadMenu}>
              <VerticalDotsIcon />
            </span>
            <ul
              className={`savings-account-preview-header__primary--download-menu ${
                isOpenDownloadMenu ? 'is-open' : ''
              }`}
              ref={componentRef}
            >
              <li className="savings-account-preview-header__primary--download-menu-item">
                {t('Programs.generalStatement')}
              </li>{' '}
              <li className="savings-account-preview-header__primary--download-menu-item">
                {t('Programs.extractionFor3Month')}
              </li>{' '}
              <li className="savings-account-preview-header__primary--download-menu-item">
                {t('Programs.extractionFor6Month')}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`savings-account-preview-content ${
          !isCollapsed ? 'is-open' : ''
        }`}
      >
        {wallets.map(({ id, number, accountTypeId, balance }) => (
          <div className="savings-account-preview-content__block" key={id}>
            <p className="savings-account-preview-content__block-item">
              {accountTypes.find((accountType: any) => accountType.id === accountTypeId)?.title}
            </p>
            <p className="savings-account-preview-content__block-item">
              <span>
                <CurrencyFormat
                  value={roundBalance(balance)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                  suffix={' ' + getCurrencyLabel(currency)} />
            </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
