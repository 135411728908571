import { ButtonProps } from './Button.props';

export function Button({
  children,
  disabled,
  onClick,
  transparent,
  classNames,
  type,
}: ButtonProps) {
  return (
    <button
      className={`btn btn--black ${
        transparent ? 'btn--transparent' : ''
      } ${classNames}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
