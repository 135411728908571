import i18next from 'i18next';

export const IDENTIFICATIONS = [
  {
    label: 'DocumentsUpload.options.photoWithInternationalPassport',
    value: '40545a45-6168-4684-b9fe-e68fddd468ea',
  },
  {
    label: 'DocumentsUpload.options.photoWithIdCard',
    value: '946f9f2d-4155-4bc1-9cd7-4e97b9c44707',
  },
  {
    label: 'DocumentsUpload.options.photoWithInternalPassport',
    value: '08bf0f88-0f1d-4cbd-bfff-b713863d153d',
  },
];

export const PERSONAL_IDENTIFICATIONS = [
  {
    label: i18next.t('DocumentsUpload.options.internationalPassport'),
    value: '430cc227-a35d-4021-ac54-8d6e1e49f794',
  },
  {
    label: i18next.t('DocumentsUpload.options.internalPassport'),
    value: '10385541-50de-4a23-b150-96f7138a314a',
  },
  {
    label: i18next.t('DocumentsUpload.options.idCard'),
    value: 'fbe5bb47-9121-4765-88ac-ec81786baa75',
  },
  {
    label: i18next.t('DocumentsUpload.options.certifiedDocument'),
    value: '61cb3878-3604-41f6-bdac-1c6539eed79c',
  },
];

export const SEX = [
  {
    label: 'Мужской',
    value: 1,
  },
  {
    label: 'Женский',
    value: 0,
  },
];

export const COUNTRIES = [
  {
    label: 'Украина',
    value: 'ukraine',
  },
  {
    label: 'Польша',
    value: 'poland',
  },
  {
    label: 'Австрия',
    value: 'austria',
  },
];

export const CITIZENSHIP = [
  {
    label: 'Украина',
    value: 'ukraine',
  },
  {
    label: 'Польша',
    value: 'poland',
  },
  {
    label: 'Австрия',
    value: 'austria',
  },
];
