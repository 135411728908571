import axios from 'axios';
import Cookies from 'universal-cookie';

const axiosApi = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  baseURL: 'https://7api.profitcode.by/api/',
  headers: {
    Accept: 'application/json',
  },
});

axiosApi.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const isUserLoggedIn = cookies.get('logged_in');
    if (!config?.headers) {
      throw new Error(
        `Expected 'config' and 'config.headers' not to be undefined`,
      );
    }
    if (isUserLoggedIn) {
      config.headers.Authorization = `Bearer ${isUserLoggedIn.token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

/* axiosApi.interceptors.request.use((config: AxiosRequestConfig) => {
  if (isUserLoggedIn) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${isUserLoggedIn.token}`;
    }
  }
  return config;
}); */

export default axiosApi;
