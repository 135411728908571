import React from 'react';

interface Props {
  theme?: 'black' | 'white';
}
export const UsdIcon = ({ theme = 'white' }: Props) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2822 10.5996V12.4976C10.4082 12.4826 10.5322 12.4545 10.6522 12.4136C10.7771 12.3755 10.8948 12.3167 11.0002 12.2396C11.1029 12.1601 11.1886 12.0608 11.2522 11.9476C11.3203 11.8219 11.3541 11.6805 11.3502 11.5376C11.3579 11.4291 11.34 11.3202 11.298 11.2198C11.2559 11.1195 11.1909 11.0303 11.1082 10.9596C10.8581 10.7885 10.5778 10.6664 10.2822 10.5996Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
      <path
        d="M9.08631 7.62583C9.00145 7.69525 8.93313 7.7827 8.88631 7.88183C8.83014 7.99426 8.80071 8.11815 8.80031 8.24383C8.79387 8.33744 8.8085 8.43131 8.84311 8.51853C8.87772 8.60574 8.93143 8.6841 9.00031 8.74783C9.20248 8.8946 9.43592 8.99249 9.68231 9.03383V7.42383C9.57173 7.42386 9.46181 7.44072 9.35631 7.47383C9.25783 7.50769 9.16634 7.5592 9.08631 7.62583Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433283 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM12.646 12.146C12.559 12.3874 12.4228 12.608 12.246 12.794C12.0302 13.0261 11.774 13.2172 11.49 13.358C11.1092 13.5388 10.6953 13.6396 10.274 13.654V14.494H9.698V13.654C8.95934 13.6007 8.38267 13.3733 7.968 12.972C7.55334 12.5707 7.31267 11.98 7.246 11.2H8.646C8.63385 11.3606 8.65376 11.5219 8.70458 11.6747C8.7554 11.8275 8.83611 11.9687 8.942 12.09C9.14472 12.3069 9.41191 12.4528 9.704 12.506V10.44C9.67067 10.4267 9.626 10.412 9.57 10.396C9.514 10.38 9.45334 10.3653 9.388 10.352C9.144 10.2867 8.90334 10.2093 8.666 10.12C8.43613 10.0352 8.22088 9.91509 8.028 9.764C7.83591 9.61229 7.68012 9.4196 7.572 9.2C7.45191 8.94439 7.39302 8.66432 7.4 8.382C7.39263 8.08917 7.45201 7.79851 7.57364 7.53204C7.69527 7.26557 7.87595 7.03028 8.102 6.844C8.31995 6.66772 8.56793 6.53221 8.834 6.444C9.11173 6.34861 9.40095 6.29077 9.694 6.272V5.506H10.278V6.268C10.5637 6.30065 10.8444 6.36781 11.114 6.468C11.3652 6.55947 11.5982 6.69491 11.802 6.868C12.0044 7.04225 12.169 7.25594 12.286 7.496C12.4184 7.77476 12.4926 8.0776 12.504 8.386H11.104C11.1033 8.13973 11.0155 7.90165 10.856 7.714C10.7876 7.62725 10.7004 7.55715 10.601 7.509C10.5015 7.46084 10.3925 7.43588 10.282 7.436V9.186L10.524 9.246C10.606 9.266 10.694 9.29 10.786 9.316C11.1881 9.4079 11.5703 9.57188 11.914 9.8C12.1462 9.95963 12.3447 10.1635 12.498 10.4C12.6168 10.5929 12.6925 10.8092 12.72 11.034C12.7415 11.2219 12.7529 11.4109 12.754 11.6C12.746 11.7864 12.7095 11.9705 12.646 12.146Z"
        fill={theme === 'black' ? 'black' : 'white'}
      ></path>
    </svg>
  );
};
