import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { PersonIdInterface } from '../../interfaces/personId.interface';

export const personIdApi = createApi({
  reducerPath: 'personId',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    personId: builder.mutation<PersonIdInterface, void>({
      query: () => ({
        url: `people/me`,
        method: 'GET',
      }),
    }),
  }),
});
export const { usePersonIdMutation } = personIdApi;
