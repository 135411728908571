import React from 'react';
import { Props } from './ProgramCard.props';

function ProgramCard({
  icon,
  name,
  type,
  onChange,
  size = 'medium',
  border,
  transparent,
}: Props) {
  return (
    <div
      className={`program-card ${type} program-card-${size} ${
        border ? 'program-card-border' : ''
      } ${transparent ? 'program-card-transparent' : ''}`}
      onClick={() => onChange?.(type)}
      aria-hidden
    >
      <img className="program-card__icon" src={icon} alt="" />
      <p className="program-card__name">{name}</p>
    </div>
  );
}

export default ProgramCard;
