import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { PersonLoginInterface } from '../../interfaces/personLogin.interface';

export const userLoginApi = createApi({
  reducerPath: 'loginInitiate',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    loginInitiate: builder.mutation<PersonLoginInterface, PersonLoginInterface>(
      {
        query: (post: PersonLoginInterface) => ({
          url: `login_check`,
          method: 'POST',
          body: post,
        }),
      },
    ),
  }),
});
export const { useLoginInitiateMutation } = userLoginApi;
