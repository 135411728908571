import { ImageUploaderProps } from './ImageUploader.props';
import UploadIcon from '../../assets/media/upload.svg';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ImageUploader({
  formats,
  minSize,
  onUpload,
  file,
  disabled,
}: ImageUploaderProps) {
  const { t } = useTranslation();
  const [filePresentation, setFilePresentation] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (file) {
      const url = convertImageToRepresentation(file);
      setFilePresentation(url);
    }
  }, [file]);

  const convertImageToRepresentation = (file: File) => {
    return window.URL.createObjectURL(file);
  };

  const checkFormat = (file: File) => {
    console.log(file.type)
    const format = file.type.split('/')[1];
    return formats.includes(format);
  };

  const checkSize = (file: File) => {
    const size = file.size;
    return size > minSize;
  };

  const validateFile = (file: File) => {
    const isValidFormat = checkFormat(file);
    const isValidSize = checkSize(file);
    return isValidFormat && isValidSize;
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files) {
      const file = files[0];
      const isValid = validateFile(file);
      if (isValid) {
        onUpload(file);
        const url = convertImageToRepresentation(file);
        setFilePresentation(url);
        return;
      }

      setError('The file format or size is incorrect');
    }
  };

  return (
    <label className="image-uploader">
      {!filePresentation ? (
        <div className="image-uploader__information">
          <img
            src={UploadIcon}
            alt="uploader"
            className="image-uploader__information-icon"
          />
          <p className="image-uploader__information-title">
            {t('ImageUploader.loadImage')}
            <span className="image-uploader__information-title-star">*</span>
          </p>
          <p className="image-uploader__information-instruction">
            {t('ImageUploader.presToChose')}
          </p>
          <p className="image-uploader__information-formats">
            {t('ImageUploader.allowedFormats')}
            {formats.map(
              (format, index) =>
                `.${format}${index === formats.length - 1 ? '' : ', '}`,
            )}
          </p>
          <p className="image-uploader__information-size">
            {t('ImageUploader.allowedSize')} {minSize} KB
          </p>
          {error && (
            <p className="image-uploader__information-error">{error}</p>
          )}
        </div>
      ) : (
        <img
          className="image-uploader-representation"
          src={filePresentation}
          alt="presentation"
        />
      )}
      <input type="file" onChange={onChange} disabled={disabled ? disabled : false} />
    </label>
  );
}

export default ImageUploader;
