import React from 'react';

function PageError500() {
  return (
    <div className="error-page error-page__custom-height">
      <h2>500</h2>
      <h4>Ooops, something went wrong</h4>
      <p>
        Server error 500. We apologise and are fix the problem. Please try again
        at a later stage
      </p>
    </div>
  );
}

export default PageError500;
