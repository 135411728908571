import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
import Circle from '../../assets/media/specials/circle-empty.svg';
import GoldenShieldLogo from '../../assets/media/specials/shield-white.svg';
import FirstLogo from '../../assets/media/specials/thunder-white.svg';
import { CheckCard } from '../CheckCard/CheckCard';
import ProgramCard from '../ProgramCard/ProgramCard';

const validDashboardToken = 'UNIQUNIWTH231JSLWTA234QRSJLI992';

function DashboardComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uniqueKey } = useParams();
  const { pathname, state } = useLocation();
  const [isKeyValid, setIsKeyValid] = useState(false);
  const [isKeyChecked, setIsKeyChecked] = useState(false);

  useEffect(() => {
    if (pathname.includes('dashboard')) {
      if (state) {
        setIsKeyValid(isKeyValid);
      }
      setIsKeyChecked(true);
      return;
    }

    if (uniqueKey === validDashboardToken) {
      navigate('/candidates/personal-data', {
        replace: true,
        state: { isValidatedKey: true },
      });
      setIsKeyValid(uniqueKey === validDashboardToken);
      setIsKeyChecked(true);
    }
  }, [uniqueKey]);

  if (!isKeyValid && isKeyChecked) return <Navigate to="/candidates/cabinet" />;

  if (!isKeyChecked) return <></>;

  const documents = [
    { id: 1, name: 'Rules of Ethics of the Club Members' },
    { id: 2, name: 'Club Accession Agreement' },
    { id: 3, name: 'Bylaws of 7Flags Ltd' },
    { id: 4, name: 'Mutual Assistance' },
  ];

  return (
    <div className="dashboard">
      <div className="wrapper">
        <div className="container">
          <div className="dashboard__page">
            <div className="dashboard__page-top">
              <h5 className="dashboard__page-title">
                {t('Dashboard.introduction')}
              </h5>
            </div>

            <div className="dashboard__page-content">
              <div className="dashboard__item card">
                <h5 className="dashboard__item-subtitle">
                  {t('Dashboard.cards.information_1')}
                </h5>
                <p className="dashboard__item-text">
                  {t('Dashboard.cards.information_2')}
                </p>
              </div>
              <NavLink
                to="/candidates/chose-program"
                className="dashboard__item program"
              >
                <div className="dashboard__item-top">
                  <div className="dashboard__item-titles">
                    <h5 className="dashboard__item-title questioning">
                      {t('Dashboard.cards.programTitle')}
                    </h5>
                    <p className="dashboard__item-text">
                      {t('Dashboard.cards.programDescription')}
                    </p>
                  </div>
                  <img src={Circle} alt="" className="dashboard-item-logo" />
                </div>
                <div className="program__cards">
                  <ProgramCard
                    icon={GoldenShieldLogo}
                    name={'Golden Shield'}
                    type={'golden'}
                    size={'small'}
                  />
                  <ProgramCard
                    icon={FirstLogo}
                    name={'First'}
                    type={'silver'}
                    size={'small'}
                  />
                </div>
              </NavLink>
              <NavLink
                className="dashboard__item documents"
                to="/candidates/documents"
              >
                <div className="dashboard__item-top">
                  <div className="dashboard__item-titles">
                    <h5 className="dashboard__item-title questioning">
                      {t('Dashboard.cards.documentsTitle')}
                    </h5>
                    <p className="dashboard__item-text">
                      {t('Dashboard.cards.documentsDescription')}
                    </p>
                  </div>
                  <img src={Circle} alt="" className="dashboard-item-logo" />
                </div>
                <div className="checkbox">
                  {documents &&
                    documents.length &&
                    documents.map(({ name }) => (
                      <CheckCard
                        type="medium"
                        additionalHeaderTitle={t('Dashboard.cards.open')}
                        value={false}
                      >
                        {name}
                      </CheckCard>
                    ))}
                </div>
              </NavLink>

              <NavLink
                className="dashboard__item payment"
                to="/candidates/invoice"
              >
                <h5 className="dashboard__item-title payment">
                  {t('Dashboard.cards.invoicePayment')}
                </h5>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardComponent;
