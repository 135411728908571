import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { DefaultEyeIcon } from '../../../assets/media';
import { useClickOutside } from '../../../hooks';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '../../Checkbox/Checkbox';

type FieldTypes = 'main_password' | 'repeat_password' | null;
const checkSymbolsAndNumbersInStringRegExp =
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const uppercaseRegExp = /(.*[A-Z].*)/;

const lowercaseRegExp = /(.*[a-z].*)/;

export const SecurityForm = () => {
  const [openedField, setOpenedField] = useState<FieldTypes>(null);
  const [validation, setValidation] = useState({
    length: false,
    symbol: false,
    upperCase: false,
    lowerCase: false,
    passwordMatch: false,
  });
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  const SecuritySchema = yup
    .object({
      password: yup.string().required('Required field'),
      repeatPassword: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<{ password: string; repeatPassword: string }>({
    mode: 'onChange',
    resolver: yupResolver(SecuritySchema),
  });

  const componentRef = useRef<HTMLDivElement | null>(null);

  const onResetOpenedField = () => {
    setOpenedField(null);
  };

  const onChangePassword = (value: string) => {
    const repeatPassword = getValues().repeatPassword;
    if (value.length >= 9) {
      setValidation((prevState) => ({ ...prevState, length: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, length: false }));
    }

    if (checkSymbolsAndNumbersInStringRegExp.test(value)) {
      setValidation((prevState) => ({ ...prevState, symbol: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, symbol: false }));
    }

    if (uppercaseRegExp.test(value)) {
      setValidation((prevState) => ({ ...prevState, upperCase: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, upperCase: false }));
    }

    if (lowercaseRegExp.test(value)) {
      setValidation((prevState) => ({ ...prevState, lowerCase: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, lowerCase: false }));
    }

    if (value === repeatPassword) {
      setValidation((prevState) => ({ ...prevState, passwordMatch: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, passwordMatch: false }));
    }
  };

  const onChangeRepeatPassword = (value: string) => {
    const password = getValues().password;
    if (value === password) {
      setValidation((prevState) => ({ ...prevState, passwordMatch: true }));
    } else {
      setValidation((prevState) => ({ ...prevState, passwordMatch: false }));
    }
  };

  const onChangePolicyAgreement = () => {
    setIsPolicyAccepted((prevState) => !prevState);
  };

  const onSubmit = (data: any) => {
    console.log({ data });
  };

  const validationStage = useMemo(
    () => Object.values(validation).filter((value) => value),
    [validation],
  );

  useClickOutside(componentRef, onResetOpenedField, true);
  return (
    <>
      <div className="main-launch-product__inner">
        <div className="section-launch-product-main">
          <div className="section-launch-product-right__section">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-launch-product-main__form section-launch-settings-main__form ng-valid ng-dirty ng-touched"
            >
              <div className="section-launch-product-main__form-section first-section">
                <div className="section-launch-product-main__form-section-heading">
                  Сменить пароль
                </div>
                <div className="section-launch-product-main__form-section-content">
                  <div className="section-launch-product-main__form-text">
                    Новый пароль должен содержать не менее 9 символов, а также
                    заглавные и строчные английские буквы, цифры или другие
                    специальные символы (@#!$%?&){' '}
                  </div>
                </div>
                <div
                  className={'section-launch-product-main__form-block'}
                  ref={componentRef}
                >
                  <div
                    className={`section-launch-product-main__form-group section-launch-settings-main__form-group hidden ${
                      openedField === 'main_password' ? 'focused' : ''
                    }`}
                  >
                    <input
                      autoComplete="off"
                      type="password"
                      className="section-launch-product-main__form-input section-launch-settings-main__form-input-password"
                      placeholder="Придумайте новый пароль"
                      onClick={() => setOpenedField('main_password')}
                      {...register(`password`)}
                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        onChangePassword(e.currentTarget.value);
                        await register(`password`).onChange(e);
                      }}
                    />
                    <button className="section-launch-product-main__form-btn-show">
                      <span className="section-launch-product-main__form-btn-text--show">
                        <DefaultEyeIcon />
                      </span>
                    </button>
                    <div className="section-launch-settings-main__form-dropdown-password">
                      <div className="section-launch-settings-main__form-dropdown-line"></div>
                      <ul className="section-launch-settings-main__form-dropdown-list">
                        <li>
                          <div
                            className={`section-launch-settings-main__form-dropdown-check ${
                              validation.lowerCase ? 'accepted' : ''
                            }`}
                          ></div>
                          <span>Содержит хотя бы одну строчную букву</span>
                        </li>
                        <li>
                          <div
                            className={`section-launch-settings-main__form-dropdown-check ${
                              validation.length ? 'accepted' : ''
                            }`}
                          ></div>
                          <span>Содержит 9 и более символов</span>
                        </li>
                        <li>
                          <div
                            className={`section-launch-settings-main__form-dropdown-check ${
                              validation.upperCase ? 'accepted' : ''
                            }`}
                          ></div>
                          <span>Содержит хотя бы одну заглавную букву</span>
                        </li>
                        <li>
                          <div
                            className={`section-launch-settings-main__form-dropdown-check ${
                              validation.symbol ? 'accepted' : ''
                            }`}
                          ></div>
                          <span>Содержит одну цифру и специальный символ</span>
                        </li>
                      </ul>
                      <div className="section-launch-settings-main__form-dropdown-info">
                        Надежность пароля:{' '}
                        <span
                          data-protection-status="low"
                          className={`section-launch-settings-main__form-dropdown-info-status section-launch-settings-main__form-dropdown-info--low ${
                            validationStage.length === 0 ||
                            validationStage.length === 1
                              ? 'show'
                              : ''
                          }`}
                        >
                          низкая
                        </span>
                        <span
                          data-protection-status="middle"
                          className={`section-launch-settings-main__form-dropdown-info-status section-launch-settings-main__form-dropdown-info--middle ${
                            validationStage.length === 2 ||
                            validationStage.length === 3
                              ? 'show'
                              : ''
                          }`}
                        >
                          средняя
                        </span>
                        <span
                          data-protection-status="high"
                          className={`section-launch-settings-main__form-dropdown-info-status section-launch-settings-main__form-dropdown-info--high ${
                            validationStage.length === 4 ? 'show' : ''
                          }`}
                        >
                          высокая
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`section-launch-product-main__form-group section-launch-settings-main__form-group hidden ${
                      openedField === 'repeat_password' ? 'focused' : ''
                    }`}
                  >
                    <input
                      autoComplete="off"
                      type="password"
                      className="section-launch-product-main__form-input section-launch-settings-main__form-input-password"
                      placeholder="Повторите пароль"
                      onClick={() => setOpenedField('repeat_password')}
                      {...register(`repeatPassword`)}
                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        onChangeRepeatPassword(e.currentTarget.value);
                        await register(`repeatPassword`).onChange(e);
                      }}
                    />
                    <button
                      name="show-hide-value"
                      type="button"
                      className="section-launch-product-main__form-btn-show"
                    >
                      <span className="section-launch-product-main__form-btn-text--show">
                        <DefaultEyeIcon />
                      </span>
                    </button>
                    <div className="section-launch-settings-main__form-dropdown-password">
                      <div className="section-launch-settings-main__form-dropdown-line"></div>
                      <div className="section-launch-settings-main__form-dropdown-info">
                        <span
                          data-confirm="false"
                          className={`section-launch-settings-main__form-dropdown-info-status section-launch-settings-main__form-dropdown-info--low ${
                            validation.passwordMatch ? '' : 'show'
                          }`}
                        >
                          Пароли не совпадают
                        </span>
                        <span
                          className={`section-launch-settings-main__form-dropdown-info-status section-launch-settings-main__form-dropdown-info--high ${
                            validation.passwordMatch ? 'show' : ''
                          }`}
                        >
                          Пароли совпадают
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-launch-product-main__form-section">
                <div className="section-launch-product-main__form-section-content">
                  <ul className="section-launch-product-main__form-btn-list">
                    <li className="section-launch-product-main__form-btn-list-item">
                      <Checkbox
                        value={isPolicyAccepted}
                        onChange={onChangePolicyAgreement}
                      />
                      <label>
                        <span>
                          Понимаю, что выбор надежности пароля является моей
                          персональной ответственностью
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="section-launch-product-main__form-submit-container"
                style={{ marginTop: 0 }}
              >
                <button
                  type="button"
                  className="btn btn--white"
                  style={{ width: '200px' }}
                >
                  Подтвердить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <aside className="section-launch-product-right">
        <div className="section-launch-product-right__section">
          <h3 className="section-launch-product-right__section-heading">
            Безопасность
          </h3>
          <div className="section-launch-product-right__section-content">
            <div className="section-launch-product-right__section-content-text">
              <p>
                В данном разделе вы можете поменять пароль, если он стал
                известен третьим лицам или вы хотите изменить тот, который был
                получен автоматическим путем - при регистрации в системе или
                восстановлении доступа. Вам предстоит придумать новый пароль и
                затем правильно повторить его. В конце процедуры нажмите кнопку
                «Подтвердить». Помните о правилах безопасности: никому не
                сообщайте и не посылайте свой пароль в сообщениях. Меняйте его
                на новый минимум раз в год.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
