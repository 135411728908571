import React from 'react';

interface Props {
  isActive?: boolean;
}
export const NoteIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="44"
      height="38"
      viewBox="0 0 44 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.53689 2H29.9519V36H1.32031V9.15789L8.53689 2Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.05626"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.53601 2.29395V9.15848H1.61279"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.05626"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.685 23.9432C18.4796 23.9138 18.3036 23.7965 18.1863 23.6205C18.0396 23.3858 18.0102 23.0924 18.1276 22.8577L20.7385 17.196C20.9145 16.8146 21.3838 16.6386 21.7652 16.8146C22.1466 16.9906 22.3226 17.46 22.1466 17.8413L20.0931 22.271L24.9628 21.8016C25.3735 21.7723 25.7549 22.0657 25.8135 22.5057C25.8429 22.9457 25.5495 23.2978 25.1095 23.3564L18.8903 23.9725C18.8023 23.9725 18.7436 23.9725 18.685 23.9432Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.567731"
        stroke-miterlimit="10"
      />
      <path
        d="M24.8747 23.3271C24.6694 23.2978 24.4934 23.1804 24.3761 23.0044L20.7971 17.9587C20.6798 17.7827 20.6211 17.5773 20.6798 17.372C20.7091 17.1666 20.8264 16.9906 21.0025 16.8733L38.0171 4.75764C39.0145 4.05359 40.54 4.28827 41.244 5.31502L43.0335 7.83788C43.7669 8.89397 43.5322 10.3314 42.4761 11.0648L25.4615 23.1804C25.2854 23.3271 25.0801 23.3564 24.8747 23.3271Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.567731"
        stroke-miterlimit="10"
      />
      <path
        d="M6.07178 23.415H15.1071"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.05626"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.07178 29.3994H25.1106"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.05626"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
