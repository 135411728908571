import React from 'react';

import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import { Footer } from '../components/Footer/Footer';

function LayoutRegistration() {
  return (
    <div className="content">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default LayoutRegistration;
