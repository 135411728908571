import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import agreementsAnimation from '../../assets/animation/questionare-final/data.json';
import { useLottie } from 'lottie-react';
import { AgreementCheckbox } from '../../components/Agreement/agreementCheckbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { agreementsInterface } from '../../interfaces/agreements.interface';
import  axiosApi  from '../../helpers/axios.utils';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookies  from "universal-cookie";
import { NavLink, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import ModalWindow from '../../components/ModalWindow/ModalWindow';

function Agreement() {
  interface LottieAnimationProps {
    animationRoot: Object;
  }
  function LottieAnimation({ animationRoot }: LottieAnimationProps) {
    const options = {
      animationData: animationRoot,
      loop: true,
      autoplay: true,
    };
    const { View } = useLottie(options);
    const viewRender = () => View;
    return <div className="agreement-animation">{viewRender()}</div>;
  }
  const { t } = useTranslation();
  const { isShown, toggle } = useModal();
  const [modalMessage, setModalMessage] = useState('');

  const { setValue, handleSubmit, formState } = useForm<agreementsInterface>({
    defaultValues: {
      IndividualOffer: false,
      ValidationProcedure: false,
      DenialOfResponsibility: false,
      PersonalData: false,
      Policy: false,
      CanProceed: false,
    },
    mode: 'onChange',
  });
  const cookies = new Cookies();
  const [offer, setOffer] = useState(false);
  const [ValidationProcedure, setValidationProcedure] = useState(false);
  const [DenialOfResponsibility, setDenialOfResponsibility] = useState(false);
  const [PersonalData, setPersonalData] = useState(false);
  const [Policy, setPolicy] = useState(false);
  const [CanProceed, setProceed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setValue('IndividualOffer', offer);
    setValue('ValidationProcedure', ValidationProcedure);
    setValue('DenialOfResponsibility', DenialOfResponsibility);
    setValue('PersonalData', PersonalData);
    setValue('Policy', Policy);
    if (
      [
        offer,
        ValidationProcedure,
        DenialOfResponsibility,
        PersonalData,
        Policy,
      ].every((item) => item === true)
    ) {
      setProceed(false);
    } else {
      setProceed(true);
    }
    setValue('CanProceed', CanProceed);
  }, [
    offer,
    ValidationProcedure,
    DenialOfResponsibility,
    PersonalData,
    Policy,
  ]);
  const isProceedBtnEnabled = () => {
    setProceed((prev) => !prev);
  };
  const changeOffer = () => {
    setOffer((prev) => !prev);
  };
  const changeVal = () => {
    setValidationProcedure((prev) => !prev);
  };
  const changeResp = () => {
    setDenialOfResponsibility((prev) => !prev);
  };
  const changeData = () => {
    setPersonalData((prev) => !prev);
  };
  const changePolicy = () => {
    setPolicy((prev) => !prev);
  };

  const fpPromise = FingerprintJS.load()
  ;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    return result
  })()
  //getting fingerprint from fingerprintjs
  const [fingerprint, setFingerprint] = useState({ } as any)
  useEffect(() => {
    ;(async () => {
      const fp = await fpPromise
      const result = await fp.get()
      setFingerprint(result)
    })()
  })


  const onSubmit: SubmitHandler<agreementsInterface> = async (
    formData: agreementsInterface,
  ) => {
    console.log(formData);
    const candidate = cookies.get('person_id');
    console.log(fingerprint)
    const payload = {
      inviteId: candidate.person.inviteId ? candidate.person.inviteId : 'fd2f6bbe-12c1-49d9-b640-6169e8259351',
      fingerprint: JSON.stringify(fingerprint),
      hostname: window.location.href,
    }
    try {
      const response = await axiosApi.post('invites/accept_public_agreement', payload);
      if (response.status === 204) {
        navigate('/candidates/upload-documents')
      }
    } catch (error) {
      console.log(error);
      setModalMessage(t('Something went wrong! Try later'));
      toggle()
    }
  };

  return (
    <>
      <ModalWindow
        isShown={isShown}
        hide={toggle}
        modalContent={modalMessage}
      />
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="agreement-wrapper">
          <div
            className={`agreement-inner agreement-border  ${
              !CanProceed ? 'agreement-checkbox_wrapper-selected' : ''
            }`}
          >
            <h1 className="agreement-header">{t('Agreements.header')}</h1>
            <div className="agreement-subheader">
              {t('Agreements.subheader')}
            </div>
            <div className="mt-8">
              <AgreementCheckbox
                value={offer}
                selected={offer}
                onClickBox={changeOffer}
                header={t('Agreements.IndividualOffer.header')}
                description={t('Agreements.IndividualOffer.description')}
              />
            </div>
            <div className="mt-8">
              <AgreementCheckbox
                value={ValidationProcedure}
                selected={ValidationProcedure}
                onClickBox={changeVal}
                header={t('Agreements.ValidationProcedure.header')}
                description={t('Agreements.ValidationProcedure.description')}
              />
            </div>
            <div className="mt-8">
              <AgreementCheckbox
                value={DenialOfResponsibility}
                selected={DenialOfResponsibility}
                onClickBox={changeResp}
                header={t('Agreements.DenialOfResponsibility.header')}
                description={t('Agreements.DenialOfResponsibility.description')}
              />
            </div>
            <div className="mt-8">
              <AgreementCheckbox
                value={PersonalData}
                selected={PersonalData}
                onClickBox={changeData}
                header={t('Agreements.PersonalData.header')}
                description={t('Agreements.PersonalData.description')}
              />
            </div>
            <div className="mt-8">
              <AgreementCheckbox
                value={Policy}
                selected={Policy}
                onClickBox={changePolicy}
                header={t('Agreements.Policy.header')}
                description={t('Agreements.Policy.description')}
              />
            </div>
          </div>
          <div className={`el-unmask ${!CanProceed ? 'visible' : ''}`}>
            <button
              className={`agreement-button agreement-border mt-8 el-unmask__content`}
              type="submit"
              disabled={CanProceed}
            >
              {t('Agreements.button')}
            </button>
          </div>
          <LottieAnimation animationRoot={agreementsAnimation} />
        </div>
      </form>
    </>
  );
}
export default Agreement;

