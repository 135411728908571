import React from 'react';

interface Props {
  isActive?: boolean;
}
export const StrongholdSolutionsIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1485 1.21484L15.1468 4.75159L40.297 18.8986L40.2255 11.968L21.1485 1.21484Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.42474"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.28684 35.439L20.3995 46.1564L26.4013 42.5839L1.21539 28.4727L1.28684 35.439Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.42474"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.187 31.2592L28.2579 30.1875L20.5056 34.6531L22.399 35.7605L30.187 31.2592Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.42899"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9003 27.9731L1.32227 14.9336V23.6504L16.3624 32.2958L23.9003 27.9731Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.42474"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.4361 38.0824L30.6159 40.5116L38.4039 36.0103L34.1884 33.5811L26.4361 38.0824Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.42899"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6816 10.0028L10.4301 7.00195L2.64209 11.4675L7.89362 14.5041L15.6816 10.0028Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.42899"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6144 13.4331L19.721 12.3613L11.933 16.8269L13.8264 17.9344L21.6144 13.4331Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.42899"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2935 20.0059L39.8354 32.4381V23.7212L25.8314 15.6475L18.2935 20.0059Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.42474"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
