import { Navigate } from 'react-router-dom';
import React from 'react';
import RegistrationOffers from '../pages/RegistrationOffers/RegistrationOffers';
import RegistrationType from '../pages/RegistrationType/RegistrationType';
import RegistrationPassportData from '../pages/RegistrationPassportData/RegistrationPassportData';
import RegistrationAddress from '../pages/RegistrationAddress/RegistrationAddress';
import PageError404 from '../pages/PageError404';
import ExpiredRegistration from '../pages/ExpiredRegistration/ExpiredRegistration';
import ConfirmEmail from '../pages/ConfirmEmail/ConfirmEmail';
import ChoseProgram from '../pages/ChoseProgram/ChoseProgram';
import SignDocuments from '../pages/SignDocuments/SignDocuments';
import Dashboard from '../pages/Dashboard/Dashboard';
import Invoice from '../pages/Invoice/Invoice';
import PersonalData from '../pages/PersonalData/PersonalData';
import DocumentsValidation from '../pages/DocumentsValidation/DocumentsValidation';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import PersonalCabinet from '../pages/PersonalCabinet/PersonalCabinet';
import CandidateSignIn from '../pages/CandidateSignIn/CandidateSignIn';
import UserSignIn from '../pages/UserSignIn/UserSignIn';
import UserInvitation from '../pages/UserInvitation/UserInvitation';
import FirstProgram from '../pages/FirstProgram/FirstProgram';
import { SilverGeneralStatement } from '../pages/FirstProgram/GeneralStatement/GeneralStatement';
import { SilverAccountStatement } from '../pages/FirstProgram/AccountStatement/AccountStatement';
import GoldenProgram from '../pages/GoldenProgram/GoldenProgram';
import { GoldenGeneralStatement } from '../pages/GoldenProgram/GeneralStatement/GeneralStatement';
import { GoldenAccountStatement } from '../pages/GoldenProgram/AccountStatement/AccountStatement';
import PersonalSettings from '../pages/PersonalSettings/PersonalSettings';
import Welcome from '../pages/Welcome/Welcome';
import Agreement from '../pages/Agreement/Agreement';
import AwaitingApproval from "../pages/AwaitingApproval/AwaitingApproval";
export const userAuthProducts = [
  {
    path: 'first',
    element: <FirstProgram />,
    type: 'silver' as 'silver' | 'golden',
    subElements: [
      {
        path: 'balance',
        element: undefined,
        subElements: [
          { path: 'general-statement', element: <SilverGeneralStatement /> },
          { path: 'account-statement', element: <SilverAccountStatement /> },
        ],
      },
    ],
  },
  {
    path: 'goldenShield',
    element: <GoldenProgram />,
    type: 'golden' as 'silver' | 'golden',
    subElements: [
      {
        path: 'balance',
        element: undefined,
        subElements: [
          { path: 'general-statement', element: <GoldenGeneralStatement /> },
          { path: 'account-statement', element: <GoldenAccountStatement /> },
        ],
      },
    ],
  },
];
export const userCandidate = [

]
export const userAuth = [
  { path: '/', element: <Navigate to="/candidates/cabinet" /> },
  // { path: '/candidates/confirm-email', element: <ConfirmEmail /> },
  // {
  //   path: '/candidates/expired-registration',
  //   element: <ExpiredRegistration />,
  // },
  { path: '/login', element: <UserSignIn /> },
  { path: '/candidates/registration/offers', element: <RegistrationOffers /> },
  // { path: '/candidates/registration/type', element: <RegistrationType /> },
  {
    path: '/candidates/registration/passport-data',
    element: <RegistrationPassportData />,
  },
  {
    path: '/candidates/registration/address',
    element: <RegistrationAddress />,
  },
  {
    path: '/candidates/chose-program',
    element: <ChoseProgram />,
  },
  {
    path: '/candidates/settings',
    element: <PersonalSettings />,
  },
  {
    path: '/candidates/personal-data',
    element: <PersonalData />,
  },
  {
    path: '/candidates/upload-documents',
    element: <DocumentsValidation />,
  },
  {
    path: '/candidates/documents',
    element: <SignDocuments />,
  },

  {
    path: '/candidates/invoice',
    element: <Invoice />,
  },
  {
    path: '/candidates/upload-documents',
    element: <DocumentsValidation />,
  },
  {
    path: '/candidates/cabinet',
    element: <PersonalCabinet />,
  },
  {
    path: '/candidates/invitation',
    element: <UserInvitation />,
  },
  {
    path: '/candidates/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/candidates/:uniqueKey',
    element: <Dashboard />,
  },
  {
    path: '/candidates/welcome',
    element: <Welcome />,
  },
  {
    path: '/candidates/agreement',
    element: <Agreement />,
  },
  {
    path: '/candidates/awaiting-validation',
    element: <AwaitingApproval />,
  },
  {
    path: '/candidates/awaiting-approval',
    element: <AwaitingApproval />,
  },
  { path: '*', element: <PageError404 /> },
];

export const userGuest = [
  { path: '/', element: <Navigate to="/login" /> },
  { path: '/candidates/login', element: <CandidateSignIn /> },
  { path: '/login', element: <UserSignIn /> },
  { path: '/candidates/forgot-password', element: <ForgotPassword /> },
  {
    path: '/candidates/expired-registration',
    element: <ExpiredRegistration />,
  },
  { path: '/candidates/confirm-email', element: <ConfirmEmail /> },
  { path: '*', element: <PageError404 /> },
];
