interface Props {
  isActive: boolean;
}
export const IQeonIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3761 2.57708C19.6775 2.18224 18.9182 2 18.1285 2C17.4299 2.03037 16.7314 2.21261 16.1239 2.57708L4.12665 9.50208C3.54956 9.83618 3.0636 10.2918 2.7295 10.8081C2.2739 11.5067 2.00055 12.3268 2.00055 13.1772V13.2987L2.2739 13.1468C2.91173 12.7823 3.57994 12.6001 4.27851 12.5697C5.03783 12.5394 5.82752 12.752 6.5261 13.1468L18.5234 20.0718C18.5841 20.1022 18.6752 20.1629 18.736 20.1933L30.0954 13.572C30.794 13.1772 31.3711 12.5697 31.7355 11.8712C32.0696 11.2637 32.2215 10.5955 32.2215 9.86656V9.41096L20.3761 2.57708Z"
        stroke={!isActive ? 'black' : 'white'}
        strokeWidth="2.20015"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52555 13.1474C5.82697 12.7525 5.06765 12.5703 4.27796 12.5703C3.57939 12.6007 2.88081 12.7829 2.27336 13.1474L2 13.2993V27.0278C2 27.1796 2 27.3011 2.03037 27.453H2.06075C2.72895 27.8175 3.45789 28.0301 4.18684 28.0301C4.91579 28.0301 5.64474 27.8478 6.31294 27.453L18.7354 20.1939C18.6747 20.1635 18.5836 20.1028 18.5228 20.0724L6.52555 13.1474Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        strokeWidth="2.20015"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9833 29.0015C34.3174 28.394 34.4693 27.7258 34.4693 26.9969V13.1773C34.4693 12.5698 34.3478 11.9623 34.1048 11.446C33.7403 10.6563 33.1329 9.95774 32.3432 9.50215L32.2217 9.44141V9.92737C32.2217 10.6259 32.0395 11.3245 31.7357 11.932C31.3713 12.6306 30.7942 13.2076 30.0956 13.6329L6.31369 27.4525C5.64549 27.817 4.91654 28.0296 4.1876 28.0296C3.45865 28.0296 2.7297 27.8473 2.0615 27.4525H2.03113C2.09187 27.9992 2.24374 28.5155 2.48672 28.9711C2.85119 29.6697 3.42828 30.2772 4.12685 30.7024L16.1241 37.6274C16.7923 37.9918 17.5213 38.2045 18.2502 38.2045C18.9792 38.2045 19.7081 38.0222 20.3763 37.6274L32.3432 30.7327L32.3736 30.7024C33.0418 30.3075 33.6188 29.7001 33.9833 29.0015Z"
        stroke={!isActive ? 'black' : 'white'}
        strokeWidth="2.20015"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
