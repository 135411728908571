import React from 'react';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import DashboardComponent from '../../components/Dashboard/DashboardComponent';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';

function Dashboard() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <section className="section-cabinet">
        <AnimationWrapper className="container">
          <DashboardComponent />
        </AnimationWrapper>
      </section>
    </>
  );
}

export default Dashboard;
