import React from 'react';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import DocumentsInformation from '../../components/DocumentsInformation/DocumentsInformation';

function SignDocuments() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <DocumentsInformation />
      </AnimationWrapper>
    </section>
  );
}

export default SignDocuments;
