import React from 'react';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';
import RegistrationInitiateForm from '../../components/RegistrationInitiateForm/RegistrationInitiateForm';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';

function CandidateSignIn() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <main className="main-login">
        <div className="wrapper">
          <div className="container">
            <RegistrationInitiateForm />
          </div>
        </div>
      </main>
    </>
  );
}

export default CandidateSignIn;
