import React from 'react';

interface Props {}
export const VerticalDotsIcon = ({}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.96 122.88"
      width="5"
      height="25"
    >
      <path
        className="cls-1"
        d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};
