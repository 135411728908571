import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useGetRegistrationOffersQuery,
  useRegistrationOffersMutation,
} from '../../app/services/registration.api';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {
  GetRegistrationOffersFormInterface,
  RegistrationOffersFormInterface,
} from '../../interfaces/registration.interface';
import PageError500 from '../../pages/PageError500';
import Spinner from '../Spinner/Spinner';

function RegistrationOffersForm() {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetRegistrationOffersQuery();
  const [sendOffersRequest] = useRegistrationOffersMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegistrationOffersFormInterface>();

  const step = `1`;

  const onSubmit: SubmitHandler<RegistrationOffersFormInterface> = async (
    formData: RegistrationOffersFormInterface,
  ) => {
    sendOffersRequest({ ...formData, step })
      .unwrap()
      .then(() => {
        navigate('/candidates/registration/type');
      })
      .catch((e) => {
        setError('offerCheckbox', {
          type: 'offerCheckboxValidation',
          message: e.data.message.offerCheckbox
            ? e.data.message.offerCheckbox
            : e.data.message,
        });
      });
  };

  return (
    <>
      {error && <PageError500 />}
      {!error && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {data?.offers?.map((content: GetRegistrationOffersFormInterface) => (
            <div className="offers-box" key={content.id.toString()}>
              <div className="offers-box__name">
                <div className="login__form-checkbox-group">
                  <input
                    className="login__form-checkbox"
                    id={`${content.id.toString()}checkboxs`}
                    type="checkbox"
                    defaultChecked={content.confirmed}
                    value={content.id.toString()}
                    {...register(`offerCheckbox`)}
                  />
                  <label
                    className="login__form-checkbox-label"
                    htmlFor={`${content.id.toString()}checkboxs`}
                  >
                    {content.title}
                  </label>
                </div>
              </div>

              <div className="offers-box__description">
                <p>{content.text}</p>
              </div>
            </div>
          ))}
          {errors.offerCheckbox?.type === 'offerCheckboxValidation' && (
            <ErrorMessage>{errors.offerCheckbox.message}</ErrorMessage>
          )}
          <div className="offers-box__btn">
            <button className="login__btn btn btn--black" type="submit">
              {t('RegistrationOffers.formButton')}
            </button>
          </div>
        </form>
      )}
      {isLoading && <Spinner />}
    </>
  );
}

export default RegistrationOffersForm;
