import React, { useState } from 'react';
import FormBase from '../FormBase/FormBase';
import {
  BellIcon,
  DefaultEyeIcon,
  GearIcon,
  LocationIcon,
  RefreshIcon,
  ShieldIcon,
  UsdIcon
} from '../../assets/media';
import { ProfileForm } from './ProfileForm/ProfileForm';
import { SecurityForm } from './SecurityForm/SecurityForm';
import { NotificationsForm } from './NotificationsForm/NotificationsForm';
import { DeliveryAddressForm } from './DeliveryAddressForm/DeliveryAddressForm';
import { EnteringHistoryForm } from './EnteringHistoryForm/EnteringHistoryForm';
import { PrivacyForm } from './PrivacyForm/PrivacyForm';
import { FirstLineForm } from "./FirstLineForm/FirstLineForm";

export enum PersonalSettingsSectionsEnum {
  PROFILE = 'profile',
  SECURITY = 'security',
  FIRST_LINE = 'firstLine',
  NOTIFICATIONS = 'notifications',
  DELIVERY_ADDRESS = 'delivery_address',
  ENTERING_HISTORY = 'entering_history',
  PRIVACY = 'privacy',
}

const sections = [
  {
    name: 'Профиль',
    key: PersonalSettingsSectionsEnum.PROFILE,
    icon: <GearIcon />,
  },
  {
    name: 'Первая линия',
    key: PersonalSettingsSectionsEnum.FIRST_LINE,
    icon: <UsdIcon theme={"black"} />,
  },
  {
    name: 'Безопасность',
    key: PersonalSettingsSectionsEnum.SECURITY,
    icon: <ShieldIcon />,
  },
  {
    name: 'Оповещения',
    key: PersonalSettingsSectionsEnum.NOTIFICATIONS,
    icon: <BellIcon />,
  },
  {
    name: 'Адрес доставки',
    key: PersonalSettingsSectionsEnum.DELIVERY_ADDRESS,
    icon: <LocationIcon />,
  },
  {
    name: 'История входов',
    key: PersonalSettingsSectionsEnum.ENTERING_HISTORY,
    icon: <RefreshIcon />,
  },
  {
    name: 'Приватность',
    key: PersonalSettingsSectionsEnum.PRIVACY,
    icon: <DefaultEyeIcon />,
  },
];

export const PersonalSettingsForm = () => {
  const [activeSection, setActiveSection] = useState(sections[0].key);
  const onSectionChange = (section: string) => {
    setActiveSection(section as PersonalSettingsSectionsEnum);
  };

  const getOpenFormBySection = (
    activeSection: PersonalSettingsSectionsEnum,
  ) => {
    switch (activeSection) {
      case PersonalSettingsSectionsEnum.PROFILE:
        return <ProfileForm />;
      case PersonalSettingsSectionsEnum.SECURITY:
        return <SecurityForm />;
      case PersonalSettingsSectionsEnum.NOTIFICATIONS:
        return <NotificationsForm />;
      case PersonalSettingsSectionsEnum.DELIVERY_ADDRESS:
        return <DeliveryAddressForm />;
      case PersonalSettingsSectionsEnum.ENTERING_HISTORY:
        return <EnteringHistoryForm />;
      case PersonalSettingsSectionsEnum.PRIVACY:
        return <PrivacyForm />;
      case PersonalSettingsSectionsEnum.FIRST_LINE:
        return <FirstLineForm />;
    }
  };
  return (
    <div>
      <div className="container">
        <h1 className="section-personal-settings-title">Настройки</h1>
      </div>
      <FormBase
        sections={sections}
        onSectionChange={onSectionChange}
        activeSection={activeSection}
      >
        {getOpenFormBySection(activeSection)}
      </FormBase>
    </div>
  );
};
