import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import welcomeAnimation from '../../assets/animation/welcome/data.json';
import { useLottie } from 'lottie-react';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useSignInvitationApplyMutation } from '../../app/services/invitationApply.api'
import { InvitationApplyInterface } from "../../interfaces/invitationApply.interface";
import Cookies  from "universal-cookie";
import { NavLink, useNavigate } from 'react-router-dom';

function Welcome() {
  // Lottie
  interface LottieAnimationProps {
    animationRoot: Object;
  }
  const cookies = new Cookies();

  function LottieAnimation({ animationRoot }: LottieAnimationProps) {
    const options = {
      animationData: animationRoot,
      loop: true,
      autoplay: true,
    };
    const { View } = useLottie(options);
    const viewRender = () => View;
    return <div className="section_welcome-animation">{viewRender()}</div>;
  }
  const { t } = useTranslation();

  // Text animation

  const headerRef = useRef<HTMLDivElement>(null);
  const text = useRef<HTMLDivElement>(null);

  const fpPromise = FingerprintJS.load()
  ;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    return result
  })()
  //getting fingerprint from fingerprintjs
  const [fingerprint, setFingerprint] = useState({ } as any)
  useEffect(() => {
    ;(async () => {
      const fp = await fpPromise
      const result = await fp.get()
      setFingerprint(result)
    })()
  })
  const candidate = cookies.get('person_id');
  const isInViewportHeader = useIsInViewport(headerRef);
  const isInViewportText = useIsInViewport(text);
  const [applyInvitation] = useSignInvitationApplyMutation()
  const navigate = useNavigate();
  const payload: InvitationApplyInterface = {
    inviteId: candidate.person.inviteId ? candidate.person.inviteId : 'fd2f6bbe-12c1-49d9-b640-6169e8259351',
    fingerprint: JSON.stringify(fingerprint),
    hostname: window.location.href,
  }
  const acceptInvitation = () => {
    applyInvitation(payload).then((response) => {
      console.log(response)
      navigate('/candidates/agreement')
    }).catch(
      (error) => {
        console.log(error)
      }
    )
  }
  function useIsInViewport(ref: any) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  useEffect(() => {
    const appearingTextElements = document.querySelectorAll('.el-unmask');
  }, []);

  return (
    <>
      <section className={`section_welcome section_welcome-height`}>
        <div className="container">
          <LottieAnimation animationRoot={welcomeAnimation} />
          <div className="section_welcome-header_wrapper" ref={headerRef}>
            <div className={`el-unmask ${isInViewportHeader ? 'visible' : ''}`}>
              <div className="section_welcome-header el-unmask__content">
                {t('Welcome.title')}
              </div>
            </div>
            {/* <div className={`el-unmask ${isInViewportHeader ? 'visible' : ''}`}>
            <div className="section_welcome-subheader el-unmask__content">
            {t('Welcome.subtitle')}
            </div>
          </div> */}
          </div>
          <div className="section_welcome-text_wrapper">
            <div className={`el-unmask ${isInViewportText ? 'visible' : ''}`}>
              <p className="section_welcome-text el-unmask__content" ref={text}>
                {t('Welcome.subtitle')}
              </p>
            </div>
          </div>
          <div className="section_welcome-buttons">
            <button className="btn btn--grey">{t('Welcome.buttonRej')}</button>
            <button className="btn btn--black" onClick={() => acceptInvitation()}>{t('Welcome.buttonAcc')}</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;

