export const CURRENCIES = [
  {
    id: '9efaebaa-9601-4342-b56b-ebcb564844bf',
    label: 'USD',
    value: 'USD',
  },
  {
    id: 'a61db533-8b82-4917-809d-018a0ac2094b',
    label: 'EUR',
    value: 'EUR',
  },
  /* {
    id: '5f5db663-310f-47cc-88a1-a590a87a8aed',
    label: 'RUB',
    value: 'RUB',
  },
  {
    id: '47f5576e-699b-44ca-87b7-f94b0247d288',
    label: 'XAU',
    value: 'XAU',
  },
  {
    id: 'c0f50781-6251-414e-8f84-3a0256024374',
    label: 'BTC',
    value: 'BTC',
  },
  {
    id: '778f6414-7faf-46f5-bb46-a176dc70da3d',
    label: 'ETH',
    value: 'ETH',
  },
  {
    id: 'a0a353ff-0962-44ba-81bb-9a81301ea9f7',
    label: 'USDT',
    value: 'USDT',
  }, */
];
