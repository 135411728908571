import React from 'react';

interface Props {
  isActive?: boolean;
}
export const LifeJournalIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="47"
      height="38"
      viewBox="0 0 47 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_302)">
        <path
          d="M44.4661 5.28661H41.264V1.96367C41.264 1.20846 40.7203 0.574081 39.965 0.453247C39.8442 0.423038 36.7025 -0.0300901 32.9567 0.664706C28.7577 1.41992 25.4347 3.32306 23.1691 6.16266C20.8732 3.35327 17.5503 1.48034 13.3513 0.725123C9.57525 0.030327 6.46377 0.483455 6.34294 0.513664C5.58773 0.634498 5.04397 1.26888 5.04397 2.02409V5.34703H1.84187C0.996028 5.34703 0.301231 6.04183 0.301231 6.88767L0.33144 35.7972C0.33144 36.2806 0.573108 36.7639 0.965819 37.0358C1.35853 37.3077 1.90228 37.3983 2.35541 37.217C2.47625 37.1868 13.9555 33.2295 22.5347 37.1264C22.9274 37.3077 23.4108 37.3077 23.8035 37.1264C32.3525 33.2295 43.862 37.0962 43.9828 37.1566C44.1338 37.217 44.3151 37.2473 44.4963 37.2473C44.7984 37.2473 45.1307 37.1566 45.3724 36.9754C45.7651 36.6733 46.0068 36.2202 46.0068 35.7368L45.9765 6.82725C46.0068 5.98141 45.312 5.28661 44.4661 5.28661ZM3.41271 33.7431L3.3825 8.4283H5.04397L5.07418 28.8493C5.07418 29.3024 5.25543 29.7253 5.61794 29.9972C5.95023 30.2993 6.40336 30.4201 6.85649 30.3597C6.94711 30.3597 13.593 29.4232 18.4868 32.7462C12.4451 31.7493 6.46377 32.9274 3.41271 33.7431ZM24.6795 9.36477C28.0629 3.62514 35.2223 3.29285 38.1827 3.41368L38.213 27.1576C36.914 27.0972 35.0411 27.1274 32.9567 27.5201C29.6337 28.1243 26.8243 29.4534 24.7097 31.3868L24.6795 9.36477ZM27.942 32.7462C32.8056 29.393 39.4817 30.2993 39.5723 30.3295C40.0255 30.3899 40.4484 30.2691 40.8109 29.967C41.1432 29.6649 41.3546 29.242 41.3546 28.7889L41.3244 8.36789H42.9859L43.0161 33.6826C39.9348 32.8972 33.9233 31.7191 27.942 32.7462Z"
          fill={!isActive ? 'black' : 'white'}
          stroke="white"
          stroke-width="0.604171"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_302">
          <rect width="46.3399" height="37.6398" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
