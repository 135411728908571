import React from 'react';

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  name: string;
}
export const Toggle = ({ checked, onChange, disabled, name }: Props) => {
  return (
    <div className="checkbox-setting">
      <input
        id={`toggle-${name}`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`toggle-${name}`} />
    </div>
  );
};
