import React from 'react';

export const StripeCurveLinesIcon = () => {
  return (
    <svg viewBox="0 0 39 21" fill="none" width="38" height="20">
      <path
        d="M0.880371 5.18457C0.880371 10.0889 0.880371 14.9931 0.880371 19.8974"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M8.80273 7.448C8.80273 9.33426 8.80273 11.2205 8.80273 13.1068"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M9.93457 0.657526C19.5965 0.657526 28.9006 0.255911 38.2285 2.92104"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};
