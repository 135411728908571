import React from 'react';

interface Props {
  isActive?: boolean;
}
export const EurosimTravelIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_281)">
        <path
          d="M20.7596 46.1033L1.20142 26.5451C0.349978 25.6936 -0.0507025 24.5918 -0.000617507 23.4899C0.0494675 22.5132 0.450148 21.5366 1.20142 20.7853L20.7596 1.20204C21.561 0.40068 22.6128 0 23.6395 0C24.6913 0 25.7181 0.40068 26.5194 1.20204L46.0776 20.7603C46.8038 21.4865 47.2045 22.4381 47.2546 23.3897C47.3297 24.5166 46.9291 25.6686 46.0776 26.5451L26.5445 46.1033C25.8933 46.7544 25.092 47.13 24.2656 47.2552C23.0135 47.4305 21.7112 47.0549 20.7596 46.1033Z"
          fill={!isActive ? '#010202' : 'white'}
        />
        <path
          d="M31.7541 24.6666C32.1548 24.7166 32.7057 24.7918 33.3568 24.7918C34.8343 24.7918 36.7376 24.4662 37.9897 22.9887L42.7227 17.3541L41.4706 16.102L36.6374 21.8368C35.3853 23.3393 32.7809 23.0388 31.8793 22.8885C31.7792 22.588 31.6039 22.3126 31.3785 22.0872L28.9494 19.6581L37.0631 11.7196L35.836 10.4925L27.7473 18.456L25.193 15.9017C24.9426 15.6513 24.6671 15.476 24.3666 15.3758C24.1412 14.3741 23.7656 12.0201 25.0427 10.7429L30.5771 5.20853L29.35 3.98145L23.7906 9.51584C21.9875 11.3189 22.288 14.1237 22.5886 15.5511C22.4133 15.6513 22.263 15.7765 22.1127 15.9017L19.4833 18.5562L17.2545 16.1771V12.1703H15.5015V15.6513H6.28587L4.5329 17.4042H15.9773L18.2312 19.7833L15.9022 22.1122C15.7269 22.2875 15.5766 22.4879 15.4765 22.7132C14.0741 22.5379 11.1692 22.4378 9.49132 24.4161L4.73324 30.0757L5.98536 31.3279L10.8436 25.5681C12.0457 24.1407 14.4497 24.341 15.4514 24.4913C15.5516 24.7417 15.7269 24.9921 15.9272 25.1925L18.5066 27.7718L10.4179 35.7354L11.1942 36.5367L11.645 36.9875L19.7337 29.024L22.1127 31.403C22.3882 31.6785 22.7388 31.8788 23.1144 31.979C23.3398 32.9306 23.7656 35.3597 22.4383 36.712L16.8789 42.2715L18.1059 43.4985L23.6654 37.9391C25.5686 36.0359 25.1429 33.0308 24.8173 31.7035C24.9426 31.6284 25.0427 31.5282 25.1679 31.428L27.7473 28.8487L30.0262 31.2778V35.2846H31.7792V31.8037H40.8696L42.6226 30.0507H31.3034L28.9994 27.6216L31.3785 25.2425C31.5287 25.0422 31.6539 24.8669 31.7541 24.6666ZM30.1514 23.9403L23.9408 30.1509C23.8657 30.226 23.7656 30.251 23.7155 30.2761C23.5903 30.3011 23.44 30.251 23.3649 30.1509L17.1543 23.9403C17.0291 23.8151 17.0291 23.6899 17.0291 23.6148C17.0291 23.5396 17.0542 23.4395 17.1543 23.3393L23.3649 17.1288C23.465 17.0286 23.5903 17.0036 23.6654 17.0036C23.7405 17.0036 23.8657 17.0286 23.9659 17.1288L30.1764 23.3393C30.2766 23.4395 30.3017 23.5396 30.3017 23.6148C30.2766 23.6899 30.2766 23.8402 30.1514 23.9403Z"
          fill={!isActive ? 'white' : 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_281">
          <rect width="47.3053" height="47.3053" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
