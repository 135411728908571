import React from 'react';
import { useLottie } from 'lottie-react';

interface LottieAnimationProps {
  animationRoot: Object;
}

function LottieAnimation({ animationRoot }: LottieAnimationProps) {
  const options = {
    animationData: animationRoot,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  const viewRender = () => View;

  return <div className="login__animation">{viewRender()}</div>;
}

export default LottieAnimation;
