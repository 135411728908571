import React from 'react';

import ExpiredRegistrationForm from '../../components/ExpiredRegistrationForm/ExpiredRegistrationForm';
import animationRegistrationInitiate from '../../assets/animation/login-black/data.json';
import LottieAnimation from '../../components/LottieAnimation/LottieAnimation';

function Registration() {
  return (
    <>
      <LottieAnimation animationRoot={animationRegistrationInitiate} />
      <main className="main-login">
        <div className="wrapper">
          <div className="container">
            <ExpiredRegistrationForm />
          </div>
        </div>
      </main>
    </>
  );
}

export default Registration;
