import React from 'react';

function PageError404() {
  return (
    <div className="error-page">
      <h2>404</h2>
      <h4>Page not found</h4>
      <p>
        Are you sure the website URL is correct? Go to our home page or go back
        to previous page
      </p>
    </div>
  );
}

export default PageError404;
