import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoBlack } from '../../assets/media/logo-black.svg';
import NavMenu from '../../components/NavMenu/NavMenu';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

function NavigationBar() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('_is-locked');
    } else {
      document.body.classList.remove('_is-locked');
    }
  }, [open]);

  return (
    <nav className={`nav nav--inverse ${open ? 'nav-open' : ''}`} data-nav="">
      <div className="wrapper">
        <div className="container">
          <div className="nav__inner">
            <div className="nav__logo-container">
              <a className="nav__logo" href="/">
                <LogoBlack />
              </a>
            </div>

            <ul className="nav__links">
              <li className="nav__links-item">
                <LanguageSwitcher />
              </li>
              <li className="nav__links-item">
                <a className="nav__link link" href="/">
                  {t('NavigationBar.login')}
                </a>
              </li>
              <li className="nav__links-item">
                <a className="nav__link link" href="/">
                  {t('NavigationBar.about')}
                </a>
              </li>
              <li className="nav__links-item">
                <a className="nav__link link" href="/">
                  {t('NavigationBar.directions')}
                </a>
              </li>
              <li className="nav__links-item">
                <a className="nav__link link" href="/">
                  {t('NavigationBar.members')}
                </a>
              </li>
            </ul>

            <button className="nav__btn" type="button" onClick={openMenu}>
              <div className="nav__btn-text">{t('NavigationBar.menu')}</div>
              <div className="nav__btn-cross">+</div>
            </button>

            <NavMenu openMenu={openMenu} />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
