import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface InputUI {
  placeholder?: string;
  type?: string;
  className?: string;
  setfocustype?: string;
}

function Input<T extends FieldValues = FieldValues>(
  props: UseControllerProps<T> & InputUI,
) {
  const { field, fieldState } = useController(props);
  return (
    <>
      <input {...field} {...props}
             onFocus={(e) => (e.target.type = props.setfocustype ? props.setfocustype : 'text')}
              onBlur={(e) => (e.target.type = props.type ? props.type : 'text')}
      />
      {fieldState.error && (
        <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
      )}
    </>
  );
}

export default Input;
