import React from 'react';

export const BellIcon = () => {
  return (
    <svg id="bell" viewBox="0 0 12 14">
      <path d="M8.60066 11.5479C8.95558 12.3564 8.58839 13.3003 7.77898 13.6543C6.97051 14.0092 6.02754 13.6415 5.6731 12.8321C5.57776 12.6164 5.53906 12.3904 5.54189 12.1685L8.20139 11.0023C8.3661 11.1496 8.50533 11.3313 8.60066 11.5479Z"></path>
      <path d="M0.388684 7.32204C-0.361733 5.4342 0.0200829 3.63461 1.14193 2.45755C1.09568 2.38675 1.04896 2.32068 1.01403 2.2395C0.659116 1.43151 1.02677 0.489475 1.83524 0.135033C2.64371 -0.219881 3.58668 0.149192 3.9416 0.956715C3.97511 1.03317 3.99351 1.11246 4.01333 1.19128C5.69398 1.1573 7.35292 2.18051 8.21991 3.92109C8.21991 3.92109 9.50411 6.86943 10.9143 7.10399C10.9578 7.10635 11.7143 7.12523 11.9451 7.67648C12.1627 8.19799 11.7148 8.89602 10.9356 9.23159L2.4974 12.868C1.71914 13.2036 0.859227 13.0691 0.675163 12.5353C0.493459 12.0091 0.892265 11.5196 0.930021 11.4762C1.72622 10.2529 0.388684 7.32204 0.388684 7.32204Z"></path>
    </svg>
  );
};
