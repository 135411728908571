import React from 'react';

export const XlsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="550.801px"
      height="550.801px"
      viewBox="0 0 550.801 550.801"
    >
      <g>
        <path
          d="M488.432,197.019h-13.226v-63.816c0-0.398-0.063-0.799-0.111-1.205c-0.021-2.531-0.833-5.021-2.568-6.992L366.325,3.694
  c-0.032-0.031-0.063-0.042-0.085-0.076c-0.633-0.707-1.371-1.295-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.422
  c-0.676-0.366-1.393-0.675-2.131-0.896c-0.2-0.053-0.38-0.135-0.58-0.188C359.87,0.119,359.037,0,358.193,0H97.2
  c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.873v160.545
  c0,17.038,13.824,30.87,30.873,30.87h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601
  V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87v-160.54C519.297,210.832,505.48,197.019,488.432,197.019z M97.2,21.601
  h250.193v110.51c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.601z M339.562,354.344v31.324H236.509V220.704h37.46v133.64
  H339.562z M74.25,385.663l47.73-83.458l-46.019-81.501h42.833l14.439,30.099c4.899,10.03,8.572,18.116,12.49,27.414h0.483
  c3.926-10.529,7.101-17.872,11.259-27.414l13.954-30.099h42.588l-46.509,80.525l48.961,84.438h-43.081l-14.929-29.858
  c-6.115-11.507-10.036-20.07-14.684-29.615h-0.49c-3.431,9.55-7.583,18.119-12.722,29.615l-13.711,29.858H74.25V385.663z
  M453.601,523.353H97.2V419.302h356.4V523.353z M401.963,388.125c-18.837,0-37.446-4.904-46.738-10.04l7.578-30.839
  c10.04,5.136,25.46,10.283,41.375,10.283c17.139,0,26.188-7.099,26.188-17.867c0-10.283-7.831-16.157-27.659-23.256
  c-27.411-9.55-45.282-24.722-45.282-48.718c0-28.15,23.498-49.684,62.427-49.684c18.594,0,32.305,3.927,42.093,8.322l-8.322,30.109
  c-6.607-3.186-18.361-7.834-34.509-7.834c-16.152,0-23.983,7.338-23.983,15.913c0,10.525,9.291,15.18,30.591,23.258
  c29.125,10.769,42.836,25.936,42.836,49.191C468.545,364.627,447.257,388.125,401.963,388.125z"
        />
      </g>
    </svg>
  );
};
