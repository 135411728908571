import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import { PersonalSettingsForm } from '../../components/PersonalSettingsForm/PersonalSettings';

function PersonalSettings() {
  return (
    <section className="section-personal-settings">
      <AnimationWrapper className="container">
        <PersonalSettingsForm />
      </AnimationWrapper>
    </section>
  );
}

export default PersonalSettings;
