export const TRANSACTIONS_TYPES = [
  { label: 'Programs.allTypes', value: 'allTypes' },
  { label: 'Programs.replenishment', value: 'replenishment' },
  { label: 'Programs.writeOff', value: 'writeOff' },
];

export const TRANSACTIONS_CURRENCIES = [
  { label: 'Currencies.allCurrencies', value: 'all-currencies' },
  { label: 'eur', value: '22491dd5-e7a3-4834-b15e-ee393c1b30dd' },
  { label: 'usd', value: 'a6d8b3ad-e576-4568-8b42-feebb239b07e' },
];
