import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useConfirmEmailMutation } from '../../app/services/registration.api';
import Spinner from '../../components/Spinner/Spinner';
import { useAppDispatch } from '../../hooks/redux';
import { getUserToken } from '../../app/features/userToken';

function ConfirmEmail() {
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const [sendTokenRequest] = useConfirmEmailMutation();
  const cookies = new Cookies();
  const token = window.location.href.split('=')[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      sendTokenRequest({ token })
        .unwrap()
        .then((response) => {
          setIsSuccess(true);
          cookies.set('logged_in', response.status, {
            path: '/',
            //secure: true,
            expires: new Date(response.cookieExpired * 1000),
          });
          dispatch(getUserToken(cookies.get('logged_in')));
          navigate('/candidates/registration/offers');
          if (response.redirectToResend) {
            navigate('/candidates/expired-registration');
          }
        })
        .catch((error) => {
          console.error(error);
          navigate('/candidates/expired-registration');
        });
    }

    return () => {
      sendTokenRequest({ token });
    };
  }, []);

  return (
    <section className="confirm-email">{!isSuccess && <Spinner />}</section>
  );
}

export default ConfirmEmail;
