import React from 'react';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import UploadDocumentsForm from '../../components/UploadDocumentsForm/UploadDocumentsForm';

function DocumentsValidation() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <UploadDocumentsForm />
      </AnimationWrapper>
    </section>
  );
}

export default DocumentsValidation;
