import React from 'react';

interface Props {
  isActive?: boolean;
}
export const ZPlexIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5962 32.4581C39.4944 25.5599 39.4944 14.3757 32.5962 7.47744C25.698 0.579223 14.5137 0.579223 7.61551 7.47744C0.717295 14.3757 0.717295 25.5599 7.61551 32.4581C14.5137 39.3563 25.698 39.3563 32.5962 32.4581Z"
        fill={!isActive ? 'white' : '#B77C5B'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.46069"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.2643 36.6867C38.0199 36.6867 45.9284 28.7782 45.9284 19.0226C45.9284 9.26691 38.0199 1.3584 28.2643 1.3584C18.5086 1.3584 10.6001 9.26691 10.6001 19.0226C10.6001 28.7782 18.5086 36.6867 28.2643 36.6867Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.71756"
        stroke-miterlimit="10"
      />
      <path
        d="M42.3892 40.5948C49.2874 33.6966 49.2874 22.5124 42.3892 15.6142C35.491 8.71594 24.3067 8.71594 17.4085 15.6142C10.5103 22.5124 10.5103 33.6966 17.4085 40.5948C24.3067 47.493 35.491 47.493 42.3892 40.5948Z"
        fill={!isActive ? 'white' : '#B77C5B'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.46069"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.7213 32.8968C39.0715 23.429 33.3014 13.8486 23.8336 11.4984C14.3657 9.14831 4.78533 14.9184 2.4352 24.3862C0.0850626 33.8541 5.85512 43.4345 15.323 45.7846C24.7908 48.1347 34.3712 42.3647 36.7213 32.8968Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.71756"
        stroke-miterlimit="10"
      />
      <path
        d="M41.8915 28.1354C44.2592 18.6715 38.5065 9.08004 29.0426 6.71234C19.5786 4.34465 9.98719 10.0973 7.61949 19.5613C5.25179 29.0252 11.0044 38.6167 20.4684 40.9844C29.9324 43.3521 39.5238 37.5994 41.8915 28.1354Z"
        fill={!isActive ? 'white' : '#B77C5B'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.46069"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.7057 27.4811L25.7504 27.4472C25.7504 27.4472 30.8798 18.8529 30.9817 18.6151C31.0836 18.3433 31.1515 18.0376 31.1515 17.7319C31.1515 16.3391 30.0985 15.2181 28.7397 15.1162H20.8588C19.466 15.1842 18.379 16.3391 18.379 17.7319C18.379 19.1246 19.466 20.2796 20.8588 20.3475H23.95C23.95 20.3475 20.3832 25.9525 19.0244 28.0926C18.7187 28.5682 18.5149 28.9078 18.5149 28.9078C18.5149 28.9078 18.379 29.1796 18.345 29.3155C18.2771 29.5533 18.2431 29.7911 18.2431 30.0628C18.2431 31.5235 19.432 32.6785 20.8588 32.6785H28.7057V32.6445C29.9966 32.4747 30.9817 31.3876 30.9817 30.0628C30.9817 28.738 29.9966 27.651 28.7057 27.4811Z"
        fill={!isActive ? 'black' : 'white'}
      />
    </svg>
  );
};
