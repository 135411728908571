import React from 'react';

interface Props {
  isActive?: boolean;
}
export const ValidatePlatformIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="43"
      height="41"
      viewBox="0 0 43 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_318_277)">
        <path
          d="M8.14307 26.7799C12.7261 33.586 17.2065 27.1904 18.6087 24.5568C19.7032 22.5047 25.5858 9.47398 28.1167 3.86494C28.4587 3.0783 29.0402 2.42848 29.7242 1.98386C30.4082 1.53924 31.2291 1.26562 32.1183 1.26562H40.7713C40.9423 1.26562 41.0449 1.33403 41.1475 1.43663C41.2501 1.57344 41.2843 1.74445 41.1817 1.91545L24.423 38.0322C24.1836 38.511 23.8415 38.9215 23.4311 39.1951C22.9865 39.4687 22.4735 39.6397 21.9605 39.6397H15.8726C15.3254 39.6397 14.8466 39.5029 14.4019 39.1951C13.9573 38.9215 13.6153 38.511 13.3759 38.0322L8.14307 26.7799Z"
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.47749"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6089 24.5231C18.6773 24.3863 18.7799 24.1811 18.9167 23.9075C17.2066 20.1453 15.4623 16.2806 14.3337 13.8181C13.9575 12.9972 13.376 12.3132 12.6236 11.8686C11.9396 11.4582 11.1529 11.2188 10.3321 11.2188H1.71333C1.54232 11.2188 1.40551 11.3214 1.33711 11.424C1.26871 11.5608 1.23451 11.6976 1.30291 11.8686L8.31421 27.0198C12.8288 33.4155 17.2066 27.1566 18.6089 24.5231Z"
          fill={!isActive ? 'black' : 'white'}
          stroke={!isActive ? 'black' : 'white'}
          stroke-width="2.47749"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_277">
          <rect width="42.4782" height="40.8366" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
