import React from 'react';
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import FormBase from '../../FormBase/FormBase';
import { Header } from '../Header/Header';

export const FirstProgramInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSectionChange = (key: string) => {
    navigate(`/first/balance/${key}`);
  };

  const sections = [
    {
      name: 'Баланс',
      key: 'balance',
      items: [
        { name: 'Выписка общая', key: 'general-statement' },
        { name: 'Выписка по конто', key: 'account-statement' },
      ],
    },
  ];

  const activeSubTab = location.pathname.split('/')[3];

  if (!activeSubTab)
    return <Navigate to={'/first/balance/general-statement'} />;

  return (
    <div className="section-launch-product">
      <Header name="First" type="silver" />
      <FormBase
        sections={sections}
        onSectionChange={onSectionChange}
        activeSection="balance"
        activeSubSection={activeSubTab}
        isDropdown
      >
        <Outlet />
      </FormBase>
    </div>
  );
};
