import ProductInformationCard from '../ProductInformationCard/ProductInformationCard';
import { PARTNERS, SERVICES } from './PersonalCabinetInformation.model';
import { SearchInput } from '../SearchInput/SearchInput';
import React, { useMemo, useState } from 'react';
import { useDebounce } from '../../hooks';
import { SpecialCard } from '../SpecialCard/SpecialCard';
import ShieldWhite from '../../assets/media/specials/shield-white.svg';
import ThunderWhite from '../../assets/media/specials/thunder-white.svg';
import { ModalPartnerWindow } from '../ModalPartnerWindow/ModalPartnerWindow';
import { useTranslation } from 'react-i18next';

function PersonalCabinetInformation() {
  const [searchValue, setSearchValue] = useState('');
  const [openedPartnerModal, setOpenedPartnerModal] = useState<
    'golden' | 'silver' | null
  >(null);
  const debouncedValue = useDebounce(searchValue, 500);

  const { t } = useTranslation();
  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const filteredSearch = useMemo(
    () =>
      SERVICES.filter(({ name }) =>
        name.toLowerCase().includes(debouncedValue.toLowerCase()),
      ),
    [debouncedValue],
  );

  const onToggleModal = (key: 'golden' | 'silver') => {
    setOpenedPartnerModal((prevState) => (prevState ? null : key));
  };

  return (
    <div className="section-personal-cabinet">
      <h1 className="section-personal-cabinet-title">
        Оцените удобство работы с нашими сервисами
      </h1>
      <ModalPartnerWindow
        onClose={() => onToggleModal('golden')}
        isShown={openedPartnerModal === 'silver'}
        logo={ThunderWhite}
        redirectTo={'/first/balance/general-statement'}
        type="silver"
        paragraphsProgram={
          <>
            <p>{t('Programs.silver.aboutProgram.paragraph_1')}</p>
            <p>{t('Programs.silver.aboutProgram.paragraph_2')}</p>
          </>
        }
        name={'First'}
        paragraphsAbout={
          <>
            <p>{t('Programs.silver.details.paragraph_2')}</p>
            <p>{t('Programs.silver.details.paragraph_2')}</p>
            <p>{t('Programs.silver.details.paragraph_2')}</p>
          </>
        }
        prefaceAbout={t('Programs.silver.details.mainInformation')}
        prefaceProgram={t('Programs.silver.aboutProgram.mainInformation')}
        subtitle={t('Programs.silver.subtitle')}
        address={'Henville Building, Suite 7'}
        city={'Nevis, Charlestown,'}
        partnerName={'7 FLAGS CLUB, LTD'}
        registrationNumber={'С51466'}
        sideDropdownOptions={[
          {
            name: 'Programs.email',
            value: 'info@7flags.com',
            type: 'mail',
            key: 'email',
          },
          {
            name: 'Programs.phone',
            value: '+44(20)808-999-40',
            type: 'tel',
            key: 'phone',
          },
        ]}
      />
      <ModalPartnerWindow
        onClose={() => onToggleModal('golden')}
        isShown={openedPartnerModal === 'golden'}
        logo={ShieldWhite}
        redirectTo={'/goldenShield/balance/general-statement'}
        type="golden"
        paragraphsProgram={
          <>
            <p>{t('Programs.golden.aboutProgram.paragraph_1')}</p>
            <p>{t('Programs.golden.aboutProgram.paragraph_2')}</p>{' '}
            <p>{t('Programs.golden.aboutProgram.paragraph_3')}</p>
          </>
        }
        name={'Golden Shield'}
        paragraphsAbout={
          <>
            <p>{t('Programs.golden.details.paragraph_1')}</p>
            <p>{t('Programs.golden.details.paragraph_2')}</p>
            <p>{t('Programs.golden.details.paragraph_3')}</p>
          </>
        }
        prefaceAbout={t('Programs.golden.details.mainInformation')}
        prefaceProgram={t('Programs.golden.aboutProgram.mainInformation')}
        subtitle={t('Programs.golden.subtitle')}
        address={'Henville Building, Suite 7'}
        city={'Nevis, Charlestown,'}
        partnerName={'7 FLAGS CLUB, LTD'}
        registrationNumber={'С51466'}
        sideDropdownOptions={[
          {
            name: 'Programs.email',
            value: 'info@7flags.com',
            type: 'mail',
            key: 'email',
          },
          {
            name: 'Programs.phone',
            value: '+44(20)808-999-40',
            type: 'tel',
            key: 'phone',
          },
        ]}
      />
      <section>
        <div className={'section-personal-cabinet__section-search-input'}>
          <SearchInput
            onChange={onSearch}
            value={searchValue}
            placeholder="Search"
          />
        </div>
        <div className="section-personal-cabinet__section-items">
          {filteredSearch.map(({ icon: Icon, name, title, to }) => {
            return (
              <ProductInformationCard
                icon={(isHovered) => {
                  return <Icon isActive={isHovered} />;
                }}
                name={title}
                type="extended"
                title={name}
                to={to}
              />
            );
          })}
        </div>
      </section>
      <section className="section-personal-cabinet__section">
        <h4 className="section-personal-cabinet__section-title">Specials</h4>
        <div className="section-personal-cabinet__section-cards">
          <SpecialCard
            icon={ShieldWhite}
            name={'Golden shield'}
            title={'Work with valuable metal'}
            type="golden"
            onClick={() => onToggleModal('golden')}
          />{' '}
          <SpecialCard
            icon={ThunderWhite}
            name={'First'}
            title="Join aba marketing innovation"
            type="silver"
            onClick={() => onToggleModal('silver')}
          />
        </div>
      </section>
      <section className="section-personal-cabinet__section">
        <h4 className="section-personal-cabinet__section-title">
          Partner products
        </h4>
        <div className="section-personal-cabinet__section-items">
          {PARTNERS.map(({ icon: Icon, name }) => {
            return (
              <ProductInformationCard
                icon={(isHovered) => {
                  return <Icon isActive={isHovered} />;
                }}
                name={name}
                type="small"
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default PersonalCabinetInformation;
