import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (
    language: string,
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    i18n.changeLanguage(language);
  };

  const languageToggle = () => {
    switch (i18n.language) {
      case 'ru':
        return (
          <a
            className="nav__link link"
            href="/"
            onClick={(e) => changeLanguage('en', e)}
          >
            En
          </a>
        );
      case 'en':
        return (
          <a
            className="nav__link link"
            href="/"
            onClick={(e) => changeLanguage('ru', e)}
          >
            Ru
          </a>
        );
      default:
        return (
          <a
            className="nav__link link"
            href="/"
            onClick={(e) => changeLanguage('ru', e)}
          >
            En
          </a>
        );
    }
  };
  return <>{languageToggle()}</>;
}

export default LanguageSwitcher;
