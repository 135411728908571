import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoBlack } from '../../assets/media/logo-black.svg';

import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import Logout from '../../components/Logout';
import { NavLink } from 'react-router-dom';

interface Props {
  classes?: string;
}
function Header({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <header className="header">
      <nav className={`nav nav--launch has-notification ${classes}`}>
        <div className="wrapper">
          <div className="container">
            <div className="nav__inner">
              <div className="nav__logo-container">
                <a className="nav__logo" href="/">
                  <LogoBlack />
                </a>
              </div>

              <ul className="nav__links">
                <li className="nav__links-item">
                  <LanguageSwitcher />
                </li>
                <li className="nav__links-item">
                  <NavLink className="nav__link link" to="goldenShield/balance/general-statement">
                    {t('Programs.myContracts')}
                  </NavLink>
                </li>
                <li className="nav__links-item">
                  <NavLink className="nav__link link" to="/candidates/settings">
                    {t('Header.settings')}
                  </NavLink>
                </li>
                <li className="nav__links-item">
                  <NavLink className="nav__link link" to="/candidates/invitation">
                    {t('Header.invitation')}
                  </NavLink>
                </li>
                <li className="nav__links-item">
                  <a className="nav__link link" href="/">
                    {t('Header.support')}
                  </a>
                </li>
                <li className="nav__links-item">
                  <Logout />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
