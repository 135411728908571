import BackButton from '../BackButton/BackButton';
import FormBase from '../FormBase/FormBase';
import RadioButton from '../RadioButton/RadioButton';
import Input from '../Input/Input';
import Select from 'react-select';
import { CITIZENSHIP, COUNTRIES, SEX, PERSONAL_IDENTIFICATIONS } from '../Select/Select.model';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { CheckCard } from '../CheckCard/CheckCard';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PersonalDataTypeFormInterface } from '../../interfaces/personalData.interface';
import DefaultTitle from '../Titles/Default';
import { useNavigate } from 'react-router-dom';
import { useGetCountryListQuery } from '../../app/services/country.api';
import { CountryModel } from '../CountryList/CountryList.model';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePostUserMutation,
  usePostCompanyMutation,
} from '../../app/services/personal-data.api';
import moment from 'moment';
import Loader from 'react-loader-advanced';

function PersonalDataForm() {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);

  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(false);

  const [registration, setRegistration] = useState(false);

  const [postUser] = usePostUserMutation();
  const [postCompany] = usePostCompanyMutation();

  interface DocumentSelect {
    value: string;
    label: string;
  }

  const navigate = useNavigate();


  const changeRadio = () => {
    setToggle((prev) => !prev);
    // createAsideMenu();
  };
  const { data } = useGetCountryListQuery();
  interface Citizenship {
    value: string;
    label: string;
  }
  let citezenship: Citizenship[] = [];
  if (data) {
    citezenship = data?.map((country: any) => {
      return {
        label: country.name,
        value: country.id,
      };
    });
  }
  type Sex = {
    label: string;
    value: number;
  };
  const PersonalDataSchema = yup.object({
    companyName: toggle
      ? yup.string()
      : yup
          .string()
          .required(t('Validators.notEmptyField'))
          .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Please enter your full name.'),
    companyRegistrationNumber: toggle
      ? yup.string()
      : yup
          .string()
          .required(t('Validators.notEmptyField'))
          .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    companyLegalDocument: toggle
      ? yup.string()
      : yup
          .string()
          .required(t('Validators.notEmptyField'))
          .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    // step: yup.string().required('Required4'),
    firstName: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    lastName: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    // sex: yup.string().required('Required7'),
    birthDate: yup
      .string()
      // Check is user older then 18 years
      .test('DOB', t('Validators.tooYoung'), (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      })
      .required('Required8'),
    citizenCountryId: yup.string().required(t('Validators.notEmptyField')),
    documentNumber: yup
      .string()
      .required(t('Validators.notEmptyField'))
      //Match only latin letters, symbols and digits
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    documentExpiredDate: yup
      .string()
      .test('DOB', t('Validators.tooShort'), (value) => {
        return moment().diff(moment(value), 'days') <= -181;
      })
      .required('Required11'),
    registrationAddressCountryId: yup
      .string()
      .required(t('Validators.notEmptyField')),
    registrationAddressPostalCode: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressCity: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressStreet: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    registrationAddressHouse: yup
      .string()
      .required(t('Validators.notEmptyField'))
      .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    isRegistrationAddressSameWithPostal: yup
      .boolean()
      .required(t('Validators.notEmptyField')),
    postalAddressCountryId: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          t('Validators.onlyLatin'),
        ),
    }),
    postalAddressPostalCode: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressCity: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressStreet: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
    postalAddressHouse: yup.string().when('hasCorrespondenceAddress', {
      is: true,
      then: yup
        .string()
        .required(t('Validators.notEmptyField'))
        .matches(/^[a-zA-Z0-9- ]*$/, t('Validators.onlyLatin')),
    }),
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<PersonalDataTypeFormInterface>({
    defaultValues: {
      companyName: '',
      companyRegistrationNumber: '',
      companyLegalDocument: '',
      // step: '',
      documentType: '',
      firstName: '',
      lastName: '',
      sex: 0,
      birthDate: '',
      citizenCountryId: '',
      documentNumber: '',
      documentExpiredDate: '',
      registrationAddressCountryId: '',
      registrationAddressPostalCode: '',
      registrationAddressCity: '',
      registrationAddressStreet: '',
      registrationAddressHouse: '',
      isRegistrationAddressSameWithPostal: true,
      postalAddressCountryId: '',
      postalAddressPostalCode: '',
      postalAddressCity: '',
      postalAddressStreet: '',
      postalAddressHouse: '',
    },
    resolver: yupResolver(PersonalDataSchema),
    mode: 'onChange',
  });

  const onSelectChange = (
    name: keyof PersonalDataTypeFormInterface,
    value: any,
  ) => {
    setValue(name, value.value);
    setRerender((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<PersonalDataTypeFormInterface> = async (
    formData: PersonalDataTypeFormInterface,
  ) => {
    //Here should call to backend API
    PersonalDataSchema.validate(formData)
      .then((data) => {
        if (toggle) {
          // delete unnececery fields
          delete formData.companyRegistrationNumber;
          delete formData.companyLegalDocument;
          delete formData.companyName;
        }
        if (!registration) {
          //delete unnececery fields from postal address
          delete formData.postalAddressCountryId;
          delete formData.postalAddressPostalCode;
          delete formData.postalAddressCity;
          delete formData.postalAddressStreet;
          delete formData.postalAddressHouse;
        }
        if (toggle) {
          setLoading(true);
          postUser({ ...formData })
            .unwrap()
            .then((data) => {
              setLoading(false);
              navigate('/candidates/upload-documents');
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        } else {
          setLoading(true);
          postCompany({ ...formData })
            .unwrap()
            .then((data) => {
              setLoading(false);
              navigate('/candidates/upload-documents');
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // navigate('/candidates/upload-documents');
  };
  const changeRegistration = () => {
    setRegistration((prev) => !prev);
    setValue('isRegistrationAddressSameWithPostal', registration);
  };
  const onSectionChange = (id: string) => {
    const section = document.getElementById(id) as HTMLElement;
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    console.log(section);
  };
  const value = getValues();
  const formBaseSections = [
    {
      name: t('PersonalDataType.title'),
      key: 'form-base-registration_type',
    },
    {
      name: t('PersonalDataType.inputTitleVariantNo'),
      key: 'form-base-company',
    },
    {
      name: t('RegistrationPassportData.title'),
      key: 'form-base-passport_data',
    },
    {
      name: t('RegistrationAddress.title'),
      key: 'form-base-registration_address',
    },
    {
      name: t('RegistrationAddress.title') + ' 2',
      key: 'form-base-registration_address_2',
    },
  ];
  const createAsideMenu = () => {
    let tempResult = formBaseSections;
    if (toggle) {
      tempResult = tempResult.filter((item) => {
        return item.key !== 'form-base-company';
      });
      console.log(tempResult);
    }
    if (!registration) {
      tempResult = tempResult.filter((item) => {
        return item.key !== 'form-base-registration_address_2';
      });
    }
    return tempResult;
  };
  return (
    <Loader show={loading} message={'Загрузка...'}>
      <div className="section-personal-data">
        <BackButton className="back-button">
          {t('Button.backButton')}
        </BackButton>
        <DefaultTitle>{t('SignDocuments.title')}</DefaultTitle>
        <div className="personalData__main">
          <FormBase
            sections={createAsideMenu()}
            onSectionChange={onSectionChange}
          >
            <div className="main-launch-product__inner">
              <div className="section-launch-product-main">
                <form
                  className="personal__form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h2
                    id="form-base-registration_type"
                    className="form-general__section personal__form-heading"
                  >
                    {t('PersonalDataType.title')}
                  </h2>
                  <p className="personal__form-subtitle">
                    {t('PersonalDataType.subtitle')}
                  </p>

                  <div className="personal-inner">
                    <RadioButton
                      children={t('PersonalDataType.inputLabelType')}
                      onClick={changeRadio}
                      checked={toggle}
                      yesId="step-2-radiobutton3"
                      noId="step-2-radiobutton4"
                    />
                    {!toggle && (
                      <>
                        <h2
                          id="form-base-company"
                          className="personal__form-heading"
                        >
                          {t('PersonalDataType.inputTitleVariantNo')}
                        </h2>
                        <div className="personal-input__wrapper">
                          <Input
                            className="input input-default"
                            name="companyName"
                            placeholder={t(
                              'PersonalDataType.inputLabelNameCompany',
                            )}
                            control={control}
                          />
                        </div>
                        <div className="personal-input__wrapper">
                          <Input
                            className="input input-default"
                            name="companyRegistrationNumber"
                            placeholder={t(
                              'PersonalDataType.inputLabelRegistrationNumber',
                            )}
                            control={control}
                          />
                        </div>

                        <div className="personal-input__wrapper">
                          <Input
                            className="input input-default"
                            name="companyLegalDocument"
                            placeholder={t(
                              'PersonalDataType.inputLabelSignDocument',
                            )}
                            control={control}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <h2
                    id="form-base-passport_data"
                    className="form-general__section personal__form-heading"
                  >
                    {t('PersonalPassportData.title')}
                  </h2>

                  <div className="personal__type-inner">
                    <div className="personal__wrapper-fields">
                      <div className="personal-input__wrapper">
                        <Select<DocumentSelect>
                          isSearchable={false}
                          options={PERSONAL_IDENTIFICATIONS.map((item:{label: string, value: string}) => ({
                            label: t(item.label),
                            value: item.value,
                          }) as DocumentSelect)}
                          placeholder={'Тип документа'}
                          onChange={selectedOption => onSelectChange(
                            'documentType', selectedOption,
                          )}
                          // value={value.personal_verification_2_type}
                        />
                      </div>
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="lastName"
                          placeholder={t(
                            'PersonalPassportData.inputLabelSurname',
                          )}
                          control={control}
                        />
                      </div>

                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="firstName"
                          placeholder={t('PersonalPassportData.inputLabelName')}
                          control={control}
                        />
                      </div>
                    </div>

                    <div className="personal__wrapper-fields">
                      <div className="personal-input__wrapper">
                        <Select<Sex>
                          options={SEX}
                          placeholder={t('PersonalPassportData.inputLabelSex')}
                          onChange={(selectedOption) =>
                            onSelectChange('sex', selectedOption)
                          }
                          //value={value.sex}
                        />
                        {errors.sex?.type === 'sexValidation' && (
                          <ErrorMessage>{errors.sex.message}</ErrorMessage>
                        )}
                      </div>

                      <div className="personal-input__wrapper">
                        <Input
                          type="text"
                          className="input input-default"
                          name="birthDate"
                          placeholder={t(
                            'PersonalPassportData.inputLabelBirthday',
                          )}
                          control={control}
                          setfocustype="date"
                        />
                      </div>
                    </div>

                    <div className="personal-input__wrapper">
                      <Select<CountryModel>
                        options={citezenship}
                        placeholder={t(
                          'PersonalPassportData.inputLabelCitizenship',
                        )}
                        onChange={(selectedOption) =>
                          onSelectChange('citizenCountryId', selectedOption)
                        }
                        //onChange={onSelectChange('citizenship')}
                        // value={value.citizenship.toString()}
                      />
                    </div>
                    {errors.citizenCountryId?.type ===
                      'citizenshipValidation' && (
                      <ErrorMessage>
                        {errors.citizenCountryId.message}
                      </ErrorMessage>
                    )}

                    <div className="personal__wrapper-fields">
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="documentNumber"
                          placeholder={t(
                            'PersonalPassportData.inputLabelDocumentNumber',
                          )}
                          control={control}
                        />
                      </div>

                      <div className="personal-input__wrapper">
                        <Input
                          type="text"
                          setfocustype="date"
                          className="input input-default"
                          name="documentExpiredDate"
                          placeholder={t(
                            'PersonalPassportData.inputLabelValidityPeriod',
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>

                  <h2
                    id="form-base-registration_address"
                    className="form-general__section personal__form-heading"
                  >
                    {t('PersonalAddress.title')}
                  </h2>

                  <div className="personal__type-inner">
                    <div className="personal-input__wrapper">
                      <Select<CountryModel>
                        options={citezenship}
                        placeholder={t('PersonalAddress.inputLabelCountry')}
                        onChange={(selectedOption) =>
                          onSelectChange(
                            'registrationAddressCountryId',
                            selectedOption,
                          )
                        }
                        // onChange={onSelectChange('country')}
                        // value={value.country.toString()}
                      />
                    </div>
                    <div className="personal-input__wrapper">
                      <Input
                        className="input input-default"
                        name="registrationAddressPostalCode"
                        placeholder={t('PersonalAddress.inputLabelIndex')}
                        control={control}
                      />
                    </div>
                    <div className="personal-input__wrapper">
                      <Input
                        className="input input-default"
                        name="registrationAddressCity"
                        placeholder={t('PersonalAddress.inputLabelCity')}
                        control={control}
                      />
                    </div>
                    <div className="personal-input__wrapper">
                      <Input
                        className="input input-default"
                        name="registrationAddressStreet"
                        placeholder={t('PersonalAddress.inputLabelStreet')}
                        control={control}
                      />
                    </div>
                    <div className="personal-input__wrapper">
                      <Input
                        className="input input-default"
                        name="registrationAddressHouse"
                        placeholder={t('PersonalAddress.inputLabelHouse')}
                        control={control}
                      />
                    </div>
                  </div>

                  <div className="personal__checkbox-wrapper">
                    <CheckCard
                      value={registration}
                      selected={registration}
                      onClick={changeRegistration}
                      type="large"
                      additionalHeaderTitle={t(
                        'PersonalAddress.inputSwitcherDescr',
                      )}
                    >
                      {' '}
                    </CheckCard>
                  </div>

                  {registration && (
                    <div className="personal__type-inner">
                      <h2
                        id="form-base-registration_address_2"
                        className="form-general__section personal__form-heading"
                      >
                        {t('PersonalAddress.title')}
                      </h2>
                      <div className="personal-input__wrapper">
                        <Select<CountryModel>
                          options={citezenship}
                          placeholder={t('PersonalAddress.inputLabelCountry')}
                          onChange={(selectedOption) =>
                            onSelectChange(
                              'postalAddressCountryId',
                              selectedOption,
                            )
                          }
                          //onChange={onSelectChange('citizenship')}
                          // value={value.citizenship.toString()}
                        />
                      </div>
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="postalAddressPostalCode"
                          placeholder={t('PersonalAddress.inputLabelIndex')}
                          control={control}
                        />
                      </div>
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="postalAddressCity"
                          placeholder={t('PersonalAddress.inputLabelCity')}
                          control={control}
                        />
                      </div>
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="postalAddressStreet"
                          placeholder={t('PersonalAddress.inputLabelStreet')}
                          control={control}
                        />
                      </div>
                      <div className="personal-input__wrapper">
                        <Input
                          className="input input-default"
                          name="postalAddressHouse"
                          placeholder={t('PersonalAddress.inputLabelHouse')}
                          control={control}
                        />
                      </div>
                    </div>
                  )}

                  <div className="personal__form-buttons">
                    <BackButton className="btn btn--black">
                      {t('Button.backButton')}
                    </BackButton>
                    <Button type="submit">
                      {t('PersonalPassportData.formButton')}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <aside className="section-launch-product-right">
              <div className="section-launch-product-right__section">
                <p className="section-launch-product-right__section-heading">
                  {t('ForPartners.header')}
                </p>
              </div>
              <div className="section-launch-product-right__section-content">
                <div className="section-launch-product-right__section-content-text">
                  <p>{t('ForPartners.description')}</p>
                </div>
              </div>
            </aside>
          </FormBase>
        </div>
      </div>
    </Loader>
  );
}

export default PersonalDataForm;

