import { configureStore } from '@reduxjs/toolkit';
import { registrationCandidatesApi } from './services/registration.api';
import { userLoginApi } from './services/login.api';
import userTokenReducer from './features/userToken';
import { countryApi } from './services/country.api';
import { citizenshipsApi } from './services/citizenships';
import { programApi } from './services/program.api';
import { documentApi } from './services/document.api';
import { personalDataApi } from './services/personal-data.api';
import { personIdApi } from './services/personId.api';
import personIdentifierReducer from './features/personIdentifier';
import { personFirstLineApi } from './services/personFirstLine.api';
import { personLedgersApi } from './services/personLedgers.api';
import { invitationApplyApi } from './services/invitationApply.api';

export const store = configureStore({
  reducer: {
    [userLoginApi.reducerPath]: userLoginApi.reducer,
    [registrationCandidatesApi.reducerPath]: registrationCandidatesApi.reducer,
    [registrationCandidatesApi.reducerPath]: registrationCandidatesApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [citizenshipsApi.reducerPath]: citizenshipsApi.reducer,
    [programApi.reducerPath]: programApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [personalDataApi.reducerPath]: personalDataApi.reducer,
    [personIdApi.reducerPath]: personIdApi.reducer,
    [personFirstLineApi.reducerPath]: personFirstLineApi.reducer,
    [personLedgersApi.reducerPath]: personLedgersApi.reducer,
    [invitationApplyApi.reducerPath]: invitationApplyApi.reducer,

    userToken: userTokenReducer,
    personIdentifier: personIdentifierReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userLoginApi.middleware)
      .concat(registrationCandidatesApi.middleware)
      .concat(countryApi.middleware)
      .concat(citizenshipsApi.middleware)
      .concat(programApi.middleware)
      .concat(documentApi.middleware)
      .concat(personalDataApi.middleware)
      .concat(personIdApi.middleware)
      .concat(personIdApi.middleware)
      .concat(personFirstLineApi.middleware)
      .concat(personLedgersApi.middleware)
      .concat(invitationApplyApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
