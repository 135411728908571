import React from 'react';

interface Props {
  isActive?: boolean;
}
export const EurosimIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="30"
      height="37"
      viewBox="0 0 30 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.54035 24.2208C2.63873 23.7099 1.50343 21.7515 1.98593 19.8499L6.01623 4.66535C6.52711 2.76373 8.4855 1.62843 10.3871 2.11093C12.2887 2.62181 13.424 4.5802 12.9415 6.48182L8.91123 21.6664C8.40035 23.568 6.44197 24.7033 4.54035 24.2208Z"
        fill={!isActive ? 'white' : 'transparent'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.19291"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66699 31.3447L4.54023 24.2207"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.19291"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.8773 35.6024H9.64915C8.96798 35.6024 8.42871 35.0631 8.42871 34.3819V9.20677C8.42871 8.52559 8.96798 7.98633 9.64915 7.98633H21.5698C22.0523 7.98633 22.5348 8.185 22.8753 8.52559L27.5868 13.5493C27.9274 13.8899 28.1261 14.3724 28.1261 14.8549V34.3819C28.0977 35.0631 27.5584 35.6024 26.8773 35.6024Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.19291"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.7041 33.303H13.8204C12.0607 33.303 10.6699 31.7987 10.6699 29.9822V17.3237C10.6699 15.4788 12.089 14.0029 13.8204 14.0029H22.7041C24.4638 14.0029 25.8545 15.5072 25.8545 17.3237V29.9822C25.8545 31.7987 24.4354 33.303 22.7041 33.303Z"
        fill={!isActive ? 'white' : '#D4A387'}
      />
      <path
        d="M26.7933 27.6553H9.73544C9.33809 27.6553 9.02588 27.3431 9.02588 26.9457V20.3326C9.02588 19.9353 9.33809 19.623 9.73544 19.623H26.7933C27.1906 19.623 27.5028 19.9353 27.5028 20.3326V26.9457C27.5028 27.3431 27.1622 27.6553 26.7933 27.6553ZM9.05426 26.2645H27.5028V21.0422H9.05426V26.2645Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.954385"
        stroke-miterlimit="10"
      />
      <path
        d="M15.4688 20.5026C15.0715 20.5026 14.7593 20.1904 14.7593 19.7931V14.2017C14.7593 13.8044 15.0715 13.4922 15.4688 13.4922C15.8662 13.4922 16.1784 13.8044 16.1784 14.2017V19.7931C16.15 20.1904 15.8378 20.5026 15.4688 20.5026Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.954385"
        stroke-miterlimit="10"
      />
      <path
        d="M21.0592 20.5026C20.6618 20.5026 20.3496 20.1904 20.3496 19.7931V14.2017C20.3496 13.8044 20.6618 13.4922 21.0592 13.4922C21.4565 13.4922 21.7687 13.8044 21.7687 14.2017V19.7931C21.7687 20.1904 21.4565 20.5026 21.0592 20.5026Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.954385"
        stroke-miterlimit="10"
      />
      <path
        d="M15.4688 33.8142C15.0715 33.8142 14.7593 33.502 14.7593 33.1047V27.485C14.7593 27.0876 15.0715 26.7754 15.4688 26.7754C15.8662 26.7754 16.1784 27.0876 16.1784 27.485V33.0763C16.15 33.502 15.8378 33.8142 15.4688 33.8142Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.954385"
        stroke-miterlimit="10"
      />
      <path
        d="M21.0592 33.8142C20.6618 33.8142 20.3496 33.502 20.3496 33.1047V27.485C20.3496 27.0876 20.6618 26.7754 21.0592 26.7754C21.4565 26.7754 21.7687 27.0876 21.7687 27.485V33.0763C21.7687 33.502 21.4565 33.8142 21.0592 33.8142Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.954385"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
