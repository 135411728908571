import Input from '../Input/Input';
import { useForm } from 'react-hook-form';
import { InvitationInterface } from '../../interfaces/invitation.interface';
import { INVITE_METHODS } from './inviteList.model';
import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import  axiosApi  from '../../helpers/axios.utils';
import { useModal } from '../../hooks/useModal';
import ModalWindow from '../ModalWindow/ModalWindow';
import { AxiosError } from "axios";

export const InviteList = () => {
  const [activeMethod, setActiveMethod] = useState(INVITE_METHODS[0].key);
  const [openTableTabId, setOpenTableTabId] = useState('');
  const { isShown, toggle } = useModal();
  const [modalMessage, setModalMessage] = useState('');
  const { t } = useTranslation();

  const inviteListSchema = yup.object({
    value: yup
      .string()
      .required(
        t(activeMethod === 'email' ? 'Form.wrongEmail' : 'Form.wrongPhone'),
      ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InvitationInterface>({
    defaultValues: {
      value: '',
    },
    mode: 'onChange',
    resolver: yupResolver(inviteListSchema),
  });

  const onSubmit = async (data: InvitationInterface) => {
    let response
    try {
      if (activeMethod === 'email') {
        const payload = {
          email: data.value,
        };
        response = await axiosApi.post('invites/by_email', payload);
      }
      if (activeMethod === 'phone') {
        //remove first + from phone number if it is present
        const phoneNumber = data.value.replace(/^\+/, '');
        const payload = {
          phoneNumber: phoneNumber,
        };
        response = await axiosApi.post('invites/by_phone_number', payload);

      }
    } catch (e: any) {
      console.log(e?.response.status)
      if (e?.response.status === 400) {
        setModalMessage(t('InviteList.alreadyRegistered'));
        toggle();
      } else {
        toggle();
        setModalMessage('Ошибка отправки запроса, попробуйте позже');
      }

    }

    console.log(response);
    if (response && response.status === 204) {
      toggle();
      setModalMessage('Ваш запрос отправлен');
    }
  };

  const onChangeTableTabId = (key: string) => {
    setOpenTableTabId((prevState) => (prevState === key ? '' : key));
  };
  console.log('errors.value', errors.value);

  return (
    <>
      <ModalWindow
        isShown={isShown}
        hide={toggle}
        modalContent={modalMessage}
      />
      <div className="section-launch-invite-list">
        <aside className="section-launch-invite-list-left">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="section-launch-invite-list__block"
          >
            <h4 className="section-launch-invite-list__block-title">
              Пригласить человека
              <a href="" className="section-launch-invite-list__block-link">
                Видео инструкция
              </a>
            </h4>
            <div className="section-launch-invite-list__method-sending-choice">
              {INVITE_METHODS.map(({ label, key }) => {
                return (
                  <div
                    className={`
                      section-launch-invite-list__method-sending-choice-item
                      ${activeMethod === key ? 'active' : ''}
                    `}
                    key={key}
                    onClick={() => setActiveMethod(key)}
                  >
                    {<span>{t(`Invitation.${label}`)}</span>}
                  </div>
                );
              })}
            </div>
            <Input
              className={`main-launch__form-input ng-pristine ng-valid ${
                errors.value?.message ? 'input-error' : ''
              }`}
              name="value"
              placeholder="Номер"
              control={control}
            />
            <Button
              classNames={'section-launch-invite-list__btn-inside btn btn--black'}
            >
              <span>{t('Invitation.sendInvitation')}</span>
            </Button>
          </form>
          <p className="section-launch-invite-list__description">
            Если человек, долго бездействует после приглашения и нет никакой
            активности в течении 14 дней, будет включен таймер на 30 дней. После,
            заявка будет удалена.
          </p>
        </aside>
        <section className="section-launch-invite-list-main section-launch-main">
          <table className="section-launch-invite-list-main__table">
            <thead>
              <tr className="dropdown">
                <td>
                  <span>{t('Invitation.issuedTo')}</span>
                </td>
                <td>
                  <span>{t('Invitation.progress')}</span>
                </td>
                <td>
                  <span>{t('Invitation.status')}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                className={`dropdown ${openTableTabId === '1' ? 'is-open' : ''}`}
              >
                <td>
                  <span>sevenflagsclub-test-31@dev24solutions.com</span>
                </td>{' '}
                <td>
                  <div className="section-launch-invite-list-main__table-progress">
                    <div className="section-launch-invite-list-main__table-progress-item">
                      <div className="section-launch-invite-list-main__table-progress-title">
                        <span>{t('Invitation.documents')}</span>
                      </div>
                      <div className="section-launch-invite-list-main__table-progress-content dropdown__content">
                        <div className="checkbox-status active" />
                      </div>
                    </div>
                    <div className="section-launch-invite-list-main__table-progress-item section-launch-invite-list-main__table-progress-item--not-filled">
                      <div className="section-launch-invite-list-main__table-progress-title">
                        <span>{t('Invitation.questionnaire')}</span>
                      </div>
                      <div className="section-launch-invite-list-main__table-progress-content section-launch-invite-list-main__table-progress-content--has-text dropdown__content">
                        0%
                      </div>
                    </div>
                    <div className="section-launch-invite-list-main__table-progress-item">
                      <div className="section-launch-invite-list-main__table-progress-title">
                        <span>{t('Invitation.program')}</span>
                      </div>
                      <div className="section-launch-invite-list-main__table-progress-content dropdown__content">
                        <div className="checkbox-status active" />
                      </div>
                    </div>
                    <div className="section-launch-invite-list-main__table-progress-item section-launch-invite-list-main__table-progress-item--not-filled">
                      <div className="section-launch-invite-list-main__table-progress-title">
                        <span>{t('Invitation.invoice')}</span>
                      </div>
                      <div className="section-launch-invite-list-main__table-progress-content section-launch-invite-list-main__table-progress-content--has-text dropdown__content">
                        0%
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="section-launch-invite-list-main__table-progress-status section-launch-invite-list-main__table-progress-status--ok">
                    Ссылка отправлена
                  </span>
                </td>
                <td>
                  <button
                    className="section-launch-invite-list-main__table-progress-show"
                    onClick={() => onChangeTableTabId('1')}
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
};
