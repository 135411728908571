interface Props {
  readOnly?: boolean;
  value: boolean;
  disabled?: boolean;
  onChange?: () => void;
}
export const Checkbox = ({ readOnly, value, disabled, onChange }: Props) => {
  return (
    <div className="checkbox-main">
      <input
        type="checkbox"
        id="check"
        className="checkbox-main-input"
        name="check"
        checked={value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
      />
      <label className="checkbox-main-label" htmlFor="check" />
    </div>
  );
};
