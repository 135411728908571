import React from 'react';

interface Props {
  isActive?: boolean;
}
export const CalendarIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9814 5.13071H28.6136V2.36778C28.6136 1.60182 27.9844 1 27.2458 1H25.878C25.1121 1 24.5102 1.62918 24.5102 2.36778V5.13071H10.6956V2.36778C10.6956 1.60182 10.0664 1 9.32783 1H7.96005C7.19409 1 6.59226 1.62918 6.59226 2.36778V5.13071H5.22448C2.89925 5.13071 1.03906 6.99089 1.03906 9.26141V29.9149C1.03906 32.1855 2.89925 34.0456 5.16977 34.0456H29.9814C32.2519 34.0456 34.1121 32.1855 34.1121 29.9149V9.26141C34.1121 6.99089 32.2519 5.13071 29.9814 5.13071ZM32.3887 29.5867C32.3887 31.0639 31.185 32.2675 29.7078 32.2675H5.44333C3.96612 32.2675 2.76247 31.0639 2.76247 29.5867V14.3496H32.3887V29.5867Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.577507"
        stroke-miterlimit="10"
      />
      <path
        d="M31.1848 22.3379C27.1362 22.3379 23.8535 25.6206 23.8535 29.6692C23.8535 33.7178 27.1362 37.0005 31.1848 37.0005C35.2335 37.0005 38.5162 33.7178 38.5162 29.6692C38.5435 25.6479 35.2335 22.3379 31.1848 22.3379ZM31.1848 34.5932C28.4766 34.5932 26.2608 32.3774 26.2608 29.6692C26.2608 26.961 28.4766 24.7452 31.1848 24.7452C33.893 24.7452 36.1089 26.961 36.1089 29.6692C36.1089 32.3774 33.893 34.5932 31.1848 34.5932Z"
        fill={!isActive ? 'black' : 'white'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.216565"
        stroke-miterlimit="10"
      />
      <path
        d="M31.1845 24.1982C28.1754 24.1982 25.7134 26.6603 25.7134 29.6694C25.7134 32.6785 28.1754 35.1405 31.1845 35.1405C34.1936 35.1405 36.6556 32.6785 36.6556 29.6694C36.6556 26.6603 34.221 24.1982 31.1845 24.1982Z"
        fill={!isActive ? 'white' : '#D4A387'}
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="0.216565"
        stroke-miterlimit="10"
      />
      <path d="M31.1846 26.7695V29.6692H33.6739" fill="white" />
      <path
        d="M31.1846 26.7695V29.6692H33.6739"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.09167"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3162 21.818C9.29823 21.818 10.0943 21.0219 10.0943 20.0398C10.0943 19.0578 9.29823 18.2617 8.3162 18.2617C7.33418 18.2617 6.53809 19.0578 6.53809 20.0398C6.53809 21.0219 7.33418 21.818 8.3162 21.818Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M14.3338 21.818C15.3158 21.818 16.1119 21.0219 16.1119 20.0398C16.1119 19.0578 15.3158 18.2617 14.3338 18.2617C13.3518 18.2617 12.5557 19.0578 12.5557 20.0398C12.5557 21.0219 13.3518 21.818 14.3338 21.818Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M20.4602 21.818C21.4423 21.818 22.2384 21.0219 22.2384 20.0398C22.2384 19.0578 21.4423 18.2617 20.4602 18.2617C19.4782 18.2617 18.6821 19.0578 18.6821 20.0398C18.6821 21.0219 19.4782 21.818 20.4602 21.818Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M8.3162 27.7535C9.29823 27.7535 10.0943 26.9574 10.0943 25.9754C10.0943 24.9934 9.29823 24.1973 8.3162 24.1973C7.33418 24.1973 6.53809 24.9934 6.53809 25.9754C6.53809 26.9574 7.33418 27.7535 8.3162 27.7535Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M14.3338 27.7535C15.3158 27.7535 16.1119 26.9574 16.1119 25.9754C16.1119 24.9934 15.3158 24.1973 14.3338 24.1973C13.3518 24.1973 12.5557 24.9934 12.5557 25.9754C12.5557 26.9574 13.3518 27.7535 14.3338 27.7535Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M20.4602 27.7535C21.4423 27.7535 22.2384 26.9574 22.2384 25.9754C22.2384 24.9934 21.4423 24.1973 20.4602 24.1973C19.4782 24.1973 18.6821 24.9934 18.6821 25.9754C18.6821 26.9574 19.4782 27.7535 20.4602 27.7535Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M26.4793 21.818C27.4613 21.818 28.2574 21.0219 28.2574 20.0398C28.2574 19.0578 27.4613 18.2617 26.4793 18.2617C25.4973 18.2617 24.7012 19.0578 24.7012 20.0398C24.7012 21.0219 25.4973 21.818 26.4793 21.818Z"
        fill={!isActive ? 'black' : 'white'}
      />
    </svg>
  );
};
