import React from 'react';
import { Statement } from '../../../components/Programs/Statement/Statement';

export const GoldenAccountStatement = () => {
  return (
    <>
      <div className="main-launch-product__inner full">
        <div className="section-launch-product-main full">
          <Statement />
        </div>
      </div>
    </>
  );
};
