import React from 'react';

// import RegistrationPassportDataForm from '../../components/RegistrationPassportDataForm/RegistrationPassportDataForm';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';

function RegistrationPassportData() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        { /*<RegistrationPassportDataForm /> */ }
      </AnimationWrapper>
    </section>
  );
}

export default RegistrationPassportData;
