import { useState } from 'react';

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const myBody = document.body;
  const toggle = () => {
    setIsShown(!isShown);
    // no more scrolling on modal show
    myBody.classList.toggle('lock_screen')
  };

  return {
    isShown,
    toggle,
  };
};

