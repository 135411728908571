import React from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion/dist/framer-motion';
import { ModalWindowProps } from './ModalWindow.props';

function ModalWindow({ isShown, hide, modalContent }: ModalWindowProps) {
  const modal = (
    <motion.div className="modal-wrapper">
      <motion.div className="modal-backdrop">
        <motion.div
          initial={{ opacity: 0, y: '-50%' }}
          animate={{ opacity: 1, y: 0 }}
          className="modal-inner"
        >
          <div className="modal-header">
            <button className="modal-close-btn" type="button" onClick={hide}>
              X
            </button>
          </div>

          <div className="modal-content">{modalContent}</div>
        </motion.div>
      </motion.div>
    </motion.div>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}

export default ModalWindow;
