import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { PersonLedgersInterface } from '../../interfaces/personLedgers.interface';

export const personLedgersApi = createApi({
  reducerPath: 'personLedgers',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    personLedgers: builder.query<PersonLedgersInterface, void>({
      query: () => ({
        url: `ledgers?page=1`,
        method: 'GET',
      }),
    }),
  }),
});
export const { usePersonLedgersQuery } = personLedgersApi;
