import { ChangeEvent, useEffect, useState } from 'react';
import { RangeSliderProps } from './RangeSlider.props';

function RangeSlider({
  classes,
  onChange,
  value,
  ...sliderProps
}: RangeSliderProps) {
  const [sliderVal, setSliderVal] = useState('0');

  const [mouseState, setMouseState] = useState<null | 'down' | 'up'>(null);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const changeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: localValue } = e.target;

    setSliderVal(localValue);
  };

  useEffect(() => {
    if (mouseState === 'up') {
      onChange(sliderVal);
    }
  }, [mouseState]);

  return (
    <div className="range-slider">
      <input
        type="range"
        value={sliderVal}
        className={`slider ${classes}`}
        id="myRange"
        onChange={changeCallback}
        onMouseDown={() => setMouseState('down')}
        onMouseUp={() => setMouseState('up')}
        style={{
          // Needs to be to change progress line in Chrome
          background: `linear-gradient(to right, #003C5A 0%, #003C5A ${sliderVal}%, #fff ${sliderVal}%, white 100%)`,
        }}
        {...sliderProps}
      />
    </div>
  );
}

export default RangeSlider;
