import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { ModalPartnerWindowProps } from './ModalPartnerWindow.props';
import { useTranslation } from 'react-i18next';

export const ModalPartnerWindow = ({
  isShown,
  onClose,
  logo,
  subtitle,
  prefaceProgram,
  prefaceAbout,
  name,
  paragraphsProgram,
  paragraphsAbout,
  partnerName,
  city,
  address,
  registrationNumber,
  sideDropdownOptions,
  redirectTo,
  type,
}: ModalPartnerWindowProps) => {
  const [openedTab, setOpenedTab] = useState<'about_program' | 'details'>(
    'about_program',
  );
  const [openedDropdownInfo, setOpenedDropdownInfo] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.toggle('modal-open', isShown);
  }, [isShown]);

  const isAboutProgram = openedTab === 'about_program';

  const onToggleOpenedDropdownInfo = (key: string) => {
    setOpenedDropdownInfo((prevState) =>
      prevState.includes(key)
        ? prevState.filter((element) => element !== key)
        : [...prevState, key],
    );
  };

  const modal = (
    <div
      className={`modal modal-template ${
        type === 'silver'
          ? 'modal-partner--blue'
          : type === 'golden'
          ? 'modal-partner--bronze'
          : ''
      }`}
    >
      <div className="modal__inner modal-partner__inner">
        <div className="modal-partner__header">
          <div className="modal-partner__header-top">
            <div className="modal-partner__header-logo-container">
              <img
                className="modal-partner__header-logo"
                src={logo}
                alt="logo"
              />
            </div>
            <div className="modal-partner__heading-container">
              <h3 className="modal-partner__heading">{name}</h3>
              <div className="modal-partner__subheading">{subtitle}</div>
            </div>
            <div className="modal-partner__close-btn-container">
              <button className="modal-partner__close-btn" onClick={onClose} />
            </div>
          </div>
          <div className="modal-partner__header-bottom-links">
            <p
              className={`modal-partner__header-bottom-link cursor ${
                isAboutProgram ? 'active' : ''
              }`}
              onClick={() => setOpenedTab('about_program')}
            >
              {t('Programs.aboutProgram')}
            </p>
            <p
              className={`modal-partner__header-bottom-link cursor ${
                !isAboutProgram ? 'active' : ''
              }`}
              onClick={() => setOpenedTab('details')}
            >
              {t('Programs.detailed')}
            </p>
          </div>
        </div>

        <div className="modal-partner__content">
          <div
            className={`modal-partner__content-main ${
              !isAboutProgram ? 'hidden' : ''
            }`}
          >
            <p className="modal-partner__content-preface">{prefaceProgram}</p>
            <div className="modal-partner__content-paragraphs">
              {paragraphsProgram}
            </div>
          </div>
          <div
            className={`modal-partner__content-main ${
              isAboutProgram ? 'hidden' : ''
            }`}
          >
            <p className="modal-partner__content-preface">{prefaceAbout}</p>
            <div className="modal-partner__content-paragraphs">
              {paragraphsAbout}
            </div>
          </div>
          <div className="modal-partner__side">
            <div className="modal-partner__side-btn-container">
              <NavLink
                className="modal-partner__side-btn btn-modal btn-modal--transparent"
                to={redirectTo}
              >
                {t('Programs.personalCabinet')}
              </NavLink>
            </div>
            <div className="modal-partner__side-dropdowns">
              {sideDropdownOptions.map(({ name, value, type, key }) => {
                return (
                  <div
                    className={`modal-partner__side-dropdown dropdown ${
                      openedDropdownInfo.includes(key) ? 'is-open' : ''
                    }`}
                    onClick={() => onToggleOpenedDropdownInfo(key)}
                  >
                    <button className="modal-partner__side-dropdown-toggle">
                      {t(name)}
                    </button>
                    <div className="modal-partner__side-dropdown-content dropdown__content">
                      <span className="modal-partner__side-dropdown-content-type">
                        <a href={`${type}:${value}`} target="_blank">
                          {value}
                        </a>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modal-partner__side-additional">
              {partnerName}
              <br />
              Рег.номер: {registrationNumber}
              <br />
              {city}
              <br />
              {address}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
