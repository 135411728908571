import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FormBaseProps } from './FormBase.props';
import { useTranslation } from 'react-i18next';

const FormBase = <T extends 'default' | 'extended' = 'default'>({
  children,
  sections,
  rightAside,
  onSectionChange,
  activeSection,
  activeSubSection,
  isDropdown,
}: FormBaseProps<T>) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  // useEffect(() => {
  //   const activeClass = 'form-base__sidebar-item-active';
  //   let lastId = '';
  //   const menuItems = Array.from(
  //     document.querySelectorAll('.form-base-sidebar-item'),
  //   ) as HTMLElement[];
     
  //   const scrollItems = Array.from(
  //     document.querySelectorAll('.form-general__section'),
  //   ) as HTMLElement[];
     
  //   window.addEventListener('scroll', function () {
  //     let fromTop = window.pageYOffset + 130;
  
  //     let cur: null | HTMLElement = null;
  
  //     scrollItems.map(function (item) {
  //       if (item.offsetTop < fromTop) {
  //         cur = item;
  //       }
  //     });
  
  //     let id = cur ? (cur as HTMLElement).id : '';
  
  //     if (lastId !== id) {
  //       lastId = id;
  //       menuItems.forEach(function (elem) {
  //         elem.classList.remove(activeClass);
  //         const filteredItems = menuItems.filter(
  //           (elem) => elem.getAttribute('id') === `${id}`,
  //         );
  //         filteredItems?.[0]?.classList.add(activeClass);
  //       });
  //     }
  //   });
  // }, []);

  const onOpenToggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <div className="main-launch main-launch-product">
      <div className="wrapper">
        <div className="container">
          <aside className="section-launch-product-left">
            <div className="section-launch-product-left__btn-container">
              <button className="section-launch-product-left__btn">
                <span
                  className="section-launch-product-left__btn-lines"
                  onClick={onOpenToggleMenu}
                />
                <span>Меню</span>
              </button>
            </div>
            <div
              className={`section-launch-product-left__list-container list_sticky ${
                isMenuOpen ? 'product-menu-open' : ''
              }`}
            >
              <button
                className="section-launch-product-left__close-btn"
                onClick={onOpenToggleMenu}
              >
                <div className="section-launch-product-left__close-btn-icon">
                  +
                </div>
              </button>
              <ul className="section-launch-product-left__list">
                {sections.map(({ name, key, icon, ...rest }) => {
                  const hasItems = 'items' in rest;
                  return (
                    <li
                      className={`
                        section-launch-product-left__list-item form-base-sidebar-item ${
                          isDropdown ? 'dropdown' : ''
                        } ${
                        activeSection === key
                          ? hasItems
                            ? 'is-open'
                            : 'is-active'
                          : ''
                      }
                      `}
                      key={key}
                    >
                      <button
                        className="section-launch-product-left__list-item-btn"
                        onClick={
                          onSectionChange
                            ? () => onSectionChange(key)
                            : undefined
                        }
                      >
                        {icon}
                        {t(name)}
                      </button>
                      <ul className="section-launch-product-left__list-item-content dropdown__content">
                        {hasItems ? (
                          rest.items.map(({ name, key }) => {
                            return (
                              <li
                                className="section-launch-product-left__list-item-content-item"
                                key={key}
                              >
                                <button
                                  className={`section-launch-product-left__list-item-content-link link ${
                                    activeSubSection === key ? 'selected' : ''
                                  }`}
                                  onClick={
                                    onSectionChange
                                      ? () => onSectionChange(key)
                                      : undefined
                                  }
                                >
                                  {t(name)}
                                </button>
                              </li>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </aside>
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormBase;
