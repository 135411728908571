import React from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationOffersForm from '../../components/RegistrationOffersForm/RegistrationOffersForm';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';

function RegistrationOffers() {
  const { t } = useTranslation();

  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        <h2>{t('RegistrationOffers.title')}</h2>
        <p>{t('RegistrationOffers.question')}</p>
        <RegistrationOffersForm />
      </AnimationWrapper>
    </section>
  );
}

export default RegistrationOffers;
