import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButtonProps } from './BackButton.props';

function BackButton({ children, className }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className={className} type="button">
      {children}
    </button>
  );
}

export default BackButton;
