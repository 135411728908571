import React from 'react';

interface Props {
  isActive?: boolean;
}
export const CalculatorIcon = ({ isActive }: Props) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0983 34H3.8753C2.29256 34 1 32.7074 1 31.1247V3.8753C1 2.29257 2.29256 1 3.8753 1H31.0983C32.6811 1 33.9736 2.29257 33.9736 3.8753V31.1247C34 32.7074 32.6811 34 31.0983 34Z"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M17.4902 1V34"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M1 17.4873H17.4868"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="2.06459"
        stroke-miterlimit="10"
      />
      <path
        d="M12.5298 25.7695H6.01416"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.65166"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8016 1.44824H1.66016V17.513H16.8016V1.44824Z"
        fill={!isActive ? 'black' : 'white'}
      />
      <path
        d="M28.463 15.957H22.7651"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.65166"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.463 19.0439H22.7651"
        stroke={!isActive ? 'black' : 'white'}
        stroke-width="1.65166"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.28467 5.98535V12.4746"
        stroke={!isActive ? 'white' : '#D4A387'}
        stroke-width="1.65166"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5293 9.23047H6.01367"
        stroke={!isActive ? 'white' : '#D4A387'}
        stroke-width="1.65166"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
