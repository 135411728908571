import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';
import { FirstProgramInformation } from '../../components/Programs/First/First';

function FirstProgram() {
  return (
    <section className="section-launch-product">
      <AnimationWrapper>
        <FirstProgramInformation />
      </AnimationWrapper>
    </section>
  );
}

export default FirstProgram;
