import { IQeonIcon } from './iqeon';
import { CurrtexIcon } from './currtex';
import { BinomMobileIcon } from './binomMobile';
import { TrainingCentreIcon } from './trainingCentre';
import { CharityFundIcon } from './charityFund';
import { DeksisIcon } from './deksis';
import { ZPlexIcon } from './zPlexIcon';
import { LifeJournalIcon } from './lifeJournal';
import { NeedTourIcon } from './needTour';
import { PracticumGroupIcon } from './practicumGroup';
import { StrongholdSolutionsIcon } from './strongholdSolutions';
import { ValidatePlatformIcon } from './validatePlatform';
import { EurosimTravelIcon } from './eurosimTravel';
import { UnionPayIcon } from './unionPay';
import { NadiaFryEventsIcon } from './nadiaFryEvents';

export {
  IQeonIcon,
  CurrtexIcon,
  NeedTourIcon,
  DeksisIcon,
  CharityFundIcon,
  ValidatePlatformIcon,
  EurosimTravelIcon,
  LifeJournalIcon,
  NadiaFryEventsIcon,
  PracticumGroupIcon,
  StrongholdSolutionsIcon,
  UnionPayIcon,
  ZPlexIcon,
  TrainingCentreIcon,
  BinomMobileIcon,
};
