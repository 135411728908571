import React, { useState } from 'react';
import {
  TRANSACTIONS_CURRENCIES,
  TRANSACTIONS_TYPES,
} from './LastTransactions.model';
import Select from '../../Select/Select';
import {
  NegativeTransaction,
  PositiveTransaction,
} from '../../../assets/media';
import { useTranslation } from 'react-i18next';
import {usePersonLedgersQuery} from "../../../app/services/personLedgers.api";
import Moment from 'react-moment';

interface Props {}
export const LastTransactions = ({}: Props) => {
  const { t } = useTranslation();
  const [transactionType, setTransactionType] = useState(
    TRANSACTIONS_TYPES[0].value,
  );
  const [currencyType, setCurrencyType] = useState(
    TRANSACTIONS_CURRENCIES[0].value,
  );
  const [isAllTransactionsShown, toggleIsAllTransactionsShown] =
    useState(false);

  const onChangeTransactionType = (key: string) => {
    setTransactionType(key);
  };
  const onChangeCurrencyType = (key: string) => {
    setCurrencyType(key);
  };

  const toggleAllTransactions = () => {
    toggleIsAllTransactionsShown((prevState) => !prevState);
  };
  const { data } = usePersonLedgersQuery();
  if (data)
  console.log (data.length);


  // convert data to transactions array
  // data structure:
  // {
  // amount: number
  // createdAt: date
  // currency: UUID
  // description: text
  // operationType: int
  //}
  // transaction object structure:
  // {
  // date: string,
  //       data: [
  //         {
  //           type: int,
  //           operationType: int,
  //           time: string,
  //           amount: number,
  //           currency: str,
  //         },
  //       ],
  //
  let transactions
  if (data) {
    transactions = data.map((item) => {
      console.log(item)
      return {
        date: item.createdAt,
        data: [
          {
            type: item.operationType,
            operationType: item.operationType,
            time: item.createdAt,
            amount: item.amount,
            currency: item.currency,
            description: item.description,
          },
        ],
      };
    }, []);
  }
  /* const transactions = [
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.operationCommission',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.operationCommission',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.operationCommission',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
    {
      date: '08 February 2022',
      data: [
        {
          type: 'replenishment',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.operationCommission',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
        {
          type: 'withdrawal',
          operationType: 'Programs.internalTransfer',
          time: '09:11:50',
          amount: '662,87',
          currency: 'EUR',
        },
      ],
    },
  ]; */

  return (
    <aside className="section-launch-product-right">
      <div className="section-launch-product-right__section">
        <p className="section-launch-product-right__section-heading">
          {t('Programs.lastTransactions')}
        </p>
      </div>
      <div className="section-launch-product-right__section">
        <div className="sidebar-transactions">
          <div className="sidebar-transactions__filters">
            <Select
              onChange={onChangeTransactionType}
              options={TRANSACTIONS_TYPES}
              value={transactionType}
            />
            <Select
              onChange={onChangeCurrencyType}
              options={TRANSACTIONS_CURRENCIES}
              value={currencyType}
            />
          </div>
          <div
            className={`sidebar-transactions__list ${
              isAllTransactionsShown ? 'is-open' : ''
            }`}
          >
            {transactions ? transactions.map(({ date, data }) => {
              return (
                <div className="sidebar-transactions__section">
                  <p className="sidebar-transactions__section-head">
                    <Moment format="MM-DD-YYYY">{date}</Moment></p>
                  <ul className="sidebar-transactions__section-list">
                    {data.map(
                      ({ type, time, amount, currency, operationType, description }) => {
                        return (
                          <li className="sidebar-transactions__section-list-item">
                            <div className="sidebar-transactions__section-list-item__icon">
                              <img
                                src={
                                  type === 3
                                    ? PositiveTransaction
                                    : NegativeTransaction
                                }
                                alt="transaction-type"
                              />
                            </div>
                            <div className="sidebar-transactions__section-list-item__info">
                              <div className="sidebar-transactions__section-list-item__details">
                                {description}
                              </div>
                              <div className="sidebar-transactions__section-list-item__target">
                                <Moment format="HH:MM:SS">
                                  {time}
                                </Moment>
                              </div>
                            </div>
                            <div className="sidebar-transactions__section-list-item__amount">
                              <strong>
                                {amount} {TRANSACTIONS_CURRENCIES.find(i => i.value === currency)?.label}
                              </strong>
                            </div>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
              );
            }) : null}
          </div>
          <div className="sidebar-transactions__footer">
            <button
              className="section-launch-newest__content-link link"
              onClick={toggleAllTransactions}
            >
              Показать ещё
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};
