import { SearchInputProps } from './SearchInput.props';
import { LoopIcon } from '../../assets/media';
import { ChangeEvent } from 'react';

export const SearchInput = ({
  value,
  onChange,
  placeholder,
}: SearchInputProps) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    onChange(value);
  };
  return (
    <label className={'search-input'}>
      <input
        className="search-input--field"
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
      />
      <img className="search-input--icon" src={LoopIcon} alt="loop" />
    </label>
  );
};
