import { createApi } from '@reduxjs/toolkit/query/react';
import { sevenFlagsG3BaseQuery } from '../../helpers/api.utilits';
import { PersonalDataTypeFormInterface } from '../../interfaces/personalData.interface';

export const personalDataApi = createApi({
  reducerPath: 'personalDataApi',
  baseQuery: sevenFlagsG3BaseQuery(),
  endpoints: (builder) => ({
    postUser: builder.mutation<
      PersonalDataTypeFormInterface,
      PersonalDataTypeFormInterface
    >({
      query: (body) => ({
        url: `onboarding/person/profile`,
        method: 'POST',
        body,
      }),
    }),
    postCompany: builder.mutation<
      PersonalDataTypeFormInterface,
      PersonalDataTypeFormInterface
    >({
      query: (body) => ({
        url: `onboarding/company/profile`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { usePostUserMutation, usePostCompanyMutation } = personalDataApi;
