import React from 'react';

// import RegistrationAddressForm from '../../components/RegistrationAddressForm/RegistrationAddressForm';
import AnimationWrapper from '../../components/AnimationWrapper/AnimationWrapper';

function RegistrationAddress() {
  return (
    <section className="section-cabinet">
      <AnimationWrapper className="container">
        { /* <RegistrationAddressForm /> */ }
      </AnimationWrapper>
    </section>
  );
}

export default RegistrationAddress;
